import { Field, Input, Spin, TextArea } from '@/components/commons';
import { Form } from 'antd';
import { useFormContext } from 'react-hook-form';

interface IProps {
  isLoading: boolean;
  specialId: number | null;
}

export const SpecialDayForm: React.FC<IProps> = ({ isLoading, specialId }) => {
  const { watch } = useFormContext();

  const isDisabled = watch('isDisabled');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='日にち' name='date'>
          <Input maxLength={100} disabled={isDisabled || specialId} />
        </Field>
        <Field label='タイトル' name='title'>
          <Input maxLength={100} disabled={isDisabled} />
        </Field>
        <Field label='コンテンツ' name='description'>
          <TextArea rows={2} maxLength={500} disabled={isDisabled} />
        </Field>
        <Field label='祝日' name='name'>
          <Input maxLength={100} disabled={isDisabled} />
        </Field>
        {specialId && (
          <Field label='登録日' name='createdAt'>
            <Input maxLength={100} disabled />
          </Field>
        )}
      </Spin>
    </Form>
  );
};

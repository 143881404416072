import { Button, Field, Input } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { AuthService } from '@/services';
import { IResponseError } from '@/types/common';
import { IForgotPasswordRequest } from '@/types/request';
import { NOTIFY_STATUS, notificationMessage } from '@/utils';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formSchemaFn } from './formSchemaFn';
import './style.scss';

interface IProps {
  title?: string;
}

export const ForgotPassword: React.FC<IProps> = ({ title }) => {
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const navigate = useNavigate();
  const { handleSubmit, setError } = methods;

  const { mutate: forgotPassword, isLoading } = useMutation(
    (body: IForgotPasswordRequest) => AuthService.forgotPassword(body),
    {
      onSuccess: () => {
        navigate(APP_ROUTER.AUTH.RESET_PASSWORD);
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.EMAIL_INVALID) {
          setError('email', {
            type: 'custom',
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          });
        } else {
          notificationMessage({
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
            type: NOTIFY_STATUS.ERROR,
          });
        }
      },
    },
  );

  const handleForgotPassword = (values: any) => {
    forgotPassword({ email: values.email });
  };

  return (
    <div className='p-forgot-password flex flex-col items-center justify-center h-screen'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-[396px]'>
        <header>
          <div className='text-[20px] font-semibold text-center'>
            パスワード再発行
          </div>
          <span className='desc font-semibold'>
            ご登録いただいたパスワードを入力してください。新しいパスワードを再発行します。
          </span>
        </header>
        <FormProvider {...methods}>
          <Form
            className='mt-[32px] flex flex-col justify-center items-center'
            layout='vertical'
            onFinish={handleSubmit(handleForgotPassword)}
          >
            <Field name='email' label='メールアドレス' className='w-full'>
              <Input placeholder='' />
            </Field>
            <Button
              type='primary'
              className='w-[316px] font-semibold'
              htmlType='submit'
              loading={isLoading}
            >
              メールを送信する
            </Button>
            <Button
              className='back-btn font-semibold w-[316px]'
              type='link'
              onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
            >
              戻る
            </Button>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};

import { IResponse, IResponsePagination } from '@/types/common';
import {
  ICreateSpecialDayRequest,
  IGetListSpecialDayRequest,
  IUpdateSpecialDayRequest,
} from '@/types/request';
import {
  ICreateSpecialDayResponse,
  IGetDetailSpecialDayResponse,
  IGetListSpecialDayResponse,
  IUpdateSpecialDayResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class SpecialDay {
  getList = (
    params: IGetListSpecialDayRequest,
  ): Promise<IResponsePagination<IGetListSpecialDayResponse>> => {
    return axiosClient.get(ENDPOINT.SPECIAL_DAY.GET_LIST, { params: params });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailSpecialDayResponse>> => {
    return axiosClient.get(
      ENDPOINT.SPECIAL_DAY.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateSpecialDayRequest,
  ): Promise<IResponse<ICreateSpecialDayResponse>> => {
    return axiosClient.post(ENDPOINT.SPECIAL_DAY.CREATE, body);
  };

  update = (
    data: IUpdateSpecialDayRequest,
    id: number,
  ): Promise<IResponse<IUpdateSpecialDayResponse>> => {
    return axiosClient.put(
      ENDPOINT.SPECIAL_DAY.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.SPECIAL_DAY.DELETE.replace(':id', String(id)),
    );
  };
}

export const SpecialDayService = new SpecialDay();

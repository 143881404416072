import { EventFeeType } from '@/utils';
import moment from 'moment';
import * as yup from 'yup';

export const onlyHalfSizeRegex =
  /[\uFF01-\uFF60\uFFE0-\uFFEF\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    name: yup
      .string()
      .required('イベント名は未入力です。')
      .max(40, 'イベント名は40文字以内で入力してください。'),
    eventFrom: yup
      .date()
      .required('開始日時を選択してください。')
      .when('publicAt', ([publicAt], schema) => {
        if (!publicAt) {
          return schema;
        }
        return schema.min(
          moment(publicAt).startOf('minute').toDate(),
          '開催期間は配信日時より後の日付を選択してください。',
        );
      }),
    eventTo: yup
      .date()
      .required('終了日時を選択してください。')
      .min(
        yup.ref('eventFrom'),
        '終了日時は開始日時より後の日付を選択してください。',
      ),
    address: yup
      .string()
      .required('開催場所は未入力です。')
      .max(100, '開催場所は100文字以内で入力してください。'),
    feeType: yup.string().required('参加費は未入力です。'),
    fee: yup
      .number()
      .typeError('参加費は未入力です。')
      .when('feeType', ([feeType], schema) => {
        if (feeType === EventFeeType.FREE) {
          return schema;
        }
        return schema
          .required('参加費は未入力です。')
          .min(1, '参加費は1以上である必要があります。')
          .max(999999999, '参加費は9桁以内で入力してください。');
      }),
    url: yup
      .string()
      .url('URLは正しい形式で入力してください。')
      .max(100, 'URLは100文字以内で入力してください。'),
    content: yup
      .string()
      .required('イベント概要は未入力です。')
      .max(500, 'イベント概要は500文字以内で入力してください。'),
    status: yup.boolean().required('バナー画像は未入力です。'),
    publicAt: yup
      .date()
      .required('配信日時を選択してください。')
      .when(
        ['status', 'pushNotificationAt', 'eventTo'],
        ([status, pushNotificationAt, eventTo], schema) => {
          if (
            !status ||
            (pushNotificationAt && moment().isAfter(moment(eventTo)))
          ) {
            return schema;
          }
          return schema.min(
            moment().add(1, 'minute').startOf('minute').toDate(),
            '配信日時が過去の日時なので、イベントを作成できませんでした。',
          );
        },
      ),
    postCodeType: yup.number().required('開催場所は未入力です。'),
    zipCode: yup
      .string()
      .when(
        ['postCodeType', 'preZipCode', 'keepCheckZipCodeError', 'address1'],
        (
          [postCodeType, preZipCode, keepCheckZipCodeError, address1],
          schema,
        ) => {
          if (!postCodeType) {
            return schema;
          }
          return schema
            .required('郵便番号は未入力です。')
            .test(
              'noFullWidth',
              '郵便番号は半角英数字で入力してください。',
              (zipCode) => {
                return !onlyHalfSizeRegex.test(zipCode || '');
              },
            )
            .test('zipCode', '「住所検索」をタップしください', (zipCode) => {
              return (
                keepCheckZipCodeError || (preZipCode === zipCode && address1)
              );
            })
            .test('errorZipCode', 'この番号では検索できません。', (_) => {
              return !keepCheckZipCodeError;
            });
        },
      ),
    attachment: yup
      .mixed()
      .when(
        ['attachments', 'imageBanner'],
        ([attachments, imageBanner], schema) => {
          if (imageBanner && (!attachments || !attachments?.fileList?.length)) {
            return schema;
          }
          if (!attachments || !attachments?.fileList?.length) {
            return schema.required('バナー画像をアップロードしてください。');
          }
          return schema
            .test('fileSize', '画像のサイズは10MBを超えています。', (_) => {
              return attachments?.fileList[0]?.size <= FILE_SIZE_LIMIT;
            })
            .test(
              'fileType',
              '画像は .jpg、.png、.jpeg 形式ではありません。',
              (_) => {
                return SUPPORTED_FORMATS.includes(
                  attachments?.fileList[0]?.type,
                );
              },
            );
        },
      ),
  } as any);

import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends TextAreaProps {
  field?: ControllerRenderProps<any, any>;
}

export const TextArea: React.FC<IProps> = ({ className, field, ...props }) => {
  return <Input.TextArea className={className} {...field} {...props} />;
};

import { Table as TableBase, TableProps } from 'antd';
import './style.scss';

export const Table: React.FC<TableProps<any>> = ({
  className,
  pagination = false,
  bordered = true,
  dataSource,
  ...props
}) => {
  return (
      <TableBase
        className={`table-common ${className} ${!dataSource?.length ? 'table-empty': ''}`}
        pagination={pagination}
        bordered={bordered}
        dataSource={dataSource}
        {...props}
      />
  );
};

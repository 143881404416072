import {
  DeleteContent,
  Drawer,
  Input,
  Pagination,
  Table,
} from '@/components/commons';
import { PostFukumaruService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IGetDetailPostFukumaruResponse } from '@/types/response';
import {
  NOTIFY_STATUS,
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
} from '@/utils';
import { SearchOutlined } from '@ant-design/icons';
import { Image, Select, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import { formSchemaFn } from '../UpdatePostFukumaru/formSchemaFn';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { IResponse, IResponseError } from '@/types/common';
import { ERROR } from '@/utils/constants/messages';
import { IUpdatePostFukumaruRequest } from '@/types/request';
import { PostFukumaruForm } from '../Drawers';
import { APP_ROUTER } from '@/routes/routes';
import { useNavigate } from 'react-router-dom';
import './style.scss';

interface IProps {
  title?: string;
}

enum FilterTypeAutoGeneratePostText {
  FUKUMARU = 'FUKUMARU',
  BIRTHDAY = 'BIRTHDAY',
  STEPS_REPORT = 'STEPS_REPORT',
  HEALTHCARE = 'HEALTHCARE ',
  SAFETY = 'SAFETY',
  DENTAL = 'DENTAL',
}

export enum AutoGeneratePostTypeLabel {
  FUKUMARU = 'ふくまる',
  BIRTHDAY = '誕生日のお知らせ',
  STEPS_REPORT = '歩数レポート',
  HEALTHCARE = '健康診断のお知らせ ',
  SAFETY = '防犯のお知らせ',
  DENTAL = 'お口のお知らせ',
}

export enum PostType {
  EASY = 'easy',
  FREE_INPUT = 'freeInput',
  STEPS_REPORT = 'stepsReport',
  BIRTHDAY = 'birthday',
  HEALTHCARE = 'healthcare',
  SAFETY_NOTICE = 'safetyNotice',
  DENTAL_NOTICE = 'dentalNotice',
}

const filterOptions = [
  { value: null, label: '-' },
  {
    value: FilterTypeAutoGeneratePostText.FUKUMARU,
    label: AutoGeneratePostTypeLabel.FUKUMARU,
  },
  {
    value: FilterTypeAutoGeneratePostText.STEPS_REPORT,
    label: AutoGeneratePostTypeLabel.STEPS_REPORT,
  },
  {
    value: FilterTypeAutoGeneratePostText.BIRTHDAY,
    label: AutoGeneratePostTypeLabel.BIRTHDAY,
  },
  {
    value: FilterTypeAutoGeneratePostText.HEALTHCARE,
    label: AutoGeneratePostTypeLabel.HEALTHCARE,
  },
  {
    value: FilterTypeAutoGeneratePostText.SAFETY,
    label: AutoGeneratePostTypeLabel.SAFETY,
  },
  {
    value: FilterTypeAutoGeneratePostText.DENTAL,
    label: AutoGeneratePostTypeLabel.DENTAL,
  },
];

export const convertTypeAutoGeneratePostText = (value: PostType) => {
  switch (value) {
    case PostType.STEPS_REPORT:
      return AutoGeneratePostTypeLabel.STEPS_REPORT;
    case PostType.BIRTHDAY:
      return AutoGeneratePostTypeLabel.BIRTHDAY;
    case PostType.HEALTHCARE:
      return AutoGeneratePostTypeLabel.HEALTHCARE;
    case PostType.SAFETY_NOTICE:
      return AutoGeneratePostTypeLabel.SAFETY;
    case PostType.DENTAL_NOTICE:
      return AutoGeneratePostTypeLabel.DENTAL;
    default:
      return AutoGeneratePostTypeLabel.FUKUMARU;
  }
};

interface IParams {
  title: string;
  limit: number;
  page: number;
  isFukumaru: boolean;
  sortBy: string;
  filterType?: string | null;
}

export const PostFukumaruList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailPostFukumaruResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    title: '',
    limit: 10,
    page: 1,
    isFukumaru: true,
    sortBy: 'publicAt',
  });
  const navigate = useNavigate();

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.POST_FUKUMARU.GET_LIST, params],
    () => PostFukumaruService.getList(convertRequest(params)),
  );

  const { mutate: deletePostFukumaru, isLoading: isLoadingDelete } =
    useMutation((id: number) => PostFukumaruService.delete(id), {
      onSuccess: () => {
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
        notificationMessage({
          message: 'Delete successfully',
          type: NOTIFY_STATUS.SUCCESS,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.POST_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    });

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '220px',
    },
    {
      title: '写真',
      dataIndex: 'imageCover',
      width: '140px',
      align: 'center',
      render: (imageCover) =>
        imageCover?.url && (
          <Image preview={false} width={112} src={imageCover?.url} />
        ),
    },
    {
      title: '種類',
      dataIndex: 'type',
      align: 'center',
      width: '166px',
      render: (type) => convertTypeAutoGeneratePostText(type),
    },
    {
      title: 'いつ',
      dataIndex: 'publicAt',
      align: 'center',
      width: '166px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        title: search,
        page: 1,
      }));
    }, 500);
  }, [search]);

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.POST_FUKUMARU.GET_DETAIL.replace(':id', String(currentId!))],
    () => PostFukumaruService.getDetail(Number(currentId)),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailPostFukumaruResponse>) => {
        const data = response?.data;
        setDetail(data);
        reset({
          title: data.title,
          content: data.content,
          imageCover: data?.imageCover?.url,
          family: data?.familyId?.name,
          createdAt: data?.createdAt
            ? moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : '',
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleDelete = (data: IGetDetailPostFukumaruResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: (
        <DeleteContent
          name='タイトル'
          content='Do you really want to delete this item?'
        />
      ),
      maskClosable: false,
      onOk: () => deletePostFukumaru(data?.id),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdatePostFukumaruRequest; id: number }) =>
      PostFukumaruService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.POST_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    },
  );

  const handleUpdate = (values: IUpdatePostFukumaruRequest) => {
    const payload = {
      id: currentId!,
      body: {
        title: values.title,
        content: values.content,
      },
    };

    update(payload);
  };

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  const handleSelectFilter = (value: string) => {
    setParams({ ...params, filterType: value, page: 1 });
  };

  return (
    <div className='p-post-fukumaru-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => {
              setParams({ ...params, page: 1, title: e.target.value });
            }}
          />
          <Select
            className='w-[180px]'
            defaultValue={null}
            onChange={handleSelectFilter}
            options={filterOptions}
          />
        </div>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              navigate(
                APP_ROUTER.POST_FUKUMARU.DETAIL.replace(
                  ':id',
                  String(record.id),
                ),
              );
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title='ふくまる投稿の詳細'
        onOk={handleSubmit(handleUpdate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={isLoadingUpdate || isLoadingDelete || isFetchingDetail}
        isDeleted={!!currentId}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <PostFukumaruForm isLoading={isFetchingDetail} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ja_JP';
import 'moment/locale/vi';
import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './Auth';
import ReactQueryProvider from './ReactQuery';
import { StateGlobalProvider } from './StateGlobal';

const AllProviders = ({ children }: { children: ReactNode }) => {
  return (
    <HelmetProvider>
      <ConfigProvider
        theme={{
          hashed: false,
          token: {
            colorBgContainer: '#f4f4f4',
            colorPrimary: ' #84AB42',
          },
        }}
        locale={locale}
      >
        <ReactQueryProvider>
          <AuthProvider>
            <StateGlobalProvider>{children}</StateGlobalProvider>
          </AuthProvider>
        </ReactQueryProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
};

export default AllProviders;

import {
  Button,
  DeleteContent,
  Drawer,
  Input,
  Pagination,
  Table,
} from '@/components/commons';
import { AppVersionService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import {
  NOTIFY_STATUS,
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
} from '@/utils';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { IResponse, IResponseError } from '@/types/common';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { VersionForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/VersionForm/formSchemaFn';
import { FORM, PLATFORM } from '../Drawers/VersionForm/constants';
import { ICreateAppVersionRequest, IUpdateAppVersionRequest } from '@/types/request/appVersion';
import { IGetDetailAppVersionResponse } from '@/types/response/appVersion';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
  version: string;
}

export const platFormOptions = [
  {
    value: '*',
    label: '全て',
  },
  {
    value: 'ios',
    label: 'IOS',
  },
  {
    value: 'android',
    label: 'ANDROID',
  },
];


export const VersionList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailAppVersionResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
    version: '',
  });

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(
      formSchemaFn(),
    ),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.APP_VERSION.GET_LIST, params],
    () => AppVersionService.getList(convertRequest(params)),
  );

  const { mutate: deleteDay, isLoading: isLoadingDelete } = useMutation(
    (id: number) => AppVersionService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
            SUCCESS.DELETE_APP_VERSION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.VERSION_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'プラットフォーム',
      dataIndex: 'platform',
      width: '100px',
      render: (value: keyof typeof PLATFORM) => PLATFORM[value] || value,
    },
    {
      title: 'バージョン',
      dataIndex: 'version',
      width: '110px',
    },
    {
      title: '実行',
      dataIndex: 'mustUpdate',
      width: '50px',
      render: (value: boolean) => value ? "必須" : '任意',
    },
    {
      title: 'リリース日付',
      dataIndex: 'releaseDate',
      align: 'center',
      width: '140px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      align: 'center',
      width: '140px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        version: search,
      }));
    }, 500);
  }, [search]);

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateAppVersionRequest; id: number }) =>
      AppVersionService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
            SUCCESS.UPDATE_APP_VERSION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.VERSION_EXIST) {
          setError('version', {
            type: 'custom',
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          });
        } else {
          notificationMessage({
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
            type: NOTIFY_STATUS.ERROR,
          });
          setIsNotFoundDetail(true);
        }
      },
    },
  );

  const { mutate: create, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateAppVersionRequest) => AppVersionService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
            SUCCESS.CREATE_APP_VERSION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.VERSION_EXIST) {
          setError('version', {
            type: 'custom',
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          });
        } else {
          notificationMessage({
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
            type: NOTIFY_STATUS.ERROR,
          });
        }
      },
    },
  );

  const handleUpdate = (values: IUpdateAppVersionRequest) => {
    const payload = {
      id: currentId!,
      body: {
        description: values?.description ?? '',
        mustUpdate: Boolean(Number(values?.mustUpdate)),
        platform: values?.platform ?? '',
        releaseDate: values?.releaseDate ?? '',
        version: values?.version ?? '',
      },
    };

    update(payload);
  };

  const handleCreate = (values: ICreateAppVersionRequest) => {
    const payload = {
      description: values?.description ?? '',
      mustUpdate: Boolean(Number(values?.mustUpdate)),
      platform: values?.platform ?? '',
      releaseDate: values?.releaseDate ?? '',
      version: values?.version ?? '',
    };

    create(payload);
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleDelete = (data: IGetDetailAppVersionResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: (
        <DeleteContent
          name='タイトル'
          content='この項目を削除してもよろしいですか。?'
        />
      ),
      maskClosable: false,
      onOk: () => deleteDay(data?.id),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.NOTIFICATION.GET_DETAIL.replace(':id', String(currentId))],
    () => AppVersionService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailAppVersionResponse>) => {
        const data = response?.data;
        setDetail(data);
        reset({
          formName: FORM.UPDATE_VERSION,
          description: data?.description ?? '',
          mustUpdate: +!!data?.mustUpdate,
          platform: data?.platform ?? '',
          version: data?.version ?? '',
          releaseDate: moment(data?.releaseDate)
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <header className='flex justify-between w-full lg:w-[1280px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='バージョン別選択'
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            登録
          </Button>
        </header>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              setOpenDrawer(true);
              setCurrentId(record.id);
              setIsNotFoundDetail(false);
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title={!currentId ? '登録' : '詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          currentId
            ? isLoadingUpdate || isLoadingDelete || isFetchingDetail
            : isLoadingCreate
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <VersionForm
            isLoading={
              isFetchingDetail ||
              isLoadingCreate ||
              isLoadingUpdate ||
              isLoadingDelete
            }
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

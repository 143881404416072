import { REGEX } from '@/utils';
import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    email: yup
      .string()
      .matches(REGEX.EMAIL, 'Invalid  email address')
      .required('Please input  email'),
  } as any);

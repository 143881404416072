import { DatePicker as DatePickerBase } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends Omit<RangePickerProps, 'value'> {
  field?: ControllerRenderProps<any, any>;
}

export const DatePickerRange: React.FC<IProps> = ({
  className,
  field,
  format='YYYY年MM月DD日 HH:mm',
  ...props
}) => {
  return <DatePickerBase.RangePicker format={format} className={className} {...field} {...props} />;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal {
  width: 1000px !important;
}
.ant-modal-confirm-title, .ant-modal-confirm-content {
  margin-bottom: 1rem;
}
.ant-modal-confirm-btns button {
  border: 1px solid;
  font-weight: bold;
}
.ant-modal-confirm-btns .ant-btn-primary {
  background: #CE3A32 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/commons/DeleteContent/style.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;AACI;EACI,mBAAA;AACR;AAGQ;EACI,iBAAA;EACA,iBAAA;AADZ;AAIQ;EACI,8BAAA;AAFZ","sourcesContent":[".ant-modal {\n    width: 1000px !important;\n\n    &-confirm-title, &-confirm-content {\n        margin-bottom: 1rem;\n    }\n    \n    &-confirm-btns {\n        button {\n            border: 1px solid;\n            font-weight:bold;\n        }\n        \n        .ant-btn-primary {\n            background: #CE3A32 !important;\n        }\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { IResponsePagination } from '@/types/common';
import { IGetListPostRequest } from '@/types/request';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class PostReport {
  getList = (
    params: IGetListPostRequest,
  ): Promise<IResponsePagination<any>> => {
    return axiosClient.get(ENDPOINT.POST_REPORT.GET_LIST, { params: params });
  };
}

export const PostReportService = new PostReport();

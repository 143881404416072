import { Input, Pagination, Table, Drawer } from '@/components/commons';
import { ENDPOINT } from '@/services/endpoint';
import { UserService } from '@/services/user';
import { IGetDetailUserResponse } from '@/types/response';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  NOTIFY_STATUS,
  userStatus,
  userStatusColors,
  verifyStatusColors,
} from '@/utils';
import { SearchOutlined } from '@ant-design/icons';
import { TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import { IFamilyRole } from '@/types/response/familyRole';
import { IRole } from '@/types/response/role';
import { IResponse, IResponseError } from '@/types/common';
import { FormProvider, useForm } from 'react-hook-form';
import { UserForm } from '../Drawers';
import { ERROR_MESSAGE } from '@/utils/constants/messages';
import { IUpdateUserRequest } from '@/types/request';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchemaFn } from '../Drawers/UserForm/formSchemaFn';
import { ImageService } from '@/services/image';
import { IUploadImageRequest } from '@/types/request/upload';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import './style.scss';

interface IProps {
  title?: string;
}

interface IParams {
  email: string;
  limit: number;
  page: number;
}

export const UserList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    email: '',
    limit: 10,
    page: 1,
  });

  const navigate = useNavigate();

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isFetching, refetch } = useQuery(
    [ENDPOINT.USER.GET_LIST, params],
    () => UserService.getList(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      width: '280px',
    },
    // {
    //   title: '権限',
    //   dataIndex: 'roles',
    //   width: '100px',
    //   render: (roles: IRole[]) => roles?.[0]?.name,
    // },
    {
      title: '参加家族',
      dataIndex: 'userFamilyRoles',
      width: '140px',
      render: (userFamilyRoles: IFamilyRole[]) =>
        userFamilyRoles?.[0]?.familyId.name,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      render: (value) => (
        <Tag color={userStatusColors[value as keyof typeof userStatusColors]}>
          {userStatus[value as keyof typeof userStatus]}
        </Tag>
      ),
    },
    {
      title: 'メール認証',
      dataIndex: 'isVerified',
      width: '166px',
      align: 'center',
      render: (value) => (value ? '〇' : '✖'),
    },
    {
      title: '登録日時',
      dataIndex: 'createdAt',
      align: 'center',
      width: '166px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '退会日時',
      dataIndex: 'deletedAt',
      align: 'center',
      width: '166px',
      render: (value) =>
        value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '',
    },
    // {
    //   align: 'center',
    //   width: '166px',
    //   fixed: 'right',
    //   render: (record: IGetListUserResponse) => (
    //     <Button
    //       type='primary'
    //       icon={<EyeFilled />}
    //       onClick={() =>
    //         navigate(APP_ROUTER.USER.DETAIL.replace(':id', String(record.id)))
    //       }
    //     >
    //       Detail
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        email: search,
      }));
    }, 500);
  }, [search]);

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    reset({});
  };

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.USER.GET_DETAIL.replace(':id', String(currentId))],
    () => UserService.getDetail(Number(currentId)),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailUserResponse>) => {
        const data = response?.data;
        reset({
          id: data?.id,
          email: data?.email,
          isVerified: data?.isVerified ? 'Yes' : 'No',
          status: userStatus[data?.status as keyof typeof userStatus],
          nickname: data?.profile?.nickname,
          gender: data?.profile?.gender,
          dayOfBirth: moment(data?.profile?.dayOfBirth),
          avatar: data?.profile?.avatar?.url,
          lastLoginAt: moment(data?.lastLoginAt).format('YYYY-MM-DD HH:mm:ss'),
          lastOnlineAt: moment(data?.lastOnlineAt).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          expiredCodeRemoveAt: moment(data?.expiredCodeRemoveAt).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          createdAt: moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          deletedAt: data?.deletedAt
            ? moment(data?.deletedAt).format('YYYY-MM-DD HH:mm:ss')
            : '',
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
        handleCancel();
      },
    },
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(true, true)),
    reValidateMode: 'onChange',
  });

  const { reset, setError, watch, handleSubmit } = methods;

  useEffect(() => {
    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isNotFoundDetail]);

  const isDisabled = watch('isDisabled');

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: any; id: number }) =>
      UserService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
        setIsNotFoundDetail(true);
        handleCancel();
      },
    },
  );

  const handleUpdate = async (values: IUpdateUserRequest) => {
    const { status, gender, nickname, avatar, attachments, dayOfBirth } =
      values;
    const payload: IUpdateUserRequest = {
      status,
      gender,
      nickname,
      avatar: null,
      dayOfBirth: moment(dayOfBirth).format('YYYY-MM-DD'),
      attachments: null,
    };
    try {
      if (!values?.attachments?.fileList?.length) {
        await update({ body: payload, id: Number(currentId) });
        return;
      }
      const response = await ImageService.getPresignedUrl(1);
      await upload({
        url: response?.data?.urls[0]?.signedUrl,
        file: values?.attachments?.fileList[0]?.originFileObj,
      });
      payload.avatar = response?.data?.urls[0]?.returnUrl;
      await update({ body: payload, id: Number(currentId) });
    } catch (error) {
      console.log({ error });
    }
  };

  const { mutate: upload, isLoading: isLoadingUpload } = useMutation(
    (body: IUploadImageRequest) => ImageService.upload(body),
    {
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  return (
    <div className='p-home'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => {
              setParams({ ...params, page: 1, email: e.target.value });
            }}
          />
        </div>
        <Table
          loading={isFetching || isLoadingUpload}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              navigate(
                APP_ROUTER.USER.DETAIL.replace(':id', String(record.id)),
              );
              // setOpenDrawer(true);
              // setCurrentId(record.id);
              // setIsNotFoundDetail(false);
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title='ユーザー詳細'
        onCancel={handleCancel}
        onDelete={() => {}}
        maskClosable={false}
        open={openDrawer}
        isDeleted={false}
        disabled={isDisabled}
        onOk={handleSubmit(handleUpdate)}
        okText={'更新 '}
        loading={isLoadingUpdate || isFetchingDetail}
      >
        <FormProvider {...methods}>
          <UserForm isLoading={isFetchingDetail} openDrawer={openDrawer} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

import { PromptStatus } from '@/types/request';

export enum NOTIFY_STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  CONFIRM = 'confirm',
}

export const genderOptions = [
  {
    label: '男性',
    value: 1,
  },
  {
    label: '女性',
    value: 2,
  },
  {
    label: 'その他',
    value: 3,
  },
];

export const userStatus = {
  active: '有効',
  deaActive: '無効',
};
export const reportStatus = {
  pending: 'pending',
  accept: 'accept',
  reject: 'reject',
};

export const EventFeeType = {
  FREE: 'free',
  PAID: 'paid',
};

export const userStatusColors = {
  active: 'green',
  deaActive: 'red',
};

export const reportStatusColors = {
  pending: 'yellow',
  accept: 'green',
  reject: 'red',
};

export const verifyStatus = {
  true: '〇',
  false: '✖',
};

export const verifyStatusColors = {
  true: 'green',
  false: 'red',
};

export const postTypes = {
  easy: 'Easy',
  freeInput: 'Free Input',
};

export const postTypeColors = {
  easy: 'green',
  freeInput: 'red',
};

export const postStatus = {
  public: 'Public',
  draft: 'Draft',
  canceled: 'Canceled',
  temporary: 'Temporary',
};

export const promptStatusText = {
  [PromptStatus.DRAFT]: '下書き',
  [PromptStatus.ENABLE]: '有効',
};

export const promptStatusColors = {
  [PromptStatus.DRAFT]: 'default',
  [PromptStatus.ENABLE]: 'success',
};

export const postStatusColors = {
  public: 'success',
  draft: 'default',
  canceled: 'error',
  temporary: 'warning',
};

export const notificationStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const eventFeeTypeOptions = [
  {
    label: '無料',
    value: 'free',
  },
  {
    label: '有料',
    value: 'paid',
  },
];

export const eventStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const contentStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const surveyStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const promptStatus = [
  {
    label: '有効',
    value: PromptStatus.ENABLE,
  },
  {
    label: '下書き',
    value: PromptStatus.DRAFT,
  },
];

export const postCodeTypeOptions = [
  {
    label: '全て',
    value: 0,
  },
  {
    label: '郵便局指定',
    value: 1,
  },
];

export const mustUpdateOptions = [
  {
    label: '必須',
    value: 1,
  },
  {
    label: '任意',
    value: 0,
  },
];

export enum PromptType {
  IMAGE_FREE_INPUT = 'imageFreeInput',
  TEXT_FREE_INPUT = 'textFreeInput',
  TITLE_FREE_INPUT = 'titleFreeInput',
  CONTENT_FREE_INPUT = 'contentFreeInput',
  IMAGE_EASY_INPUT = 'imageEasyInput',
  TEXT_EASY_INPUT = 'textEasyInput',
  TITLE_EASY_INPUT = 'titleEasyInput',
  CONTENT_EASY_INPUT = 'contentEasyInput',
  AUTO_GENERATE = 'autoGenerate',
}

export enum DISPLAY_MODE {
  DEFAULT = 'default',
  REPORT = 'report',
}

export const modulesReactQuill = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ direction: 'rtl' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

import { IResponse, IResponsePagination } from '@/types/common';
import {
  ICreateContentRequest,
  IGetListContentRequest,
  IUpdateContentRequest,
} from '@/types/request';
import { IGetListContentResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Content {
  getList = (
    params: IGetListContentRequest,
  ): Promise<IResponsePagination<IGetListContentResponse>> => {
    return axiosClient.get(ENDPOINT.CONTENT.GET_LIST, { params: params });
  };

  create = (body: ICreateContentRequest): Promise<IResponse<any>> => {
    return axiosClient.post(ENDPOINT.CONTENT.CREATE, body);
  };

  getDetail = (id: number): Promise<IResponse<any>> => {
    return axiosClient.get(
      ENDPOINT.CONTENT.GET_DETAIL.replace(':id', String(id)),
    );
  };

  update = (data: IUpdateContentRequest, id: number): Promise<IResponse<any>> => {
    return axiosClient.put(
      ENDPOINT.CONTENT.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(ENDPOINT.CONTENT.DELETE.replace(':id', String(id)));
  };
}

export const ContentService = new Content();

import { IResponse, IResponsePagination } from '@/types/common';
import {
  IGetListPostFukumaruRequest,
  IUpdatePostFukumaruRequest,
} from '@/types/request';
import {
  IGetDetailPostFukumaruResponse,
  IGetListPostFukumaruResponse,
  IUpdatePostFukumaruResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class PostFukumaru {
  getList = (
    params: IGetListPostFukumaruRequest,
  ): Promise<IResponsePagination<IGetListPostFukumaruResponse>> => {
    return axiosClient.get(ENDPOINT.POST_FUKUMARU.GET_LIST, { params: params });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailPostFukumaruResponse>> => {
    return axiosClient.get(
      ENDPOINT.POST_FUKUMARU.GET_DETAIL.replace(':id', String(id)),
    );
  };

  update = (
    data: IUpdatePostFukumaruRequest,
    id: number,
  ): Promise<IResponse<IUpdatePostFukumaruResponse>> => {
    return axiosClient.put(
      ENDPOINT.POST_FUKUMARU.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.POST_FUKUMARU.DELETE.replace(':id', String(id)),
    );
  };
}

export const PostFukumaruService = new PostFukumaru();

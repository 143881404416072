import { IResponse, IResponsePagination } from '@/types/common';
import { IGetListUserRequest, IUpdateUserRequest } from '@/types/request';
import {
  IGetDetailUserResponse,
  IGetListUserResponse,
  IGetMeResponse,
  IUpdateUserResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class User {
  getList = (
    params: IGetListUserRequest,
  ): Promise<IResponsePagination<IGetListUserResponse>> => {
    return axiosClient.get(ENDPOINT.USER.GET_LIST, { params: params });
  };

  getDetail = (id: number): Promise<IResponse<IGetDetailUserResponse>> => {
    return axiosClient.get(ENDPOINT.USER.GET_DETAIL.replace(':id', String(id)));
  };

  getMe = async (): Promise<IResponse<IGetMeResponse>> => {
    const res: IResponse<IGetMeResponse> = await axiosClient.get(
      ENDPOINT.USER.GET_ME,
    );

    return res;
  };

  update = (
    data: IUpdateUserRequest,
    id: number,
  ): Promise<IResponse<IUpdateUserResponse>> => {
    return axiosClient.put(
      ENDPOINT.USER.UPDATE.replace(':id', String(id)),
      data,
    );
  };
}

export const UserService = new User();

import * as yup from 'yup';
import { FORM_NAME } from './constants';

export const formSchemaFn = (formName: FORM_NAME) => {
  return yup.object().shape({
    promptInputTypeId: yup.number().required('機能は未入力です。'),
    title: yup
      .string()
      .required('タイトルは未入力です。')
      .max(100, 'タイトルは100文字以内で入力してください。'),
    instructions: yup
      .string()
      .required('命令・指示は未入力です。')
      .max(3000, '命令・指示は3000文字以内で入力してください。'),
    status: yup.string().required('ステータスは未入力です。'),
  });
};

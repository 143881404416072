import {
  ICheckResetPasswordRequest,
  IForgotPasswordRequest,
  ILoginRequest,
  IResetPasswordRequest,
} from '@/types/request';
import { setCookie } from '@/utils/helpers/storage';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';
import { IResponse } from '@/types/common';
import { ILoginResponse } from '@/types/response';

class Auth {
  login = async (body: ILoginRequest): Promise<IResponse<ILoginResponse>> => {
    const res: IResponse<ILoginResponse> = await axiosClient.post(
      ENDPOINT.AUTH.LOGIN,
      body
    );

    console.log({ res });

    const accessToken = res?.data?.accessToken;

    if (accessToken) {
      setCookie('accessToken', accessToken, { expires: 365 });
    }

    return res;
  };

  forgotPassword = (body: IForgotPasswordRequest) => {
    return axiosClient.post(ENDPOINT.AUTH.FORGOT_PASSWORD, body);
  };

  checkResetPassword = (body: ICheckResetPasswordRequest) => {
    return axiosClient.post(ENDPOINT.AUTH.CHECK_RESET_PASSWORD, body);
  };

  resetPassword = (body: IResetPasswordRequest) => {
    return axiosClient.post(ENDPOINT.AUTH.RESET_PASSWORD, body);
  };

  logout = () => {
    return axiosClient.post(ENDPOINT.AUTH.LOGOUT);
  };
}

export const AuthService = new Auth();

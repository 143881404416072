import {
  IGetListCommonRequest,
  IResponse,
  IResponsePagination,
} from '@/types/common';
import {
  ICreateSurveyHealthQuestionResponse,
  IGetDetailSurveyHealthQuestionResponse,
  IGetListSurveyHeathQuestionResponse,
  IUpdateSurveyHealthQuestionResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';
import {
  ICreateSurveyHealthQuestionRequest,
  IUpdateSurveyHealthQuestionRequest,
} from '@/types/request';

class SurveyHealthQuestion {
  getList = (
    params?: IGetListCommonRequest,
  ): Promise<IResponsePagination<IGetListSurveyHeathQuestionResponse>> => {
    return axiosClient.get(ENDPOINT.SURVEY_HEALTH_QUESTION.GET_LIST, {
      params: params,
    });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailSurveyHealthQuestionResponse>> => {
    return axiosClient.get(
      ENDPOINT.SURVEY_HEALTH_QUESTION.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateSurveyHealthQuestionRequest,
  ): Promise<IResponse<ICreateSurveyHealthQuestionResponse>> => {
    return axiosClient.post(ENDPOINT.SURVEY_HEALTH_QUESTION.CREATE, body);
  };

  update = (
    data: IUpdateSurveyHealthQuestionRequest,
    id: number,
  ): Promise<IResponse<IUpdateSurveyHealthQuestionResponse>> => {
    return axiosClient.put(
      ENDPOINT.SURVEY_HEALTH_QUESTION.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.SURVEY_HEALTH_QUESTION.DELETE.replace(':id', String(id)),
    );
  };
}

export const SurveyHealthQuestionService = new SurveyHealthQuestion();

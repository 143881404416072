import * as yup from 'yup';
import { FORM } from './constants';
import moment from 'moment';

export const formSchemaFn = (formName: FORM) =>
  yup.object().shape({
    date:
      formName === FORM.CREATE_SPECIAL_DAY &&
      yup
        .string()
        .required('Please input date')
        .test('is-valid-date', 'Please use MM-DD', (value) =>
          moment(value, 'MM-DD', true).isValid(),
        ),
    title: yup.string().max(100, 'Length must not exceed 100 characters'),
    description: yup.string().max(500, 'Length must not exceed 500 characters'),
    name: yup.string().max(100, 'Length must not exceed 100 characters'),
  } as any);

import { Divider, Modal } from 'antd';

interface IProps {
  title: string;
  description: string;
  onOk?: () => void;
  onCancel?: () => void;
  open: boolean;
}

export const ModalCommon: React.FC<IProps> = ({
  title,
  description,
  open,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title={
        <div className='font-semibold text-3xl'>
          {title}
          <Divider />
        </div>
      }
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    >
      <p className='font-semibold text-base'>{description}</p>
      <Divider />
    </Modal>
  );
};

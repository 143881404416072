import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    title: yup
      .string()
      .required('Please input title')
      .max(100, 'Length must not exceed 100 characters'),
    content: yup
      .string()
      .required('Please input content')
      .max(500, 'Length must not exceed 100 characters'),
  } as any);

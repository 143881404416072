import { Field, Input, TextArea } from '@/components/commons';
import { Form } from 'antd';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FORM_NAME } from '../../constants';

export const UserForm = () => {
  const { control, reset } = useFormContext();

  const formMonitor = useWatch({
    control,
  });

  useEffect(() => {
    reset({
      formName: FORM_NAME.CREATE_USER,
    });
  }, []);

  console.log({ formMonitor });

  return (
    <Form layout='vertical'>
      <Field label='Title' name='title'>
        <Input placeholder='Enter title' allowClear />
      </Field>
      <Field label='Content' name='content'>
        <TextArea placeholder='Enter content' />
      </Field>
    </Form>
  );
};

import moment from 'moment';
import * as yup from 'yup';

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    title: yup
      .string()
      .required('タイトルは未入力です。')
      .max(100, 'タイトルは100文字以内で入力してください。'),
    message: yup
      .string()
      .required('本文は未入力です。')
      .max(500, '本文は500文字以内で入力してください。'),
    status: yup
      .boolean()
      .required('ステータスは未入力です。')
      .oneOf([true, false], 'ステータスは未入力です。'),
    sentFromAt: yup
      .date()
      .required('開始日時を選択してください。')
      .when('status', ([status], schema) => {
        if ((isActionEdit && isNotPermissionEdit) || !status) {
          return schema;
        }

        return schema.min(
          moment().add(1, 'minute').startOf('minute').toDate(),
          '配信日時が過去の日時になっています。',
        );
      }),
    sentToAt: yup
      .date()
      .required('終了日時を選択してください。')
      .when('sentFromAt', ([sentFromAt], schema) => {
        if (!sentFromAt) {
          return schema;
        }

        return schema.min(
          yup.ref('sentFromAt'),
          '終了日時は開始日時より後の日付を選択してください。',
        );
      }),
  } as any);

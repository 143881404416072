import { REGEX } from '@/utils';
import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    newPassword: yup
      .string()
      .matches(REGEX.PASSWORD, 'Password is too weak')
      .min(8, 'Password must be at least 8 characters')
      .required('Please input password'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Please confirm your password'),
  } as any);

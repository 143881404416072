import { IResponse, IResponsePagination } from '@/types/common';
import { IGetListPromptKeyGroupRequest } from '@/types/request';
import {
  IGetDetailPromptKeyGroupResponse,
  IGetListPromptKeyGroupResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class PromptKeyGroup {
  getList = (
    params: IGetListPromptKeyGroupRequest,
  ): Promise<IResponsePagination<IGetListPromptKeyGroupResponse>> => {
    return axiosClient.get(ENDPOINT.PROMPT_KEY_GROUP.GET_LIST, {
      params: params,
    });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailPromptKeyGroupResponse>> => {
    return axiosClient.get(
      ENDPOINT.PROMPT_KEY_GROUP.GET_DETAIL.replace(':id', String(id)),
    );
  };
}

export const PromptKeyGroupService = new PromptKeyGroup();

import { IResponse, IResponsePagination } from '@/types/common';
import {
  ICreatePromptRequest,
  IGetListPromptRequest,
  IUpdatePromptRequest,
} from '@/types/request';
import {
  ICreatePromptResponse,
  IGetDetailPromptResponse,
  IGetListPromptResponse,
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Prompt {
  getList = (
    params: IGetListPromptRequest,
  ): Promise<IResponsePagination<IGetListPromptResponse>> => {
    return axiosClient.get(ENDPOINT.PROMPT.GET_LIST, { params: params });
  };

  getDetail = (id: number): Promise<IResponse<IGetDetailPromptResponse>> => {
    return axiosClient.get(
      ENDPOINT.PROMPT.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreatePromptRequest,
  ): Promise<IResponse<ICreatePromptResponse>> => {
    return axiosClient.post(ENDPOINT.PROMPT.CREATE, body);
  };

  update = (
    id: string,
    body: IUpdatePromptRequest,
  ): Promise<IResponse<ICreatePromptResponse>> => {
    return axiosClient.post(
      ENDPOINT.PROMPT.UPDATE.replace(':id', String(id)),
      body,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.PROMPT.DELETE.replace(':id', String(id)),
    );
  };
}

export const PromptService = new Prompt();

import { Input, Pagination, Table } from '@/components/commons';
import { ENDPOINT } from '@/services/endpoint';
import { convertRequest } from '@/utils';
import { SearchOutlined } from '@ant-design/icons';
import { TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import { PostReportService } from '@/services/post-report';
import './style.scss';

interface IProps {
  title?: string;
  postId?: string | null;
}

interface IParams {
  reason: string;
  limit: number;
  page: number;
}

export const PostReportList: React.FC<IProps> = ({ title, postId }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    reason: '',
    limit: 10,
    page: 1,
    ...(postId && { postId }),
  });

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isFetching } = useQuery(
    [ENDPOINT.USER_REPORT.GET_LIST, params],
    () => PostReportService.getList(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '200px',
      render: (_, record) => (
        <Link
          to={APP_ROUTER.POST.DETAIL.replace(':id', String(record?.postId?.id))}
        >
          {record?.postId?.title}
        </Link>
      ),
    },
    {
      title: '報告者',
      dataIndex: 'createdBy',
      width: '100px',
      render: (_, record) => (
        <Link
          to={APP_ROUTER.USER.DETAIL.replace(
            ':id',
            String(record?.createdBy?.id),
          )}
        >
          {record?.createdBy?.email}
        </Link>
      ),
    },
    {
      title: 'ニックネーム',
      dataIndex: 'nickname',
      width: '150px',
      render: (_, record) => record?.createdBy?.profile?.nickname,
    },
    // {
    //   title: 'reason',
    //   dataIndex: 'reason',
    //   width: '280px',
    // },
    // {
    //   title: 'ステータス',
    //   dataIndex: 'status',
    //   width: '80px',
    //   align: 'center',
    //   render: (value) => (
    //     <Tag
    //       color={reportStatusColors[value as keyof typeof reportStatusColors]}
    //     >
    //       {reportStatus[value as keyof typeof reportStatus]}
    //     </Tag>
    //   ),
    // },
    {
      title: '報告日時',
      dataIndex: 'createdAt',
      align: 'center',
      width: '166px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    // {
    //   align: 'center',
    //   width: '166px',
    //   fixed: 'right',
    //   render: (record: IGetListUserResponse) => (
    //     <Button
    //       type='primary'
    //       icon={<EyeFilled />}
    //       onClick={() =>
    //         navigate(APP_ROUTER.USER.DETAIL.replace(':id', String(record.id)))
    //       }
    //     >
    //       Detail
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        reason: search,
      }));
    }, 500);
  }, [search]);

  return (
    <div className='p-home'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          // onRow={(record) => ({
          //   onClick: () => {
          //     navigate(
          //       APP_ROUTER.USER.DETAIL.replace(':id', String(record.id)),
          //     );
          //   },
          // })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
    </div>
  );
};

import { Field, Input, Spin, TextArea } from '@/components/commons';
import { Badge, Button, Card, Divider, Form, InputNumber } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
  isLoading: boolean;
}

export const SurveyHealthForm: React.FC<IProps> = ({ isLoading }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'surveyHealthAnswers',
  });

  const isDisabled = watch('isDisabled');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='質問' name='question' required>
          <TextArea
            placeholder='入力してください'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field name='sortOrder' className='flex-1' label='表示順' required>
          <InputNumber min={0} disabled={isDisabled} />
        </Field>
        <Divider className='border-t-2 border-gray-500' />
        <Card
          title={
            <label className='title-calendar font-semibold'>
              回答選択肢一覧
              <Badge status='error' className='ml-2' />
            </label>
          }
          extra={
            <Button
              type='primary'
              onClick={() =>
                append({ answer: null, point: null, sortOrder: null })
              }
            >
              選択肢追加
            </Button>
          }
          className='bg-gray-100'
        >
          {fields.map((field, index) => (
            <Card
              key={field?.id}
              className='relative mb-4 bg-white border border-gray-100 shadow-sm'
            >
              {fields.length > 1 && (
                <Button
                  type='primary'
                  shape='circle'
                  icon={<CloseOutlined />}
                  size='small'
                  onClick={() => remove(index)}
                  className='absolute -top-2 -right-2 z-10 bg-red-600 hover:bg-red-700 border-none text-white shadow-md'
                />
              )}
              <Field
                label='回答選択内容'
                name={`surveyHealthAnswers.${index}.answer`}
                help={(errors?.surveyHealthAnswers as any)?.[index]?.answer}
                required
              >
                <Input
                  placeholder='入力してください'
                  maxLength={100}
                  disabled={isDisabled}
                />
              </Field>
              <div className='flex'>
                <Field
                  name={`surveyHealthAnswers.${index}.point`}
                  className='flex-1'
                  label='点数'
                  required
                  help={(errors?.surveyHealthAnswers as any)?.[index]?.point}
                >
                  <InputNumber min={0} disabled={isDisabled} />
                </Field>
                <Field
                  name={`surveyHealthAnswers.${index}.sortOrder`}
                  className='flex-1'
                  label='表示順'
                  required
                  help={
                    (errors?.surveyHealthAnswers as any)?.[index]?.sortOrder
                  }
                >
                  <InputNumber min={0} disabled={isDisabled} />
                </Field>
              </div>
            </Card>
          ))}
        </Card>
      </Spin>
    </Form>
  );
};

import { Tabs } from 'antd';
import { UserList } from '../UserList';
import { UserReportList } from '../UserReportList';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { DISPLAY_MODE } from '@/utils';
import { Helmet } from 'react-helmet-async';

interface IProps {
  title?: string;
}

export const UserPage: React.FC<IProps> = ({title}) => {
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState<string>(
    searchParams.get('userId') || '',
  );
  const mode =
    (searchParams.get('mode') as DISPLAY_MODE) || DISPLAY_MODE.DEFAULT;

  return (
    <>
      <Tabs
      onChange={() => setUserId('')}
      destroyInactiveTabPane={true}
      defaultActiveKey={mode}
      type='card'
      items={[
        {
          label: `ユーザ一覧`,
          key: DISPLAY_MODE.DEFAULT,
          children: <UserList title={title}/>,
        },
        {
          label: '報告一覧',
          key: DISPLAY_MODE.REPORT,
          children: <UserReportList title={title} userId={userId} />,
        },
      ]}
    />
    </>
    
  );
};

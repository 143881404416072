import { Button, Drawer, Input, Pagination, Table } from '@/components/commons';
import { NOTIFY_STATUS, notificationModal } from '@/utils';
import {
  DeleteFilled,
  EyeFilled,
  PlusCircleFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { TableColumnsType } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { formSchemaFn } from './formSchemaFn';
import './style.scss';
import { UserForm } from './Drawers';

interface IProps {
  title?: string;
}

export const Example: React.FC<IProps> = ({ title }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [formName, setFormName] = useState<string>('');

  const data = [];
  for (let i = 1; i <= 10; i++) {
    data.push({
      id: i,
      title: `Lorem Ipsum is simply dummy text of the printing and typesetting industry ${i}.`,
      content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. `,
    });
  }

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(formName)),
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '180px',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: '220px',
    },
    {
      title: 'Actions',
      align: 'center',
      width: '220px',
      fixed: 'right',
      render: (record: any) => (
        <div className='flex flex-col gap-2 lg:flex-row'>
          <Button type='primary' icon={<EyeFilled />}>
            Detail
          </Button>
          <Button
            type='primary'
            icon={<DeleteFilled />}
            danger
            onClick={() =>
              notificationModal({
                type: NOTIFY_STATUS.CONFIRM,
                title: 'Notification',
                content: 'Do you really want to delete this item?',
                maskClosable: false,
              })
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleCreate = (values: any) => {
    console.log('submit', values);
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    reset({});
  };

  useEffect(() => {
    setFormName(watch('formName'));
  }, [watch('formName')]);

  return (
    <div className='p-example'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full lg:w-[1000px]'>
        <header className='flex justify-between w-full lg:w-[1000px]'>
          <h3 className='font-semibold text-[20px]'>Fukumaru's Post List</h3>
          <Button
            type='primary'
            icon={<PlusCircleFilled />}
            onClick={() => setOpenDrawer(true)}
          >
            Create
          </Button>
        </header>
        <div className='flex items-center gap-[6px]'>
          <span className='font-semibold text-[20px]'>ユーザー</span>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
          />
        </div>
        <Table
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <Pagination
          className='pagination-table'
          total={10}
          pageSize={10}
          current={1}
          showSizeChanger={false}
        />
      </div>
      <Drawer
        title='Drawer with extra actions'
        onOk={handleSubmit(handleCreate)}
        onCancel={handleCancel}
        open={openDrawer}
      >
        <FormProvider {...methods}>
          <UserForm />
        </FormProvider>
      </Drawer>
    </div>
  );
};

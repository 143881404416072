import { ISvgIcon } from '@/types/common';

export const OwlIcon: React.FC<ISvgIcon> = ({
  width = 22,
  height = 22,
  stroke = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 48 48'
      {...props}
    >
      <g fill='none' stroke={stroke} strokeWidth={4}>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M6.358 7.31c2.662 3.848 6.186 5.092 7.86 5.215 2.855-1.398 6.203-2.202 9.782-2.202 3.58 0 6.927.804 9.782 2.201 1.674-.122 5.198-1.366 7.86-5.214.757-.821 3.03-.439 2.164 6.573-.289 1.756-1.087 5.585-1.616 7.49.488 1.361.75 2.8.75 4.289C42.94 34.132 34.46 41 24 41S5.06 34.133 5.06 25.662c0-1.489.262-2.928.75-4.289-.529-1.905-1.327-5.735-1.616-7.49C3.328 6.871 5.6 6.49 6.358 7.31'
        />
        <path
          strokeLinejoin='round'
          d='M25 31.25c0 .69-1 3.75-1 3.75s-1-3.06-1-3.75.448-1.25 1-1.25 1 .56 1 1.25Z'
        />
        <circle cx={17} cy={22} r={4} />
        <circle cx={31} cy={22} r={4} />
      </g>
    </svg>
  );
};

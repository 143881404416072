import {
  Field,
  Input,
  RadioGroup,
  Select,
  Spin,
  TextArea,
} from '@/components/commons';
import { convertArrayToOptions, promptStatus } from '@/utils';
import { Form } from 'antd';

interface IProps {
  isLoading?: boolean;
  promptInputTypes: IPromptInputTypesOptions[] | undefined;
}

interface IPromptInputTypesOptions {
  id: number;
  name: string;
}

export const PromptForm: React.FC<IProps> = ({
  isLoading,
  promptInputTypes,
}) => {
  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='タイトル' name='title' required>
          <Input placeholder='入力してください' />
        </Field>
        <Field label='機能' name='promptInputTypeId' required>
          <Select
            allowClear
            placeholder='選択してください'
            options={convertArrayToOptions(
              promptInputTypes ?? [],
              'id',
              'name',
            )}
          />
        </Field>
        <Field label='命令・指示' name='instructions' required>
          <TextArea placeholder='入力してください' rows={6} />
        </Field>
        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={promptStatus} />
        </Field>
      </Spin>
    </Form>
  );
};

import { Helmet } from 'react-helmet-async';
import './style.scss';

interface IProps {
  title?: string;
}

export const Home: React.FC<IProps> = ({ title }) => {
  return (
    <div className='p-home'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full lg:w-[1000px]'></div>
    </div>
  );
};

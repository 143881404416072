import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { NotificationService } from '@/services/notification';
import { IResponse, IResponseError } from '@/types/common';
import {
  ICreateNotificationRequest,
  IUpdateNotificationRequest,
} from '@/types/request/notification';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  notificationStatus,
  NOTIFY_STATUS,
} from '@/utils';
import { ERROR, SUCCESS, SUCCESS_MESSAGE } from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { NotificationForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/NotificationForm/formSchemaFn';
import { IGetDetailNotificationResponse } from '@/types/response/notification';
import { FORM_NAME } from '../Drawers/NotificationForm/constants';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
}

export const NotificationList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailNotificationResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
  });

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.NOTIFICATION.GET_LIST, params],
    () => NotificationService.getList(convertRequest(params)),
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId!, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleCreate = (values: ICreateNotificationRequest) => {
    const payload = {
      title: values.title,
      message: values.message,
      status: values.status,
      sentFromAt: moment(values.sentFromAt).utc(),
      sentToAt: moment(values.sentToAt).utc(),
    };

    createNotification(payload);
  };

  const { mutate: createNotification, isLoading: isLoadingCreateNotification } =
    useMutation(
      (body: ICreateNotificationRequest) => NotificationService.create(body),
      {
        onSuccess: () => {
          notificationMessage({
            message:
              SUCCESS_MESSAGE[
                SUCCESS.CREATE_NOTIFICATION as keyof typeof SUCCESS_MESSAGE
              ],
            type: NOTIFY_STATUS.SUCCESS,
          });
          refetch();
          handleCancel();
          setParams({ ...params, page: 1 });
        },
        onError: (response: IResponseError) => {
          handleErrorCommon(response, setError);
        },
      },
    );

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.NOTIFICATION.GET_DETAIL.replace(':id', String(currentId))],
    () => NotificationService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailNotificationResponse>) => {
        const data = response.data;
        setDetail(data);

        reset({
          formName: FORM_NAME.UPDATE_NOTIFICATION,
          title: data.title,
          message: data.message,
          status: data.status ? 1 : 0,
          sentFromAt: moment(data.notificationSchedule.sentFromAt),
          sentToAt: moment(data.notificationSchedule.sentToAt),
          isDisabled: (data?.status &&
            moment().isAfter(
              moment(data.notificationSchedule.sentFromAt),
            )) as boolean,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = (values: IUpdateNotificationRequest) => {
    const payload = {
      id: currentId!,
      body: {
        title: values.title,
        message: values.message,
        status: values.status,
        sentFromAt: moment(values.sentFromAt).utc(),
        sentToAt: moment(values.sentToAt).utc(),
        updatedAt: detail?.updatedAt,
      },
    };

    updateNotification(payload);
  };

  const handleDelete = (data: IGetDetailNotificationResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='タイトル' content={data?.title} />,
      maskClosable: false,
      onOk: () => deleteNotification(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { mutate: updateNotification, isLoading: isLoadingUpdateNotification } =
    useMutation(
      (payload: { body: IUpdateNotificationRequest; id: number }) =>
        NotificationService.update(payload.body, payload.id),
      {
        onSuccess: () => {
          notificationMessage({
            message:
              SUCCESS_MESSAGE[
                SUCCESS.UPDATE_NOTIFICATION as keyof typeof SUCCESS_MESSAGE
              ],
            type: NOTIFY_STATUS.SUCCESS,
          });
          refetch();
          handleCancel();
          setParams({ ...params, page: 1 });
        },
        onError: (error: IResponseError) => {
          handleErrorCommon(error, setError);

          if (error.error === ERROR.NOTIFICATION_NOT_FOUND) {
            setOpenDrawer(false);
          }
        },
      },
    );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteNotification, isLoading: isLoadingDeleteNotification } =
    useMutation((id: number) => NotificationService.delete(id), {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_NOTIFICATION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.NOTIFICATION_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    });

  const columns: TableColumnsType = [
    {
      title: 'タイトル',
      dataIndex: 'title',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: '配信日時',
      render: (value, record) => {
        return `${moment(record.notificationSchedule.sentFromAt).format('YYYY年MM月DD日 HH:mm')}～${moment(record.notificationSchedule.sentToAt).format('YYYY年MM月DD日 HH:mm')}`;
      },
    },
    {
      title: 'ステータス',
      width: '300px',
      render: (value, record) => {
        if (!record.status) {
          return notificationStatus[1].label;
        }

        return moment().isBefore(moment(record.notificationSchedule.sentFromAt))
          ? '配信予約済'
          : '配信中';
      },
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div className='p-notification h-full'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='text-right'>
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            お知らせ新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col h-full'>
          <Table
            loading={isFetching}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'お知らせはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? 'お知らせ新規登録' : 'お知らせ詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          currentId
            ? isLoadingUpdateNotification ||
              isLoadingDeleteNotification ||
              isFetchingDetail
            : isLoadingCreateNotification
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
        disabledDelete={false}
      >
        <FormProvider {...methods}>
          <NotificationForm isLoading={isFetchingDetail} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

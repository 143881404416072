import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  contentStatus,
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
} from '@/utils';
import { ERROR, SUCCESS, SUCCESS_MESSAGE } from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { ContentForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/ContentForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/ContentForm/constants';
import { ICreateContentRequest, IUpdateContentRequest } from '@/types/request';
import { ImageService } from '@/services/image';
import { IUploadImageRequest } from '@/types/request/upload';
import { IGetDetailContentResponse } from '@/types/response';
import { ContentService } from '@/services';
import { SurveyService } from '@/services/survey';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
}

export interface ContentImage {
  url?: string;
  file?: File;
  previewUrl?: string;
}

const defaultImages = [null, null, null, null, null, null];

export const ContentList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailContentResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
  });

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.CONTENT.GET_LIST, params],
    () => ContentService.getList(convertRequest(params)),
  );

  const {
    data: dataSurveys,
    refetch: refetchSurveys,
    isFetching: isFetchingSurveys,
  } = useQuery(
    [ENDPOINT.SURVEY.GET_LIST, params],
    () => SurveyService.getList(),
    {
      enabled: openDrawer,
    },
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({ images: defaultImages });
  };

  const handleCreate = async (values: ICreateContentRequest) => {
    const { images, ...rest } = values;
    const payload: ICreateContentRequest = {
      ...rest,
      surveyIds: values?.surveyIds ? [values?.surveyIds as number] : [],
      status: values?.status ? 1 : 0,
    };
    try {
      const newImages = images?.filter((image) => image);
      if (newImages) {
        const response = await ImageService.getPresignedUrl(newImages?.length);
        const imageUploads: any = [];
        newImages?.forEach((image, index) => {
          imageUploads.push(
            upload({
              url: response?.data?.urls[index]?.signedUrl,
              file: image?.file as ContentImage,
            }),
          );
        });
        await Promise.all(imageUploads);
        payload.images = response?.data?.urls.map(
          (value: any) => value.returnUrl,
        );
      }
      await createContent(payload);
    } catch (error) {
      console.log({ error });
    }
  };

  const { mutate: createContent, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateContentRequest) => ContentService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.CREATE_CONTENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
        if (response.message === ERROR.SURVEY_NOT_FOUND) {
          refetchSurveys();
        }
      },
    },
  );

  const { mutate: upload, isLoading: isLoadingUpload } = useMutation(
    (body: IUploadImageRequest) => ImageService.upload(body),
    {
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.CONTENT.GET_DETAIL.replace(':id', String(currentId))],
    () => ContentService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailContentResponse>) => {
        const data = response?.data;
        setDetail(data);
        const isDisabled =
          !!data?.status && moment().isSameOrAfter(moment(data?.contentTo));
        const payload: ICreateContentRequest = {
          name: data?.name,
          title: data?.title,
          images: data?.imageIds?.length
            ? defaultImages.map((_, index) => {
                if (data?.imageIds[index]) {
                  return {
                    url: data?.imageIds[index]?.url,
                    previewUrl: data?.imageIds[index]?.url,
                  };
                }
                return null;
              })
            : defaultImages,
          contentFrom: moment(data?.contentFrom),
          contentTo: moment(data?.contentTo),
          surveyIds: data?.surveyIds[0]?.id,
          status: data?.status ? 1 : 0,
          isDisabled,
        };
        reset({
          formName: FORM_NAME.UPDATE_CONTENT,
          ...payload,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = async (values: IUpdateContentRequest) => {
    const { images, ...rest } = values;
    const payload: IUpdateContentRequest = {
      ...rest,
      surveyIds: values?.surveyIds ? [values?.surveyIds as number] : [],
      status: values?.status ? 1 : 0,
    };
    try {
      const newImages = images?.filter((value) => value?.file);
      if (newImages?.length) {
        const response = await ImageService.getPresignedUrl(newImages?.length);
        const imageUploads: any = [];
        newImages?.forEach((image, index) => {
          imageUploads.push(
            upload({
              url: response?.data?.urls[index]?.signedUrl,
              file: image?.file as ContentImage,
            }),
          );
        });
        await Promise.all(imageUploads);
        let uploadedIndex = 0;
        payload.images = images
          ?.filter((value) => value)
          ?.map((value: any) => {
            if (value?.file) {
              const url = response?.data?.urls[uploadedIndex]?.returnUrl;
              uploadedIndex++;
              return url;
            }
            return value.url;
          });
      } else {
        payload.images = images
          ?.filter((value) => value)
          ?.map((value: any) => value.url);
      }
      await updateContent({ body: payload, id: Number(currentId) });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDelete = (data: IGetDetailContentResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='コンテンツ名' content={data?.name} />,
      maskClosable: false,
      onOk: () => deleteContent(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { mutate: updateContent, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateContentRequest; id: number }) =>
      ContentService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.UPDATE_CONTENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        if (error.error === ERROR.SURVEY_NOT_FOUND) {
          refetchSurveys();
        }

        if (error.error === ERROR.CONTENT_NOT_FOUND) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteContent, isLoading: isLoadingDelete } = useMutation(
    (id: number) => ContentService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_CONTENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.CONTENT_RELOAD_DATA) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const columns: TableColumnsType = [
    {
      title: 'コンテンツ名',
      dataIndex: 'name',
      width: '500px',
    },
    {
      title: '配信期間',
      render: (value, record) => {
        return `${moment(record.contentFrom).format('YYYY年MM月DD日 HH:mm')}～${moment(record.contentTo).format('YYYY年MM月DD日 HH:mm')}`;
      },
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='text-right'>
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({ images: defaultImages }),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            コンテンツ新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col'>
          <Table
            loading={isFetching || isLoadingUpload}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'コンテンツはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? 'コンテンツ新規作成' : 'コンテンツ詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          isLoadingUpdate ||
          isLoadingDelete ||
          isFetchingDetail ||
          isLoadingCreate ||
          isLoadingUpload
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
        allowDelete
      >
        <FormProvider {...methods}>
          <ContentForm
            isLoading={
              isFetchingDetail ||
              isLoadingUpdate ||
              isLoadingCreate ||
              isLoadingDelete ||
              isFetchingSurveys
            }
            openDrawer={openDrawer}
            surveyOptions={dataSurveys?.data?.data}
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

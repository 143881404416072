export enum NOTIFY_STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  CONFIRM = 'confirm',
}

export const genderOptions = [
  {
    label: '男性',
    value: 1,
  },
  {
    label: '女性',
    value: 2,
  },
  {
    label: 'その他',
    value: 3,
  },
];

export const userStatus = {
  active: 'active',
  deaActive: 'deaActive',
};

export const EventFeeType = {
  FREE: 'free',
  PAID: 'paid',
};

export const userStatusColors = {
  active: 'green',
  deaActive: 'red',
};

export const verifyStatus = {
  true: 'Yes',
  false: 'No',
};

export const verifyStatusColors = {
  true: 'green',
  false: 'red',
};

export const postTypes = {
  easy: 'Easy',
  freeInput: 'Free Input',
};

export const postTypeColors = {
  easy: 'green',
  freeInput: 'red',
};

export const postStatus = {
  public: 'Public',
  draft: 'Draft',
  canceled: 'Canceled',
  temporary: 'Temporary',
};

export const postStatusColors = {
  public: 'success',
  draft: 'default',
  canceled: 'error',
  temporary: 'warning',
};

export const notificationStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const eventFeeTypeOptions = [
  {
    label: '無料',
    value: 'free',
  },
  {
    label: '有料',
    value: 'paid',
  },
];

export const eventStatus = [
  {
    label: '公開',
    value: 1,
  },
  {
    label: '下書き',
    value: 0,
  },
];

export const postCodeTypeOptions = [
  {
    label: '全て',
    value: 0,
  },
  {
    label: '郵便番号指定',
    value: 1,
  },
];

export enum PromptType {
  IMAGE_FREE_INPUT = 'imageFreeInput',
  TEXT_FREE_INPUT = 'textFreeInput',
  TITLE_FREE_INPUT = 'titleFreeInput',
  CONTENT_FREE_INPUT = 'contentFreeInput',
  IMAGE_EASY_INPUT = 'imageEasyInput',
  TEXT_EASY_INPUT = 'textEasyInput',
  TITLE_EASY_INPUT = 'titleEasyInput',
  CONTENT_EASY_INPUT = 'contentEasyInput',
  AUTO_GENERATE = 'autoGenerate',
}

import { Button, FieldReadOnly, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PostFukumaruService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError } from '@/types/common';
import { ERROR } from '@/utils/constants/messages';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import { Form, Image } from 'antd';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const PostFukumaruDetail: React.FC<IProps> = ({ title }) => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const { data: postDetail, isFetching } = useQuery(
    [ENDPOINT.POST_FUKUMARU.GET_DETAIL.replace(':id', postId!)],
    () => PostFukumaruService.getDetail(Number(postId)),
    {
      onError: (response: IResponseError) => {
        if (response.error === ERROR.POST_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data = postDetail?.data;

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[700px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          {!isFetching && (
            <>
              <header className='flex justify-between w-full md:w-[700px]'>
                <h3 className='font-semibold text-[20px]'>
                  Fukumaru's Post Detail
                </h3>
                <div>
                  <Button
                    type='primary'
                    icon={<EditFilled />}
                    onClick={() =>
                      navigate(
                        APP_ROUTER.POST_FUKUMARU.UPDATE.replace(':id', postId!),
                      )
                    }
                  >
                    Edit
                  </Button>
                </div>
              </header>
              <Form
                labelCol={{ span: 6 }}
                labelAlign='left'
                className='flex flex-col justify-center w-full border-block p-3 mt-3'
              >
                <FieldReadOnly label='Title'>
                  <div className='break-all'>
                    <div className='leading-6'>{data?.title}</div>
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='Cover'>
                  {data?.imageCover?.url && (
                    <Image width={112} src={data?.imageCover?.url} />
                  )}
                </FieldReadOnly>
                <FieldReadOnly label='Content'>
                  <div className='leading-6'>{data?.content}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Family'>
                  <div className='leading-6'>{data?.familyId.name}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Created At'>
                  {data?.createdAt &&
                    moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </FieldReadOnly>
              </Form>
              <div className='w-full flex justify-center mt-3'>
                <Button
                  className='w-1/2'
                  icon={<LeftCircleOutlined />}
                  onClick={() => navigate(APP_ROUTER.POST_FUKUMARU.LIST)}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </Spin>
      </div>
    </div>
  );
};

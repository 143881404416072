import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  convertRequest,
  EventFeeType,
  eventStatus,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
} from '@/utils';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { EventForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/EventForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/EventForm/constants';
import { EventService } from '@/services/event';
import { ICreateEventRequest, IUpdateEventRequest } from '@/types/request';
import { ImageService } from '@/services/image';
import { IUploadImageRequest } from '@/types/request/upload';
import { IGetDetailEventResponse } from '@/types/response';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
}

export const EventList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailEventResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
  });

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.EVENT.GET_LIST, params],
    () => EventService.getList(convertRequest(params)),
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError, clearErrors } =
    methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleCreate = async (values: ICreateEventRequest) => {
    const { attachments, ...rest } = values;
    const payload: ICreateEventRequest = {
      ...rest,
      fee: values?.feeType === EventFeeType.FREE ? 1 : Number(values?.fee),
      imageBanner: null,
      attachments: null,
      status: values?.status ? 1 : 0,
      zipCode: !values?.postCodeType ? null : values.zipCode,
    };
    try {
      const response = await ImageService.getPresignedUrl(1);
      await upload({
        url: response?.data?.urls[0]?.signedUrl,
        file: values?.attachments?.fileList[0]?.originFileObj,
      });
      payload.imageBanner = response?.data?.urls[0]?.returnUrl;
      await createEvent(payload);
    } catch (error) {
      console.log({ error });
    }
  };

  const { mutate: createEvent, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateEventRequest) => EventService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.CREATE_EVENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        if (response.message === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
        } else {
          handleErrorCommon(response, setError);
        }
      },
    },
  );

  const { mutate: upload, isLoading: isLoadingUpload } = useMutation(
    (body: IUploadImageRequest) => ImageService.upload(body),
    {
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  const { mutate: checkZipCode, isLoading: isLoadingCheckZipCode } =
    useMutation((zipCode: string) => EventService.checkZipCode(zipCode), {
      onSuccess: (response: IResponse<any>) => {
        const { data } = response;
        clearErrors('zipCode');
        setValue('preZipCode', data?.zipCode);
        setValue('address1', data?.address1);
        setValue('address2', data?.address2);
        setValue('keepCheckZipCodeError', false);
      },
      onError: (error: IResponseError) => {
        if (error.error === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
          setValue('keepCheckZipCodeError', true);
          setValue('address1', '');
          setValue('address2', '');
        } else {
          handleErrorCommon(error, setError);
        }
      },
    });

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.EVENT.GET_DETAIL.replace(':id', String(currentId))],
    () => EventService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailEventResponse>) => {
        const data = response?.data;
        setDetail(data);
        const payload: ICreateEventRequest = {
          name: data?.name,
          eventFrom: moment(data?.eventFrom),
          eventTo: moment(data?.eventTo),
          address: data?.address,
          feeType: data?.feeType,
          fee: data?.fee,
          url: data?.url || '',
          content: data?.content,
          imageBanner: data?.imageBanner?.url,
          publicAt: moment(data?.publicAt),
          status: data?.status,
          attachments: null,
          pushNotificationAt: data?.pushNotificationAt,
          postCodeType: data?.japanPost ? 1 : 0,
          zipCode: data?.japanPost?.zipCode || '',
          preZipCode: data?.japanPost?.zipCode || '',
          address1: data?.japanPost?.address1 || '',
          address2: data?.japanPost?.address2 || '',
          isDisabled:
            data?.pushNotificationAt && moment().isAfter(moment(data?.eventTo)),
        };
        reset({
          formName: FORM_NAME.UPDATE_EVENT,
          ...payload,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = async (values: IUpdateEventRequest) => {
    const { attachments, ...rest } = values;
    const payload: IUpdateEventRequest = {
      ...rest,
      fee: values?.feeType === EventFeeType.FREE ? 1 : Number(values?.fee),
      imageBanner: null,
      status: values?.status ? 1 : 0,
      zipCode: !values.postCodeType ? null : values.zipCode,
    };
    try {
      delete payload.attachments;
      delete payload.formName;
      delete payload.isDisabled;
      delete payload.pushNotificationAt;
      delete payload.postCodeType;
      delete payload.address1;
      delete payload.address2;
      delete payload.preZipCode;
      delete payload.keepCheckZipCodeError;
      if (!values?.attachments?.fileList?.length) {
        await updateEvent({ body: payload, id: Number(currentId) });
        return;
      }
      const response = await ImageService.getPresignedUrl(1);
      await upload({
        url: response?.data?.urls[0]?.signedUrl,
        file: values?.attachments?.fileList[0]?.originFileObj,
      });
      payload.imageBanner = response?.data?.urls[0]?.returnUrl;
      await updateEvent({ body: payload, id: Number(currentId) });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDelete = (data: IGetDetailEventResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='イベント名' content={data?.name} />,
      maskClosable: false,
      onOk: () => deleteEvent(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { mutate: updateEvent, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateEventRequest; id: number }) =>
      EventService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.UPDATE_EVENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        if (error.error === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
        } else {
          handleErrorCommon(error, setError);
        }

        if (error.error === ERROR.EVENT_NOT_FOUND) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteEvent, isLoading: isLoadingDelete } = useMutation(
    (id: number) => EventService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_EVENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.EVENT_RELOAD_DATA) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const columns: TableColumnsType = [
    {
      title: 'イベント名',
      dataIndex: 'name',
      width: '500px',
    },
    {
      title: 'イベント開催日時',
      render: (value, record) => {
        return `${moment(record.eventFrom).format('YYYY年MM月DD日 HH:mm')}～${moment(record.eventTo).format('YYYY年MM月DD日 HH:mm')}`;
      },
    },
    {
      title: 'ステータス',
      render: (value, record) => {
        if (!record.status) {
          return eventStatus[1].label;
        }

        return !record?.pushNotificationAt ? '配信予約済み' : '配信中';
      },
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='text-right'>
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            イベント新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col'>
          <Table
            loading={isFetching || isLoadingUpload}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'イベントはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? 'イベント新規作成' : 'イベント詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          isLoadingUpdate ||
          isLoadingDelete ||
          isFetchingDetail ||
          isLoadingCreate ||
          isLoadingUpload
        }
        isDeleted={!!currentId}
        // disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <EventForm
            isLoadingCheckZipCode={isLoadingCheckZipCode}
            isLoading={
              isFetchingDetail ||
              isLoadingUpdate ||
              isLoadingCreate ||
              isLoadingDelete
            }
            openDrawer={openDrawer}
            checkZipCode={checkZipCode}
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

import {
  Button,
  DeleteContent,
  Drawer,
  Input,
  Pagination,
  Table,
} from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { SpecialDayService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IGetDetailSpecialDayResponse } from '@/types/response';
import {
  NOTIFY_STATUS,
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
} from '@/utils';
import {
  SearchOutlined,
  EyeFilled,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formSchemaFn } from '../SpecialDayForm/formSchemaFn';
import { FORM } from '../SpecialDayForm/constants';
import {
  ICreateSpecialDayRequest,
  IUpdateSpecialDayRequest,
} from '@/types/request';
import { IResponse, IResponseError } from '@/types/common';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { FORM_NAME } from '../Drawers/SpecialDayForm/constants';
import { SpecialDayForm } from '../Drawers';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
  date: string;
}

export const SpecialDayList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailSpecialDayResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
    date: '',
  });

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(
      formSchemaFn(
        currentId ? FORM.UPDATE_SPECIAL_DAY : FORM.CREATE_SPECIAL_DAY,
      ),
    ),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const navigate = useNavigate();
  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.SPECIAL_DAY.GET_LIST, params],
    () => SpecialDayService.getList(convertRequest(params)),
  );

  const { mutate: deleteDay, isLoading: isLoadingDelete } = useMutation(
    (id: number) => SpecialDayService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_SPECIALDAY as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.DAY_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: '日にち',
      dataIndex: 'date',
      width: '60px',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '140px',
    },
    {
      title: '祝日',
      dataIndex: 'name',
      width: '140px',
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      align: 'center',
      width: '110px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    // {
    //   title: 'Actions',
    //   align: 'center',
    //   width: '160px',
    //   fixed: 'right',
    //   render: (record) => (
    //     <div className='flex flex-col gap-2 lg:flex-row justify-center items-center'>
    //       <Button
    //         type='primary'
    //         icon={<EyeFilled />}
    //         onClick={() =>
    //           navigate(
    //             APP_ROUTER.SPECIAL_DAY.DETAIL.replace(':id', String(record.id)),
    //           )
    //         }
    //       >
    //         Detail
    //       </Button>
    //       <Button
    //         type='primary'
    //         icon={<DeleteFilled />}
    //         danger
    //         onClick={() =>
    //           notificationModal({
    //             type: NOTIFY_STATUS.CONFIRM,
    //             title: 'Notification',
    //             content: 'Do you really want to delete this item?',
    //             maskClosable: false,
    //             onOk: () => deleteDay(record.id),
    //           })
    //         }
    //       >
    //         Delete
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        date: search,
      }));
    }, 500);
  }, [search]);

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateSpecialDayRequest; id: number }) =>
      SpecialDayService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
        setIsNotFoundDetail(true);
      },
    },
  );

  const { mutate: create, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateSpecialDayRequest) => SpecialDayService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.DAY_EXIST) {
          setError('date', {
            type: 'custom',
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          });
        } else {
          notificationMessage({
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
            type: NOTIFY_STATUS.ERROR,
          });
        }
      },
    },
  );

  const handleUpdate = (values: IUpdateSpecialDayRequest) => {
    const payload = {
      id: currentId!,
      body: {
        title: values?.title ?? '',
        description: values?.description,
        name: values?.name ?? '',
      },
    };

    update(payload);
  };

  const handleCreate = (values: ICreateSpecialDayRequest) => {
    const payload = {
      date: values?.date,
      title: values?.title ?? '',
      description: values?.description,
      name: values?.name ?? '',
    };

    create(payload);
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleDelete = (data: IGetDetailSpecialDayResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: (
        <DeleteContent
          name='タイトル'
          content='Do you really want to delete this item?'
        />
      ),
      maskClosable: false,
      onOk: () => deleteDay(data?.id),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.NOTIFICATION.GET_DETAIL.replace(':id', String(currentId))],
    () => SpecialDayService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailSpecialDayResponse>) => {
        const data = response?.data;
        setDetail(data);
        reset({
          formName: FORM_NAME.UPDATE_SPECIALDAY,
          date: data?.date,
          title: data?.title ?? '',
          description: data?.description,
          name: data?.name ?? '',
          createdAt: data?.createdAt
            ? moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : '',
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <header className='flex justify-between w-full lg:w-[1280px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='タイトルから検索'
            onChange={(e) => {
              setParams({ ...params, page: 1, date: e.target.value });
            }}
          />
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            登録
          </Button>
        </header>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              setOpenDrawer(true);
              setCurrentId(record.id);
              setIsNotFoundDetail(false);
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title={!currentId ? '登録' : '詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          currentId
            ? isLoadingUpdate || isLoadingDelete || isFetchingDetail
            : isLoadingCreate
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <SpecialDayForm
            isLoading={
              isFetchingDetail ||
              isLoadingCreate ||
              isLoadingUpdate ||
              isLoadingDelete
            }
            specialId={currentId}
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

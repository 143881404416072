import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface IInputWrapProps extends RadioGroupProps {
  field?: ControllerRenderProps<any, any>;
  options?: { label: string; value: string | number }[];
}

export const RadioGroup: React.FC<IInputWrapProps> = ({
  className,
  options = [],
  field,
  ...props
}) => {
  return (
    <Radio.Group
      className={className}
      {...props}
    >
      {options.map((option) => (
        <Radio key={option.value} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

import { IGetListCommonRequest } from '@/types/common';
import { Moment } from 'moment';

export enum NotificationRemindType {
  TEETH = 'teeth',
}

export interface IGetListNotificationRemindRequest extends IGetListCommonRequest {
  title?: string;
}

export interface ICreateNotificationRemindRequest {
  title: string;
  message: string;
  status: number;
  pushNotificationDate: Moment;
  type?: NotificationRemindType;
}

export interface IUpdateNotificationRemindRequest {
  title: string;
  message: string;
  status: number;
  pushNotificationDate: Moment;
  type?: NotificationRemindType;
}

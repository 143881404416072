import { Button, FieldReadOnly, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { SpecialDayService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError } from '@/types/common';
import { ERROR } from '@/utils/constants/messages';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const SpecialDayDetail: React.FC<IProps> = ({ title }) => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const { data: dayDetail, isFetching } = useQuery(
    [ENDPOINT.SPECIAL_DAY.GET_DETAIL.replace(':id', postId!)],
    () => SpecialDayService.getDetail(Number(postId)),
    {
      onError: (response: IResponseError) => {
        if (response.error === ERROR.DAY_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data = dayDetail?.data;

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[560px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          {!isFetching && (
            <>
              <header className='flex justify-between w-full md:w-[560px]'>
                <h3 className='font-semibold text-[20px]'>
                  Special Day Detail
                </h3>
                <div>
                  <Button
                    type='primary'
                    icon={<EditFilled />}
                    onClick={() =>
                      navigate(
                        APP_ROUTER.SPECIAL_DAY.UPDATE.replace(':id', postId!),
                      )
                    }
                  >
                    Edit
                  </Button>
                </div>
              </header>
              <Form
                labelCol={{ span: 8 }}
                labelAlign='left'
                className='flex flex-col justify-center w-full border-block p-3 mt-3'
              >
                <FieldReadOnly label='Date'>
                  <div className='break-all'>{data?.date}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Title'>
                  <div className='break-all leading-6'>{data?.title}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Description'>
                  <div className='break-all leading-6'>{data?.description}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Created At'>
                  {data?.createdAt &&
                    moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </FieldReadOnly>
              </Form>
              <div className='w-full flex justify-center mt-3'>
                <Button
                  className='w-1/2'
                  icon={<LeftCircleOutlined />}
                  onClick={() => navigate(APP_ROUTER.SPECIAL_DAY.LIST)}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </Spin>
      </div>
    </div>
  );
};

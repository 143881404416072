import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
} from '@/utils';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TableColumnsType, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SurveyHealthForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/SurveyHealthQuestionForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/SurveyHealthQuestionForm/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IGetDetailSurveyHealthQuestionResponse } from '@/types/response';
import { SurveyHealthQuestionService } from '@/services/surveyHealthQuestion';
import { SurveyHealthService } from '@/services/surveyHealth';
import { APP_ROUTER } from '@/routes/routes';
import {
  ICreateSurveyHealthQuestionRequest,
  IUpdateSurveyHealthQuestionRequest,
} from '@/types/request';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
  type?: string;
  sortBy?: string;
  sortDirection?: 'ASC' | 'DESC';
  surveyHealthId?: string;
}

export const SurveyHealthQuestionList: React.FC<IProps> = ({ title }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] =
    useState<IGetDetailSurveyHealthQuestionResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
    sortBy: 'sortOrder',
    sortDirection: 'ASC',
    surveyHealthId: id,
  });

  const {
    data: dataSurveyHealth,
    isFetching: isFetchingDetailSurveyHealth,
    refetch: refetchDetailSurveyHealth,
  } = useQuery(
    [ENDPOINT.SURVEY_HEALTH.GET_DETAIL.replace(':id', String(id))],
    () => SurveyHealthService.getDetail(Number(id)!),
    {
      enabled: !!Number(id),
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
        navigate(APP_ROUTER.SURVEY_HEALTH.LIST);
      },
    },
  );

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.SURVEY_HEALTH_QUESTION.GET_LIST, params],
    () => SurveyHealthQuestionService.getList(convertRequest(params)),
    {
      enabled: !isFetchingDetailSurveyHealth && !!Number(id),
    },
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId!, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleCreate = (values: ICreateSurveyHealthQuestionRequest) => {
    const payload = {
      question: values.question,
      sortOrder: values?.sortOrder,
      surveyHealthId: id,
      surveyHealthAnswers: values?.surveyHealthAnswers,
    };

    createSurveyHealthQuestion(payload);
  };

  const {
    mutate: createSurveyHealthQuestion,
    isLoading: isLoadingCreateSurveyHealthQuestion,
  } = useMutation(
    (body: ICreateSurveyHealthQuestionRequest) =>
      SurveyHealthQuestionService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.CREATE_SURVEY_HEALTH_QUESTION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        refetchDetailSurveyHealth();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  const { isFetching: isFetchingDetail } = useQuery(
    [
      ENDPOINT.SURVEY_HEALTH_QUESTION.GET_DETAIL.replace(
        ':id',
        String(currentId),
      ),
    ],
    () => SurveyHealthQuestionService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (
        response: IResponse<IGetDetailSurveyHealthQuestionResponse>,
      ) => {
        const data = response?.data;
        setDetail(data);

        reset({
          formName: FORM_NAME.UPDATE_SURVEY_HEALTH_QUESTION,
          question: data?.question,
          sortOrder: data?.sortOrder,
          surveyHealthAnswers: data?.surveyHealthAnswers,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = (values: IUpdateSurveyHealthQuestionRequest) => {
    const payload = {
      id: currentId!,
      body: {
        question: values?.question,
        sortOrder: values?.sortOrder,
        surveyHealthId: id,
        surveyHealthAnswers: values?.surveyHealthAnswers,
      },
    };

    updateSurveyHealthQuestion(payload);
  };

  const handleDelete = (data: IGetDetailSurveyHealthQuestionResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='質問' content={data?.question} />,
      maskClosable: false,
      onOk: () => deleteSurveyHealth(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const {
    mutate: updateSurveyHealthQuestion,
    isLoading: isLoadingUpdateSurveyHealthQuestion,
  } = useMutation(
    (payload: { body: IUpdateSurveyHealthQuestionRequest; id: number }) =>
      SurveyHealthQuestionService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.UPDATE_SURVEY_HEALTH_QUESTION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.SURVEY_HEALTH_QUESTION_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
    refetchDetailSurveyHealth();
  };

  const { mutate: deleteSurveyHealth, isLoading: isLoadingDeleteSurveyHealth } =
    useMutation((id: number) => SurveyHealthQuestionService.delete(id), {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_SURVEY_HEALTH_QUESTION as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.SURVEY_HEALTH_QUESTION_RELOAD_DATA) {
          setOpenDrawer(false);
        }
      },
    });

  const columns: TableColumnsType = [
    {
      title: '質問',
      dataIndex: 'question',
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => <span style={{ whiteSpace: "pre-line" }}>{text}</span>
    },
    {
      title: '表示順',
      dataIndex: 'sortOrder',
      width: 120,
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
      refetchDetailSurveyHealth();
    }
  }, [isDisabled, isNotFoundDetail]);

  useEffect(() => {
    if (!Number(id)) {
      notificationMessage({
        message: ERROR_MESSAGE.SURVEY_HEALTH_NOT_FOUND,
        type: NOTIFY_STATUS.ERROR,
      });
      setIsNotFoundDetail(true);
      navigate(APP_ROUTER.SURVEY_HEALTH.LIST);
    }
  }, []);

  return (
    <div className='p-post-fukumaru-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='flex justify-between gap-5'>
          <Link
            to={APP_ROUTER.SURVEY_HEALTH.LIST}
            className='text-3xl flex items-center'
          >
            <span className='inline-block rotate-180 '>➜</span>
          </Link>
          <Tooltip title={dataSurveyHealth?.data?.name} placement='top'>
            <p className='text-2xl font-bold truncate max-w-[1000px]'>
              {dataSurveyHealth?.data?.name}
            </p>
          </Tooltip>
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({ surveyHealthAnswers: [{}] }),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
            disabled={!dataSurveyHealth}
          >
            質問新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col h-full'>
          <Table
            loading={isFetching || isFetchingDetailSurveyHealth}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'お知らせはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? '質問新規作成' : '質問詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          currentId
            ? isLoadingUpdateSurveyHealthQuestion ||
              isLoadingDeleteSurveyHealth ||
              isFetchingDetail
            : isLoadingCreateSurveyHealthQuestion
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <SurveyHealthForm isLoading={isFetchingDetail} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

import { IResponse, IResponsePagination } from '@/types/common';
import {
  ICreateSurveyRequest,
  IGetListSurveyRequest,
  IUpdateSurveyRequest,
} from '@/types/request';
import { IGetListSurveyResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Survey {
  getList = (
    params?: IGetListSurveyRequest,
  ): Promise<IResponsePagination<IGetListSurveyResponse>> => {
    return axiosClient.get(ENDPOINT.SURVEY.GET_LIST, { params: params });
  };

  create = (body: ICreateSurveyRequest): Promise<IResponse<any>> => {
    return axiosClient.post(ENDPOINT.SURVEY.CREATE, body);
  };

  getDetail = (id: number): Promise<IResponse<any>> => {
    return axiosClient.get(
      ENDPOINT.SURVEY.GET_DETAIL.replace(':id', String(id)),
    );
  };

  update = (data: IUpdateSurveyRequest, id: number): Promise<IResponse<any>> => {
    return axiosClient.put(
      ENDPOINT.SURVEY.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(ENDPOINT.SURVEY.DELETE.replace(':id', String(id)));
  };
}

export const SurveyService = new Survey();

import { useAuth } from '@/providers/Auth';
import { APP_ROUTER } from '@/routes/routes';
import {
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Drawer, Dropdown, Layout, Menu, MenuProps, Tag } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigator, menu } from '../Navigator';
import './style.scss';

interface IProps {}

type MenuItem = Required<MenuProps>['items'][number];

const Header: React.FC<IProps> = ({}) => {
  const [openDraw, setOpenDraw] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>('/');

  const navigate = useNavigate();
  const { currentUser, logOutSuccess } = useAuth();

  const headerStyle = {
    backgroundColor: 'var(--color-header)',
    borderBlockEnd: '1px solid rgba(5, 5, 5, 0.06)',
    height: '60px',
    backdropFilter: 'blur(8px)',
    transition: 'background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  };

  const handleOnClickMenu = async (e: { key: string }) => {
    navigate(e.key);
    setCurrent(e.key);
  };

  const items: MenuItem[] = [
    {
      key: '0',
      label: (
        <div>
          <Tag>管理者</Tag>
          <div className='mt-4px font-semibold'>田中 太朗 田中 太朗</div>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '1',
      icon: <LogoutOutlined />,
      label: 'ログアウト',
      onClick: logOutSuccess,
    },
  ];

  return (
    <Layout.Header style={headerStyle} className='c-header'>
      <div className='flex gap-[6px]'>
        <div className='px-[10px] h-full block lg:hidden'>
          <MenuOutlined
            className='text-white text-[18px]'
            onClick={() => {
              setOpenDraw((prev) => !prev);
            }}
          />
        </div>
        <div className='logo' onClick={() => navigate(APP_ROUTER.HOME)}>
          <div className='image'>
            <img src='/logo.svg' alt='' />
          </div>
          <span className='app-name no-select'>ヒビノエ</span>
        </div>
      </div>
      <Navigator />
      <Drawer
        placement='left'
        width={250}
        onClose={() => setOpenDraw(false)}
        open={openDraw}
        closable={false}
        closeIcon={<></>}
        classNames={{
          body: 'c-drawer-sidebar__body',
        }}
      >
        <Menu
          className='menu'
          onClick={handleOnClickMenu}
          mode='inline'
          items={menu as any}
          selectedKeys={[current]}
          rootClassName='c-menu'
        />
      </Drawer>
      <Dropdown
        className='mx-4 flex items-center cursor-pointer'
        menu={{ items }}
        placement='bottom'
        arrow={false}
        trigger={['click']}
      >
        <div className='flex items-center gap-[8px]'>
          <UserOutlined className='text-[20px] text-[#FFFFFFA3]' />
          <span className='text-white no-select'>ログイン名</span>
          <DownOutlined className='text-[14px] text-[#FFFFFFA3]' />
        </div>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;

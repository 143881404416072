import { Button, Field, Input, Spin, TextArea } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { SpecialDayService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  ICreateSpecialDayRequest,
  IUpdateSpecialDayRequest,
} from '@/types/request';
import { IGetDetailSpecialDayResponse } from '@/types/response';
import { NOTIFY_STATUS, notificationMessage } from '@/utils';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FORM } from './constants';
import { formSchemaFn } from './formSchemaFn';

interface IProps {
  title?: string;
}

export const SpecialDayForm: React.FC<IProps> = ({ title }) => {
  const { id: dayId } = useParams();
  const navigate = useNavigate();

  const [formName, setFormName] = useState<FORM>(FORM.CREATE_SPECIAL_DAY);

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(formName)),
  });

  const { handleSubmit, reset, setError } = methods;

  const { data: dayDetail, isFetching } = useQuery(
    [ENDPOINT.SPECIAL_DAY.GET_DETAIL.replace(':id', dayId!)],
    () => SpecialDayService.getDetail(Number(dayId)),
    {
      enabled: !!dayId,
      onSuccess: (response: IResponse<IGetDetailSpecialDayResponse>) => {
        const data = response.data;
        setFormName(FORM.UPDATE_SPECIAL_DAY);
        reset({
          date: data.date,
          title: data.title,
          description: data.description,
        });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.DAY_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const { mutate: create, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateSpecialDayRequest) => SpecialDayService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.SPECIAL_DAY.LIST);
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.DAY_EXIST) {
          setError('date', {
            type: 'custom',
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          });
        } else {
          notificationMessage({
            message:
              ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
            type: NOTIFY_STATUS.ERROR,
          });
        }
      },
    },
  );

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateSpecialDayRequest; id: number }) =>
      SpecialDayService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.SPECIAL_DAY.DETAIL.replace(':id', dayId!));
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
      },
    },
  );

  const handleCreate = (values: any) => {
    const payload = {
      date: values?.date,
      title: values?.title,
      description: values?.description,
    };

    create(payload);
  };

  const handleUpdate = (values: any) => {
    const payload = {
      id: Number(dayId),
      body: {
        title: values?.title,
        description: values?.description,
      },
    };

    update(payload);
  };

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[560px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          <FormProvider {...methods}>
            {!isFetching && (
              <>
                <header className='flex justify-between w-full md:w-[560px]'>
                  <h3 className='font-semibold text-[20px]'>
                    Special Day Detail
                  </h3>
                </header>
                <Form
                  id='day-form'
                  labelCol={{ span: 8 }}
                  labelAlign='left'
                  className='flex flex-col justify-center w-full border-block p-3 mt-3'
                  onFinish={handleSubmit(dayId ? handleUpdate : handleCreate)}
                >
                  <Field label='Date' name='date'>
                    <Input
                      placeholder='Enter date'
                      disabled={!!dayId}
                      maxLength={5}
                    />
                  </Field>
                  <Field label='Title' name='title'>
                    <Input placeholder='Enter title' />
                  </Field>
                  <Field label='Description' name='description'>
                    <TextArea
                      placeholder='Enter description'
                      maxLength={500}
                      rows={6}
                    />
                  </Field>
                </Form>
                <div className='flex w-full gap-2 mt-3'>
                  <Button
                    className='w-1/2'
                    icon={<LeftCircleOutlined />}
                    onClick={() =>
                      navigate(
                        dayId
                          ? APP_ROUTER.SPECIAL_DAY.DETAIL.replace(':id', dayId!)
                          : APP_ROUTER.SPECIAL_DAY.LIST,
                      )
                    }
                  >
                    Back
                  </Button>
                  <Button
                    type='primary'
                    className='w-1/2'
                    htmlType='submit'
                    form='day-form'
                    icon={<CheckCircleOutlined />}
                    loading={dayId ? isLoadingUpdate : isLoadingCreate}
                  >
                    {dayId ? ' Update' : 'Create'}
                  </Button>
                </div>
              </>
            )}
          </FormProvider>
        </Spin>
      </div>
    </div>
  );
};

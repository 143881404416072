import { IResponse, IResponsePagination } from '@/types/common';
import {
  ICreateEventRequest,
  IGetListEventRequest,
  IUpdateEventRequest,
} from '@/types/request';
import { IGetListEventResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Event {
  getList = (
    params: IGetListEventRequest,
  ): Promise<IResponsePagination<IGetListEventResponse>> => {
    return axiosClient.get(ENDPOINT.EVENT.GET_LIST, { params: params });
  };

  delete = (id: number) => {
    return axiosClient.delete(ENDPOINT.EVENT.DELETE.replace(':id', String(id)));
  };

  create = (body: ICreateEventRequest): Promise<IResponse<any>> => {
    return axiosClient.post(ENDPOINT.EVENT.CREATE, body);
  };

  getDetail = (id: number): Promise<IResponse<any>> => {
    return axiosClient.get(
      ENDPOINT.EVENT.GET_DETAIL.replace(':id', String(id)),
    );
  };

  update = (data: IUpdateEventRequest, id: number): Promise<IResponse<any>> => {
    return axiosClient.put(
      ENDPOINT.EVENT.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  checkZipCode = (zipCode: string): Promise<IResponse<any>> => {
    return axiosClient.get(
      ENDPOINT.ZIPCODE.GET_DETAIL.replace(':id', String(zipCode)),
    );
  };
}

export const EventService = new Event();

import { DatePicker as DatePickerBase } from 'antd';
import { DatePickerProps } from 'antd/lib';
import { ControllerRenderProps } from 'react-hook-form';

interface IProps extends Omit<DatePickerProps, 'value'> {
  field?: ControllerRenderProps<any, any>;
}

export const DatePicker: React.FC<IProps> = ({
  className,
  field,
  format='YYYY年MM月DD日 HH:mm',
  ...props
}) => {
  return <DatePickerBase format={format} className={className} {...field} {...props} />;
};

import { IResponse, IResponsePagination } from '@/types/common';
import { ICreateNotificationRequest, IGetListNotificationRequest, IUpdateNotificationRequest } from '@/types/request';
import { ICreateNotificationResponse, IGetDetailNotificationResponse, IGetListNotificationResponse, IUpdateNotificationResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Notification {
  getList = (
    params: IGetListNotificationRequest,
  ): Promise<IResponsePagination<IGetListNotificationResponse>> => {
    return axiosClient.get(ENDPOINT.NOTIFICATION.GET_LIST, { params: params });
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.NOTIFICATION.DELETE.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateNotificationRequest,
  ): Promise<IResponse<ICreateNotificationResponse>> => {
    return axiosClient.post(ENDPOINT.NOTIFICATION.CREATE, body);
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailNotificationResponse>> => {
    return axiosClient.get(
      ENDPOINT.NOTIFICATION.GET_DETAIL.replace(':id', String(id)),
    );
  };

  update = (
    data: IUpdateNotificationRequest,
    id: number,
  ): Promise<IResponse<IUpdateNotificationResponse>> => {
    return axiosClient.put(
      ENDPOINT.NOTIFICATION.UPDATE.replace(':id', String(id)),
      data,
    );
  };
}

export const NotificationService = new Notification();

import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    platform: yup.string().required('プラットフォームは未入力です。'),
    version: yup
      .string()
      .required('バージョンは未入力です。')
      .matches(/^\d+\.\d+\.\d+$/, '「xx.xx.xx」形式を使用してください。')
      .max(8, 'バージョン8文字以内で入力してください。'),
    mustUpdate: yup.string().required('実行は未入力です。'),
    releaseDate: yup.date().required('リリース日付は未入力です。'),
    description: yup
      .string()
      .required('コンテンツは未入力です。')
      .max(500, 'コンテンツ500文字以内で入力してください。'),
  } as any);

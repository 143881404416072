import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

interface IProps extends TextAreaProps {
  field?: ControllerRenderProps<any, any>;
}

export const TextArea: React.FC<IProps> = ({ className, field, ...props }) => {
  const context = useFormContext();
  return (
    <Input.TextArea
      className={className}
      {...field}
      {...props}
      onBlur={(event: React.FocusEvent<HTMLTextAreaElement, Element>) =>
        context?.setValue(props?.name as string, event?.target?.value?.trim())
      }
    />
  );
};

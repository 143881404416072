import { IResponse, IResponsePagination } from '@/types/common';
import {
} from '@/types/request';
import {
} from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';
import { ICreateAppVersionRequest, IGetListAppVersionRequest, IUpdateAppVersionRequest } from '@/types/request/appVersion';
import { ICreateAppVersionResponse, IGetDetailAppVersionResponse, IGetListAppVersionResponse, IUpdateAppVersionResponse } from '@/types/response/appVersion';

class AppVersion {
  getList = (
    params: IGetListAppVersionRequest,
  ): Promise<IResponsePagination<IGetListAppVersionResponse>> => {
    return axiosClient.get(ENDPOINT.APP_VERSION.GET_LIST, { params: params });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailAppVersionResponse>> => {
    return axiosClient.get(
      ENDPOINT.APP_VERSION.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateAppVersionRequest,
  ): Promise<IResponse<ICreateAppVersionResponse>> => {
    return axiosClient.post(ENDPOINT.APP_VERSION.CREATE, body);
  };

  update = (
    data: IUpdateAppVersionRequest,
    id: number,
  ): Promise<IResponse<IUpdateAppVersionResponse>> => {
    return axiosClient.put(
      ENDPOINT.APP_VERSION.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.APP_VERSION.DELETE.replace(':id', String(id)),
    );
  };
}

export const AppVersionService = new AppVersion();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-home .ant-table-content {
  border-radius: 8px;
  border: 1px solid var(--color-border);
}`, "",{"version":3,"sources":["webpack://./src/pages/Post/PostReportList/style.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,qCAAA;AAAJ","sourcesContent":[".p-home {\n  .ant-table-content {\n    border-radius: 8px;\n    border: 1px solid var(--color-border);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

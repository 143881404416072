import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutComponent } from '@/components';
import { NotFound } from './pages';
import { useAuth } from './providers/Auth';
import {
  PrivateRouteWrap,
  PublicRouteWrap,
  privateRoutes,
  publicRoutes,
} from './routes';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRouteWrap isAuthenticated={isAuthenticated} />}>
          <Route>
            {publicRoutes?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>
        </Route>
        <Route element={<PrivateRouteWrap isAuthenticated={isAuthenticated} />}>
          <Route element={<LayoutComponent />}>
            <Route>
              {privateRoutes?.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { Field, Input, Spin } from '@/components/commons';
import {
  convertObjectToArray,
  genderOptions,
  onPreview,
  verifyStatus,
} from '@/utils';
import { Badge, Button, DatePicker, Form, Image, Select, Upload } from 'antd';
import { userStatus } from '@/utils';
import { useFormContext } from 'react-hook-form';
import { Moment } from 'moment';
import {
  FALLBACK_URL,
  FILE_SIZE_LIMIT,
  SUPPORTED_EXTENSION,
  SUPPORTED_FORMATS,
} from '@/services/image';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
  isLoading: boolean;
  openDrawer: boolean;
  isLoadingCheckZipCode?: boolean;
  checkZipCode?: any;
}

export const UserForm: React.FC<IProps> = ({
  isLoading,
  openDrawer,
  isLoadingCheckZipCode,
  checkZipCode,
}) => {
  const { watch, setValue, setError, clearErrors } = useFormContext();

  const isDisabled = watch('isDisabled');
  const avatar = watch('avatar');
  const attachments = watch('attachments');
  const zipCode = watch('zipCode');

  const handleUpload = (info: any) => {
    const file = info.file.originFileObj || info.file;
    const reader = new FileReader();
    reader.onload = () => {
      setValue('avatar', reader.result as string);
      setValue('attachments', info);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setValue('avatar', null);
    setValue('attachments', null);
  };

  const beforeUpload = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (file.size > FILE_SIZE_LIMIT) {
      setError('attachment', {
        type: 'validate',
        message: '画像のサイズは10MBを超えています。',
      });
      return Upload.LIST_IGNORE;
    }

    if (
      !SUPPORTED_EXTENSION.includes(fileExtension || '') ||
      !SUPPORTED_FORMATS.includes(file.type)
    ) {
      setError('attachment', {
        type: 'validate',
        message: '画像は .jpg、.png、.jpeg 形式ではありません。',
      });
      return Upload.LIST_IGNORE;
    }
    clearErrors('attachment');

    return true;
  };

  const handleKeyPressInputPostCode = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const char = String.fromCharCode(event.which);
    const inputValue = (event.target as HTMLInputElement).value;

    if (!/[0-9]/.test(char) || inputValue.length >= 7) {
      event.preventDefault();
    }
  };

  const handlePasteInputPostCode = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const clipboardData = event.clipboardData.getData('text');
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    const selectionStart = inputElement.selectionStart ?? 0;
    const selectionEnd = inputElement.selectionEnd ?? 0;

    const newLength =
      inputValue.length -
      (selectionEnd - selectionStart) +
      clipboardData.length;

    if (!/^[0-9]*$/.test(clipboardData) || newLength > 7) {
      event.preventDefault();
    }
  };

  const handleCheckZipCode = (zipCode: string) => {
    checkZipCode(zipCode);
  };

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        {!attachments?.fileList?.length && !avatar && (
          <Field label='アバター' name='attachments' className='h-[80px]'>
            <div className='border-solid rounded border-2 border-zinc-300  w-[200px] h-[100px] flex justify-center items-center'>
              <Upload
                customRequest={() => {}}
                disabled={isDisabled}
                listType='text'
                maxCount={1}
                beforeUpload={beforeUpload}
                showUploadList={false}
                onChange={handleUpload}
              >
                <Button
                  disabled={isDisabled}
                  type='default'
                  shape='default'
                  size='middle'
                  className='text-[#84ab42] border border-[#84ab42] font-semibold'
                >
                  画像を選択
                </Button>
              </Upload>
            </div>
          </Field>
        )}
        {avatar && openDrawer && !isLoading && (
          <Field label='アバター' name='avatar' className='h-[80px]'>
            <div className='relative inline-block'>
              <Image
                height={100}
                src={avatar}
                className='block rounded-lg'
                fallback={FALLBACK_URL}
              />
              {!isDisabled && (
                <Button
                  type='primary'
                  shape='circle'
                  icon={<CloseOutlined />}
                  size='small'
                  onClick={handleRemoveImage}
                  className='absolute -top-2 -right-2 z-10 bg-red-500 border-none'
                />
              )}
            </div>
          </Field>
        )}
        <Field name='attachment'>
          <div></div>
        </Field>
        <Field name='email' label='メールアドレス' className='w-full'>
          <Input disabled={true} />
        </Field>
        <Field name='isVerified' label='ステータス確認' className='w-full'>
          <Select
            disabled={true}
            options={convertObjectToArray(verifyStatus, 'string')}
          />
        </Field>
        <Field name='status' label='ステータス' className='w-full'>
          <Select
            options={convertObjectToArray(userStatus, 'string')}
            disabled={isDisabled}
          />
        </Field>
        <Field name='gender' label='性別' className='w-full'>
          <Select disabled={isDisabled} options={genderOptions} />
        </Field>
        <Field name='nickname' label='ニックネーム' className='w-full' required>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='dayOfBirth' label='生年月日' className='w-full' required>
          <DatePicker
            onCalendarChange={(e) => {
              setValue('dayOfBirth', e ? (e as Moment).second(0) : undefined);
            }}
          />
        </Field>
        <label className='title-calendar font-semibold '>
          郵便番号
          <Badge status='error' className='ml-2 mb-1' />
        </label>
        <>
          <div className='flex items-baseline'>
            <Field name='zipCode' className='mb-0'>
              <Input
                className='w-[200px] mr-1 bg-transparent hover:bg-transparent'
                disabled={isDisabled}
                onKeyPress={handleKeyPressInputPostCode}
                onPaste={handlePasteInputPostCode}
              />
            </Field>
            <Button
              className='absolute left-[210px] pt-1'
              disabled={isDisabled}
              type='primary'
              size='middle'
              onClick={() => handleCheckZipCode(zipCode)}
              loading={isLoadingCheckZipCode}
            >
              住所検索
            </Button>
          </div>
          <Field name='note' className='mb-1'>
            <span>※ハイフンなし半角で入力してください</span>
          </Field>
          <Field name='address1' className='mb-1'>
            <Input
              placeholder='-'
              maxLength={200}
              disabled={true}
              style={!isDisabled ? { color: 'black' } : {}}
            />
          </Field>
          <Field name='address2'>
            <Input
              placeholder='-'
              maxLength={200}
              disabled={true}
              style={!isDisabled ? { color: 'black' } : {}}
            />
          </Field>
          <Field name='createdAt' label='登録日' className='w-full'>
            <Input disabled={true} />
          </Field>
          <Field name='deletedAt' label='退会日' className='w-full'>
            <Input disabled={true} />
          </Field>
        </>
      </Spin>
    </Form>
  );
};

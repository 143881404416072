import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface IProps {
  title?: string;
}

export const Unauthorized: React.FC<IProps> = ({ title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Result
        status='403'
        title='403'
        subTitle='Sorry, the page you visited can not allow.'
        extra={
          <Link to={'/'}>
            <Button type='primary'>Back Home</Button>
          </Link>
        }
      />
    </>
  );
};

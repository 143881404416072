import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
} from '@/utils';
import { ERROR, SUCCESS, SUCCESS_MESSAGE } from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, TableColumnsType } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SurveyHealthForm } from '../Drawers';
import { formSchemaFn } from '../Drawers/SurveyHealthForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/SurveyHealthForm/constants';
import { SurveyHealthService } from '@/services/surveyHealth';
import { Link } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import {
  generateSurveyHealthTypeText,
  IGetDetailSurveyHealthResponse,
  TypeSurveyHealth,
} from '@/types/response';
import {
  ICreateSurveyHealthRequest,
  IUpdateSurveyHealthRequest,
} from '@/types/request/surveyHealth';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
  type?: string;
  sortBy?: string;
  sortDirection?: 'ASC' | 'DESC';
}

export const surveyHealthTypeOptions = [
  {
    value: TypeSurveyHealth.EAT_10,
    label: generateSurveyHealthTypeText(TypeSurveyHealth.EAT_10),
  },
  {
    value: TypeSurveyHealth.DVS,
    label: generateSurveyHealthTypeText(TypeSurveyHealth.DVS),
  },
  {
    value: TypeSurveyHealth.OF5,
    label: generateSurveyHealthTypeText(TypeSurveyHealth.OF5),
  },
  {
    value: TypeSurveyHealth.ELDERLY,
    label: generateSurveyHealthTypeText(TypeSurveyHealth.ELDERLY),
  },
];

export const SurveyHealthList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailSurveyHealthResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
    sortBy: 'sortOrder',
    sortDirection: 'ASC',
  });

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.SURVEY_HEALTH.GET_LIST, params],
    () => SurveyHealthService.getList(convertRequest(params)),
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId!, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError } = methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({ message: null, description: null });
  };

  const handleCreate = (values: ICreateSurveyHealthRequest) => {
    const payload = {
      title: values.title,
      name: values.name,
      message: values.message,
      description: values.description,
      status: values?.status ? 1 : 0,
      type: values?.type,
      sortOrder: values?.sortOrder,
    };

    createSurveyHealth(payload);
  };

  const { mutate: createSurveyHealth, isLoading: isLoadingCreateSurveyHealth } =
    useMutation(
      (body: ICreateSurveyHealthRequest) => SurveyHealthService.create(body),
      {
        onSuccess: () => {
          notificationMessage({
            message:
              SUCCESS_MESSAGE[
                SUCCESS.CREATE_SURVEY_HEALTH as keyof typeof SUCCESS_MESSAGE
              ],
            type: NOTIFY_STATUS.SUCCESS,
          });
          refetch();
          handleCancel();
          setParams({ ...params, page: 1 });
        },
        onError: (response: IResponseError) => {
          handleErrorCommon(response, setError);
        },
      },
    );

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.SURVEY_HEALTH.GET_DETAIL.replace(':id', String(currentId))],
    () => SurveyHealthService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailSurveyHealthResponse>) => {
        const data = response?.data;
        setDetail(data);

        reset({
          formName: FORM_NAME.UPDATE_SURVEY_HEALTH,
          name: data?.name,
          title: data?.title,
          message: data?.message,
          description: data?.description,
          status: data?.status ? 1 : 0,
          sortOrder: data?.sortOrder,
          type: data?.type,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = (values: IUpdateSurveyHealthRequest) => {
    const payload = {
      id: currentId!,
      body: {
        title: values?.title,
        name: values?.name,
        message: values?.message,
        description: values?.description,
        status: values?.status ? 1 : 0,
        type: values?.type,
        sortOrder: values?.sortOrder,
      },
    };

    updateSurveyHealth(payload);
  };

  const handleDelete = (data: IGetDetailSurveyHealthResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='アンケート名' content={data?.name} />,
      maskClosable: false,
      onOk: () => deleteSurveyHealth(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { mutate: updateSurveyHealth, isLoading: isLoadingUpdateSurveyHealth } =
    useMutation(
      (payload: { body: IUpdateSurveyHealthRequest; id: number }) =>
        SurveyHealthService.update(payload.body, payload.id),
      {
        onSuccess: () => {
          notificationMessage({
            message:
              SUCCESS_MESSAGE[
                SUCCESS.UPDATE_SURVEY_HEALTH as keyof typeof SUCCESS_MESSAGE
              ],
            type: NOTIFY_STATUS.SUCCESS,
          });
          refetch();
          handleCancel();
          setParams({ ...params, page: 1 });
        },
        onError: (error: IResponseError) => {
          handleErrorCommon(error, setError);

          if (error.error === ERROR.SURVEY_HEALTH_NOT_FOUND) {
            setOpenDrawer(false);
          }
        },
      },
    );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteSurveyHealth, isLoading: isLoadingDeleteSurveyHealth } =
    useMutation((id: number) => SurveyHealthService.delete(id), {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_SURVEY_HEALTH as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.SURVEY_HEALTH_NOT_FOUND) {
          setOpenDrawer(false);
        }
      },
    });

  const columns: TableColumnsType = [
    {
      title: 'アンケート名',
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: '種類',
      width: '250px',
      dataIndex: 'type',
      render: (value) => {
        return generateSurveyHealthTypeText(value);
      },
    },
    {
      title: '表示順',
      width: '150px',
      dataIndex: 'sortOrder',
    },
    {
      title: 'ステータス',
      width: '120px',
      render: (value, record) => {
        return record.status ? '公開' : '下書き';
      },
    },
    {
      title: '質問票',
      width: '120px',
      render: (value, record) => {
        const listQuestionLink = APP_ROUTER.SURVEY_HEALTH.LIST_QUESTION.replace(
          ':id',
          record?.id,
        );
        return (
          <Link to={listQuestionLink} className='text-2xl'>
            ➜
          </Link>
        );
      },
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  const handleSelectFilter = (value: string) => {
    setParams({ ...params, type: value, page: 1 });
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='text-right'>
          <Select
            className='min-w-[170px] mr-3 text-left'
            defaultValue={null}
            onChange={handleSelectFilter}
            options={[
              { value: null, label: '全て' },
              ...surveyHealthTypeOptions,
            ]}
          />
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col h-full'>
          <Table
            loading={isFetching}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'お知らせはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? 'アンケート新規作成' : 'アンケート詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          currentId
            ? isLoadingUpdateSurveyHealth ||
              isLoadingDeleteSurveyHealth ||
              isFetchingDetail
            : isLoadingCreateSurveyHealth
        }
        isDeleted={!!currentId}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <SurveyHealthForm isLoading={isFetchingDetail} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

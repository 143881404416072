import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from './Header';

export const LayoutComponent: React.FC = () => {
  const layoutStyle = {
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: 'linear-gradient(#ffffff, #f5f5f5 28%) !important',
  };

  const contentStyle = {
    overflow: 'scroll',
    backgroundColor: 'linear-gradient(#ffffff, #f5f5f5 28%) !important',
  };

  return (
    <Layout style={layoutStyle}>
      <Header />
      <Layout>
        <Layout.Content
          style={contentStyle}
          className='px-[20px] lg:px-[40px] py-[20px] lg:py-[32px]'
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

import * as yup from 'yup';

const isTextEditorValid = (content: string) => {
  const strippedContent = content.replace(/<(.|\n)*?>/g, '').trim();
  return strippedContent.length > 0;
};

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    name: yup
      .string()
      .required('アンケート名は未入力です。')
      .max(100, 'アンケート名は100文字以内で入力してください。'),
    title: yup
      .string()
      .required('タイトルは未入力です。')
      .max(100, 'タイトルは100文字以内で入力してください。'),
    message: yup
      .string()
      .test('is-valid-message', 'アンケート終了内容は未入力です。', (value) =>
        isTextEditorValid(value || ''),
      )
      .required('アンケート終了内容は未入力です。')
      .max(10000, 'アンケート終了内容は10000文字以内で入力してください。'),
    description: yup
      .string()
      .test('is-valid-message', 'アンケートTOP内容は未入力です。', (value) =>
        isTextEditorValid(value || ''),
      )
      .required('アンケートTOP内容は未入力です。')
      .max(10000, 'アンケートTOP内容は10000文字以内で入力してください。'),
    status: yup
      .boolean()
      .required('ステータスは未入力です。')
      .oneOf([true, false], 'ステータスは未入力です。'),
    sortOrder: yup.number().required('表示順は未入力です。'),
    type: yup.string().required('タイプは未入力です。'),
  } as any);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-drawer .ant-drawer-header {
  border-bottom: 1px solid rgb(229, 231, 235);
}`, "",{"version":3,"sources":["webpack://./src/components/commons/Drawer/style.scss"],"names":[],"mappings":"AACE;EACE,2CAAA;AAAJ","sourcesContent":[".c-drawer {\r\n  .ant-drawer-header {\r\n    border-bottom: 1px solid rgb(229, 231, 235);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const ENDPOINT = {
  AUTH: {
    LOGIN: '/auth/sign-in',
    LOGOUT: '/auth/logout',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_RESET_PASSWORD: '/auth/check-reset-password',
    REFRESH_TOKEN: '/auth/refresh-token',
  },
  USER: {
    GET_ME: '/users/me',
    GET_LIST: '/users',
    GET_DETAIL: '/users/:id',
    UPDATE: '/users/:id',
  },
  POST: {
    GET_LIST: '/posts',
    GET_DETAIL: '/posts/:id',
    GET_FUKUMARU_DETAIL: '/posts/fukumaru/:id',
    UPDATE_FUKUMARU: '/posts/fukumaru/:id',
    DELETE_FUKUMARU: '/posts/fukumaru/:id',
  },
  POST_FUKUMARU: {
    GET_LIST: '/posts',
    GET_DETAIL: '/posts/fukumaru/:id',
    UPDATE: '/posts/fukumaru/:id',
    DELETE: '/posts/fukumaru/:id',
  },
  SPECIAL_DAY: {
    GET_LIST: '/special-days',
    GET_DETAIL: '/special-days/:id',
    CREATE: '/special-days/',
    UPDATE: '/special-days/:id',
    DELETE: '/special-days/:id',
  },
  NOTIFICATION: {
    GET_LIST: '/notifications',
    GET_DETAIL: '/notifications/:id',
    CREATE: '/notifications',
    UPDATE: '/notifications/:id',
    DELETE: '/notifications/:id',
  },
  PROMPT: {
    GET_LIST: '/prompts',
    GET_DETAIL: '/prompts/:id',
    CREATE: '/prompts/',
    UPDATE: '/prompts/:id',
    DELETE: '/prompts/:id',
  },
  PROMPT_INPUT_TYPE: {
    GET_LIST: '/prompt-input-types',
  },
  PROMPT_KEY_GROUP: {
    GET_LIST: '/prompt-key-groups',
    GET_DETAIL: '/prompt-key-groups/:id',
    CREATE: '/prompt-key-groups/',
    UPDATE: '/prompt-key-groups/:id',
    DELETE: '/prompt-key-groups/:id',
  },
  EVENT: {
    GET_LIST: '/events',
    GET_DETAIL: '/events/:id',
    CREATE: '/events',
    UPDATE: '/events/:id',
    DELETE: '/events/:id',
  },
  IMAGE: {
    GET_URL: '/uploads/presigned-url/:id',
  },
  COMMENT: {
    GET_LIST: '/comments',
    DELETE: '/comments/:id',
  },
  ZIPCODE: {
    GET_DETAIL: '/japan-posts/zip-code/:id',
  },
  USER_REPORT: {
    GET_LIST: '/user-reports',
  },
  POST_REPORT: {
    GET_LIST: '/post-reports',
  },
  CONTENT: {
    GET_LIST: '/contents',
    GET_DETAIL: '/contents/:id',
    CREATE: '/contents',
    UPDATE: '/contents/:id',
    DELETE: '/contents/:id',
  },
  SURVEY: {
    GET_LIST: '/surveys',
    GET_DETAIL: '/surveys/:id',
    CREATE: '/surveys',
    UPDATE: '/surveys/:id',
    DELETE: '/surveys/:id',
  },
  NOTIFICATION_REMIND: {
    GET_LIST: '/notifications-remind',
    GET_DETAIL: '/notifications-remind/:id',
    CREATE: '/notifications-remind',
    UPDATE: '/notifications-remind/:id',
    DELETE: '/notifications-remind/:id',
  },
  SURVEY_HEALTH: {
    GET_LIST: '/surveys-health',
    GET_DETAIL: '/surveys-health/:id',
    CREATE: '/surveys-health',
    UPDATE: '/surveys-health/:id',
    DELETE: '/surveys-health/:id',
  },
  SURVEY_HEALTH_QUESTION: {
    GET_LIST: '/surveys-health-question',
    GET_DETAIL: '/surveys-health-question/:id',
    CREATE: '/surveys-health-question',
    UPDATE: '/surveys-health-question/:id',
    DELETE: '/surveys-health-question/:id',
  },
  APP_VERSION: {
    GET_LIST: '/app-version-settings',
    GET_DETAIL: '/app-version-settings/:id',
    CREATE: '/app-version-settings/',
    UPDATE: '/app-version-settings/:id',
    DELETE: '/app-version-settings/:id',
  },
};

import './style.scss';

interface IProps {
  name: string,
  content: string,
}

export const DeleteContent: React.FC<IProps> = ({
  name,
  content,
}) => {
  return (
    <>
      <div className="border border-gray-300 w-full md:w-4/5">
        <div className="bg-gray-100 px-4 py-2 font-semibold">
          {name}
        </div>
        <div className="px-4 py-2 border-t border-gray-300">
          {content}
        </div>
      </div>
    </>
  )
};

import { IGetListCommonRequest } from '@/types/common';
import { Moment } from 'moment';

export enum NotificationType {
  SYSTEM = 'system',
  CRIME_PREVENTION = 'crime_prevention',
}

export const dayOfWeek = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 0 },
];

export interface IGetListNotificationRequest extends IGetListCommonRequest {
  title?: string;
}

export interface ICreateNotificationRequest {
  title: string;
  message: string;
  status: boolean;
  sentFromAt: Moment;
  sentToAt: Moment;
  type?: NotificationType;
  postCodeType?: number;
  address1?: string;
  address2?: string;
  preZipCode?: string;
  zipCode?: string | null;
}

export interface IUpdateNotificationRequest {
  title: string;
  message: string;
  status: boolean;
  sentFromAt: Moment;
  sentToAt: Moment;
  updatedAt?: string;
  type?: NotificationType;
  postCodeType?: number;
  address1?: string;
  address2?: string;
  preZipCode?: string;
  zipCode?: string | null;
}

import { Form, FormItemProps } from 'antd';
import clsx from 'clsx';
import './style.scss';

export const FieldReadOnly: React.FC<FormItemProps> = ({
  className,
  colon = false,
  label,
  children,
  ...props
}) => {
  const customLabel = <span className='font-semibold'>{label}:</span>;
  return (
    <Form.Item
      rootClassName={clsx('c-field-read-only', className)}
      colon={colon}
      label={customLabel}
      {...props}
    >
      {children}
    </Form.Item>
  );
};

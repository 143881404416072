import { Field, Input, RadioGroup, Spin, TextArea } from '@/components/commons';
import { DatePicker, Form, Select } from 'antd';
import { useFormContext } from 'react-hook-form';
import { platFormOptions } from '../../VersionList';
import { mustUpdateOptions } from '@/utils';
import { Moment } from 'moment';

interface IProps {
  isLoading: boolean;
}

export const VersionForm: React.FC<IProps> = ({ isLoading }) => {
  const { watch, setValue } = useFormContext();

  const isDisabled = watch('isDisabled');


  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field name='platform' className='flex-1' label='プラットフォーム' required>
          <Select
            style={{ width: '100%' }}
            options={platFormOptions}
            disabled={isDisabled}
          />
        </Field>
        <Field label='バージョン' name='version' required>
          <Input maxLength={10} disabled={isDisabled} />
        </Field>
        <Field label='実行' name='mustUpdate' required>
          <RadioGroup options={mustUpdateOptions} disabled={isDisabled} />
        </Field>
        <Field label='リリース日付' name='releaseDate' className='flex-1' required>
          <DatePicker
            showTime
            onCalendarChange={(e) => {
              setValue('releaseDate', e ? (e as Moment).second(0) : undefined);
            }}
            disabled={isDisabled}
          />
        </Field>
        <Field label='コンテンツ' name='description' required>
          <TextArea rows={2} maxLength={500} disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};

export enum FORM {
  CREATE_VERSION = 'CREATE_VERSION',
  UPDATE_VERSION = 'UPDATE_VERSION',
}

export enum PLATFORM {
  '*' = 'All',
  'ios' = 'IOS',
  'android' = 'ANDROID',
}

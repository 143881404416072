import { IResponsePagination } from '@/types/common';
import { IGetListUserRequest } from '@/types/request';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class UserReport {
  getList = (
    params: IGetListUserRequest,
  ): Promise<IResponsePagination<any>> => {
    return axiosClient.get(ENDPOINT.USER_REPORT.GET_LIST, { params: params });
  };
}

export const UserReportService = new UserReport();

import {
  Button,
  Field,
  Input,
  Select,
  Spin,
  TextArea,
} from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import {
  PromptKeyGroupService,
  PromptService,
  SpecialDayService,
} from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError, IResponsePagination } from '@/types/common';
import {
  ICreatePromptRequest,
  IUpdateSpecialDayRequest,
} from '@/types/request';
import { IGetListPromptKeyGroupResponse, IPromptKey } from '@/types/response';
import {
  NOTIFY_STATUS,
  PromptType,
  convertArrayToOptions,
  notificationMessage,
  replaceTemplate,
} from '@/utils';
import { ERROR_MESSAGE } from '@/utils/constants/messages';
import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FORM } from './constants';
import { formSchemaFn } from './formSchemaFn';

interface IProps {
  title?: string;
}

export const PromptForm: React.FC<IProps> = ({ title }) => {
  const [promptKeys, setPromptKeys] = useState<IPromptKey[]>();
  const { id: promptId } = useParams();
  const navigate = useNavigate();

  const [formName, setFormName] = useState<FORM>(FORM.CREATE_PROMPT);

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(formName, promptKeys ?? [])),
  });

  const {
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const formMonitor = useWatch({
    control,
  });

  const diary = formMonitor?.diary;
  const audience = formMonitor?.audience;
  const location = formMonitor?.location;
  const time = formMonitor?.time;
  const activities = formMonitor?.activities;
  const emotions = formMonitor?.emotions;
  const instructions = formMonitor?.instructions;
  const currentGroupId = formMonitor.groupId;

  // const { data: dayDetail, isFetching } = useQuery(
  //   [ENDPOINT.SPECIAL_DAY.GET_DETAIL.replace(':id', promptId!)],
  //   () => SpecialDayService.getDetail(Number(promptId)),
  //   {
  //     enabled: !!promptId,
  //     onSuccess: (response: IResponse<IGetDetailSpecialDayResponse>) => {
  //       const data = response.data;
  //       setFormName(FORM.UPDATE_PROMPT);
  //       reset({
  //         date: data.date,
  //         title: data.title,
  //         description: data.description,
  //       });
  //     },
  //     onError: (response: IResponseError) => {
  //       if (response.error === ERROR.DAY_NOT_FOUND) {
  //         navigate(APP_ROUTER.NOT_FOUND);
  //       }
  //     },
  //   },
  // );

  const { data: promptKeyGroups, isFetching: isLoadingPromptKeyGroups } =
    useQuery(
      [ENDPOINT.PROMPT_KEY_GROUP.GET_LIST.replace(':id', promptId!)],
      () => PromptKeyGroupService.getList({}),
      {
        onSuccess: (
          response: IResponsePagination<IGetListPromptKeyGroupResponse>,
        ) => {},
      },
    );

  const { mutate: create, isLoading: isLoadingCreate } = useMutation(
    (body: ICreatePromptRequest) => PromptService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.PROMPT.LIST);
      },
      onError: (response: IResponseError) => {
        notificationMessage({
          message:
            ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
      },
    },
  );

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateSpecialDayRequest; id: number }) =>
      SpecialDayService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message: 'Success',
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.SPECIAL_DAY.DETAIL.replace(':id', promptId!));
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
      },
    },
  );

  const handleCreate = (values: any) => {
    const payload: ICreatePromptRequest = {
      title: values?.title,
      instructions: values?.instructions,
      promptInputTypeId: values?.promptInputTypeId,
      status: values?.status,
    };

    create(payload);
  };

  const handleUpdate = (values: any) => {
    const payload = {
      id: Number(promptId),
      body: {
        title: values?.title,
        description: values?.description,
        promptInputTypeId: values?.promptInputTypeId,
        status: values?.status,
      },
    };

    update(payload);
  };

  useEffect(() => {
    const currentGroup = promptKeyGroups?.data?.data?.find(
      (group) => group.id === currentGroupId,
    );

    setPromptKeys(currentGroup?.promptKeys ?? []);
    setValue('instructions', currentGroup?.instructions);
  }, [currentGroupId]);

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[560px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={false}>
          <FormProvider {...methods}>
            {!false && (
              <>
                <header className='flex justify-between w-full md:w-[560px]'>
                  <h3 className='font-semibold text-[20px]'>Create Prompt</h3>
                </header>
                <Form
                  id='day-form'
                  labelCol={{ span: 10 }}
                  labelAlign='left'
                  className='flex flex-col justify-center w-full border-block p-3 mt-3'
                  onFinish={handleSubmit(
                    promptId ? handleUpdate : handleCreate,
                  )}
                >
                  <Field label='Title' name='title'>
                    <Input placeholder='Enter title' />
                  </Field>
                  <Field label='Description' name='description'>
                    <TextArea
                      placeholder='Enter description'
                      rows={6}
                      maxLength={500}
                    />
                  </Field>
                  <Field label='Group' name='groupId' required>
                    <Select
                      allowClear
                      placeholder='Select group'
                      options={convertArrayToOptions(
                        promptKeyGroups?.data?.data ?? [],
                        'id',
                        'name',
                      )}
                    />
                  </Field>
                  {promptKeys?.map((promptKey) => (
                    <Field
                      key={promptKey.id}
                      label={promptKey.key}
                      name={promptKey.key}
                      required
                    >
                      <Input placeholder={`Enter ${promptKey.key}`} />
                    </Field>
                  ))}
                  <Field label='Instructions' name='instructions' required>
                    <TextArea
                      placeholder='Enter instructions'
                      autoSize
                      maxLength={500}
                    />
                  </Field>
                  <Field label='Result' name='result'>
                    <TextArea placeholder='Enter result' readOnly autoSize />
                  </Field>
                </Form>
                <div className='flex w-full gap-2 mt-3'>
                  <Button
                    className='w-1/2'
                    icon={<LeftCircleOutlined />}
                    onClick={() =>
                      navigate(
                        promptId
                          ? APP_ROUTER.PROMPT.DETAIL.replace(':id', promptId!)
                          : APP_ROUTER.PROMPT.LIST,
                      )
                    }
                  >
                    Back
                  </Button>
                  <Button
                    type='primary'
                    className='w-1/2'
                    htmlType='submit'
                    form='day-form'
                    icon={<CheckCircleOutlined />}
                    loading={promptId ? isLoadingUpdate : isLoadingCreate}
                  >
                    {promptId ? ' Update' : 'Create'}
                  </Button>
                </div>
              </>
            )}
          </FormProvider>
        </Spin>
      </div>
    </div>
  );
};

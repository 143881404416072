import * as yup from 'yup';

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    question: yup
      .string()
      .required('質問は未入力です。')
      .max(100, '質問は100文字以内で入力してください。'),
    sortOrder: yup.number().required('表示順は未入力です。'),
    surveyHealthAnswers: yup.array().of(
      yup.object().shape({
        answer: yup
          .string()
          .required('回答選択内容は未入力です。')
          .max(100, '回答選択内容は100文字以内で入力してください。'),
        sortOrder: yup.number().required('表示順は未入力です。'),
        point: yup.number().required('点数は未入力です。'),
      }),
    ),
  } as any);

import {
  DatePicker,
  Field,
  Input,
  RadioGroup,
  Spin,
} from '@/components/commons';
import {
  modulesReactQuill,
  notificationStatus,
  postCodeTypeOptions,
} from '@/utils';
import { Badge, Button, Form, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';
import { notificationTypeOptions } from '../../NotificationList';
import { onlyHalfSizeRegex } from '@/pages/Event/Drawers/EventForm/formSchemaFn';
import { NotificationType } from '@/types/request';
import { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FontAttributor: any = Quill.import('formats/font');
FontAttributor.whitelist = ['Noto Sans JP', 'Arial', 'Times New Roman'];
Quill.register(FontAttributor, true);

const AlignStyle: any = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

interface IProps {
  isLoading: boolean;
  checkZipCode: any;
  isLoadingCheckZipCode: boolean;
}

export const NotificationForm: React.FC<IProps> = ({
  isLoading,
  checkZipCode,
  isLoadingCheckZipCode,
}) => {
  const { trigger, watch, setValue, setError } = useFormContext();

  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs().startOf('day'));
  };

  const disabledTime = (current: Dayjs) => {
    if (!current) return {};

    const now = dayjs();
    const selectedDate = dayjs(current);

    if (selectedDate.isBefore(now, 'day')) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    if (selectedDate.isSame(now, 'day')) {
      const disabledHours: number[] = [];
      for (let i = 0; i < now.hour(); i++) {
        disabledHours.push(i);
      }

      const disabledMinutes = (selectedHour: number) => {
        if (selectedHour === now.hour()) {
          const minutes = [];
          for (let i = 0; i <= now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      };

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: disabledMinutes,
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const isDisabled = watch('isDisabled');
  const postCodeType = watch('postCodeType');
  const zipCode = watch('zipCode');
  const type = watch('type');

  const handleKeyPressInputPostCode = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const char = String.fromCharCode(event.which);
    const inputValue = (event.target as HTMLInputElement).value;

    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  };

  const handlePasteInputPostCode = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const clipboardData = event.clipboardData.getData('text');
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    const selectionStart = inputElement.selectionStart ?? 0;
    const selectionEnd = inputElement.selectionEnd ?? 0;

    const newLength =
      inputValue.length -
      (selectionEnd - selectionStart) +
      clipboardData.length;

    if (!/^[0-9]*$/.test(clipboardData) || newLength > 7) {
      event.preventDefault();
    }
  };

  const onBlurInput = (e: any) => {
    if (!/^[0-9]*$/.test(e.target.value)) {
      setValue('zipCode', '');
      setError('zipCode', {
        type: 'validate',
        message: '郵便番号は未入力です。',
      });
    }
  };

  const handleCheckZipCode = (zipCode: string) => {
    if (zipCode) {
      if (!onlyHalfSizeRegex.test(zipCode)) {
        checkZipCode(zipCode);
      }
    } else {
      setError('zipCode', {
        type: 'validate',
        message: '郵便番号は未入力です。',
      });
    }
  };

  useEffect(() => {
    if (type === NotificationType.SYSTEM) {
      setValue('postCodeType', 0);
      trigger('postCodeType');
    }
  }, [type]);

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='タイトル' name='title' required>
          <Input
            placeholder='入力してください'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field label='本文' name='message' required>
          <ReactQuill
            readOnly={isDisabled}
            theme='snow'
            modules={modulesReactQuill}
            preserveWhitespace
          />
        </Field>
        <label className='title-calendar font-semibold'>
          配信日時
          <Badge status='error' className='ml-2' />
        </label>
        <div className='flex items-baseline	gap-1 pt-2'>
          <Field name='sentFromAt' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('sentFromAt', e ? (e as Moment).second(0) : undefined);
                trigger('sentFromAt');
                trigger('sentToAt');
              }}
              disabled={isDisabled}
            />
          </Field>
          <span>～</span>
          <Field required name='sentToAt' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('sentToAt', e ? (e as Moment).second(0) : undefined);
                trigger('sentFromAt');
                trigger('sentToAt');
              }}
              disabled={isDisabled}
            />
          </Field>
        </div>
        <Field name='type' className='flex-1' label='種類' required>
          <Select
            style={{ width: '100%' }}
            options={notificationTypeOptions}
            disabled={isDisabled}
          />
        </Field>
        <label className='title-calendar font-semibold '>
          配信範囲
          <Badge status='error' className='ml-2 mb-1' />
        </label>
        <Field
          name='postCodeType'
          className={`w-full ${!!postCodeType && 'mb-1'} `}
        >
          <RadioGroup
            options={postCodeTypeOptions}
            disabled={isDisabled || type === NotificationType.SYSTEM}
          />
        </Field>
        {!!postCodeType && (
          <>
            <div className='flex items-baseline'>
              <Field name='zipCode' className='mb-0'>
                <Input
                  className='w-[200px] mr-1 bg-transparent hover:bg-transparent'
                  disabled={isDisabled}
                  onKeyPress={handleKeyPressInputPostCode}
                  onPaste={handlePasteInputPostCode}
                  onBlur={onBlurInput}
                  maxLength={7}
                />
              </Field>
              <Button
                className='absolute left-[210px] pt-1'
                disabled={isDisabled}
                type='primary'
                size='middle'
                onClick={() => handleCheckZipCode(zipCode)}
                loading={isLoadingCheckZipCode}
              >
                住所検索
              </Button>
            </div>
            <Field name='note' className='mb-1'>
              <span>※ハイフンなし半角で入力してください</span>
            </Field>
            <Field name='address1' className='mb-1'>
              <Input
                placeholder='-'
                maxLength={200}
                disabled={true}
                style={!isDisabled ? { color: 'black' } : {}}
              />
            </Field>
            <Field name='address2'>
              <Input
                placeholder='-'
                maxLength={200}
                disabled={true}
                style={!isDisabled ? { color: 'black' } : {}}
              />
            </Field>
          </>
        )}
        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={notificationStatus} disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};

import { IGetListCommonRequest, IResponse, IResponsePagination } from '@/types/common';
import { ICreateSurveyHealthResponse, IGetDetailSurveyHealthResponse, IGetListSurveyHeathResponse, IUpdateSurveyHealthResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';
import { ICreateSurveyHealthRequest, IUpdateSurveyHealthRequest } from '@/types/request/surveyHealth';

class SurveyHealth {
  getList = (
    params?: IGetListCommonRequest,
  ): Promise<IResponsePagination<IGetListSurveyHeathResponse>> => {
    return axiosClient.get(ENDPOINT.SURVEY_HEALTH.GET_LIST, { params: params });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailSurveyHealthResponse>> => {
    return axiosClient.get(
      ENDPOINT.SURVEY_HEALTH.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateSurveyHealthRequest,
  ): Promise<IResponse<ICreateSurveyHealthResponse>> => {
    return axiosClient.post(ENDPOINT.SURVEY_HEALTH.CREATE, body);
  };

  update = (
    data: IUpdateSurveyHealthRequest,
    id: number,
  ): Promise<IResponse<IUpdateSurveyHealthResponse>> => {
    return axiosClient.put(
      ENDPOINT.SURVEY_HEALTH.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.SURVEY_HEALTH.DELETE.replace(':id', String(id)),
    );
  };
}

export const SurveyHealthService = new SurveyHealth();

import { IGetListCommonRequest } from '@/types/common';

export interface IGetListPromptRequest extends IGetListCommonRequest {
  title?: string;
}

export enum PromptStatus {
  ENABLE = 'enable',
  DRAFT = 'draft',
}

export interface ICreatePromptRequest {
  title: string;
  promptInputTypeId: number;
  instructions: string;
  status: PromptStatus;
}

export interface IUpdatePromptRequest {
  title: string;
  promptInputTypeId: number;
  instructions: string;
  status: PromptStatus;
}

import {
  Field,
  Input,
  RadioGroup,
  Spin,
} from '@/components/commons';
import { modulesReactQuill, notificationStatus } from '@/utils';
import { Form, InputNumber, Select } from 'antd';
import { useFormContext } from 'react-hook-form';
import { surveyHealthTypeOptions } from '../../SurveyHealthList';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FontAttributor: any = Quill.import('formats/font');
FontAttributor.whitelist = ['Noto Sans JP', 'Arial', 'Times New Roman'];
Quill.register(FontAttributor, true);

const AlignStyle: any = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

interface IProps {
  isLoading: boolean;
}

export const SurveyHealthForm: React.FC<IProps> = ({ isLoading }) => {
  const { watch } = useFormContext();

  const isDisabled = watch('isDisabled');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='アンケート名' name='name' required>
          <Input
            placeholder='入力してください'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field label='タイトル' name='title' required>
          <Input
            placeholder='入力してください'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field label='アンケートTOP内容' name='description' required>
          <ReactQuill readOnly={isDisabled} theme='snow' modules={modulesReactQuill} />
        </Field>
        <Field label='アンケート終了内容' name='message' required>
          <ReactQuill readOnly={isDisabled} theme='snow' modules={modulesReactQuill} />
        </Field>
        <Field name='type' className='flex-1' label='タイプ' required>
          <Select
            style={{ width: '100%' }}
            options={surveyHealthTypeOptions}
            disabled={isDisabled}
          />
        </Field>
        <Field name='sortOrder' className='flex-1' label='表示順' required>
          <InputNumber
            min={0}
            disabled={isDisabled}
          />
        </Field>
        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={notificationStatus} disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};

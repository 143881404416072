import * as yup from 'yup';
import { FORM_NAME } from './constants';

export const formSchemaFn = (formName: string) =>
  yup.object().shape({
    title:
      (formName === FORM_NAME.CREATE_USER ||
        formName === FORM_NAME.UPDATE_USER) &&
      yup
        .string()
        .required('Please input title')
        .max(100, 'Length must not exceed 100 characters'),
    content:
      formName === FORM_NAME.CREATE_USER &&
      yup
        .string()
        .required('Please input content')
        .max(500, 'Length must not exceed 100 characters'),
  } as any);

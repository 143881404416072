import { Button, FieldReadOnly, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { UserService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { FALLBACK_URL } from '@/services/image';
import { IResponse, IResponseError } from '@/types/common';
import { IGetDetailUserResponse } from '@/types/response';
import {
  genderOptions,
  userStatus,
  userStatusColors,
  verifyStatusColors,
} from '@/utils';
import { ERROR } from '@/utils/constants/messages';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import { Form, Image, Tag } from 'antd';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const UserDetail: React.FC<IProps> = ({ title }) => {
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const { data: userDetail, isFetching } = useQuery(
    [ENDPOINT.USER.GET_DETAIL.replace(':id', userId!)],
    () => UserService.getDetail(Number(userId)),
    {
      onSuccess: (response: IResponse<IGetDetailUserResponse>) => {},
      onError: (response: IResponseError) => {
        if (response.error === ERROR.USER_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data = userDetail?.data;

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[400px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          {!isFetching && (
            <>
              <header className='flex justify-between w-full md:w-[400px]'>
                <h3 className='font-semibold text-[20px]'>User Detail</h3>
                {/* <div>
                  <Button
                    type='primary'
                    icon={<EditFilled />}
                    onClick={() =>
                      navigate(APP_ROUTER.USER.UPDATE.replace(':id', userId!))
                    }
                  >
                    Edit
                  </Button>
                </div> */}
              </header>
              <Form
                labelCol={{ span: 10 }}
                labelAlign='left'
                className='flex flex-col justify-center w-full border-block p-3 mt-3'
              >
                <FieldReadOnly label='アバター'>
                  <Image
                    height={100}
                    src={data?.profile?.avatar?.url}
                    className='block rounded-lg'
                    fallback={FALLBACK_URL}
                  />
                </FieldReadOnly>
                <FieldReadOnly label='メールアドレス'>
                  <div className='break-all'>{data?.email}</div>
                </FieldReadOnly>
                <FieldReadOnly label='ステータス確認'>
                  <Tag
                    color={
                      verifyStatusColors[
                        String(
                          data?.isVerified,
                        ) as keyof typeof verifyStatusColors
                      ]
                    }
                  >
                    {data?.isVerified ? 'Yes' : 'No'}
                  </Tag>
                </FieldReadOnly>
                <FieldReadOnly label='ステータス'>
                  <Tag
                    color={
                      userStatusColors[
                        data?.status as keyof typeof userStatusColors
                      ]
                    }
                  >
                    {userStatus[data?.status as keyof typeof userStatus]}
                  </Tag>
                </FieldReadOnly>
                <FieldReadOnly label='性別'>
                  <div className='break-all'>
                    {
                      genderOptions.find(
                        ({ value }) => data?.profile?.gender === value,
                      )?.label
                    }
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='ニックネーム'>
                  <div className='break-all'>{data?.profile?.nickname}</div>
                </FieldReadOnly>
                <FieldReadOnly label='生年月日'>
                  {moment(data?.profile?.dayOfBirth).format('YYYY-MM-DD')}
                </FieldReadOnly>
                <FieldReadOnly label='郵便番号'>
                  <div className='break-all'>
                    {data?.profile?.japanPostId?.zipCode}
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='都道府県'>
                  <div className='break-all'>
                    {data?.profile?.japanPostId?.address1}
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='市区町村'>
                  <div className='break-all'>
                    {data?.profile?.japanPostId?.address2}
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='登録日'>
                  {moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </FieldReadOnly>
                <FieldReadOnly label='退会日'>
                  {data?.deletedAt && moment(data?.deletedAt).format('YYYY-MM-DD HH:mm:ss')}
                </FieldReadOnly>
                <FieldReadOnly label='最終ログイン日'>
                  {data?.lastLoginAt && moment(data?.lastLoginAt).format('YYYY-MM-DD HH:mm:ss')}
                </FieldReadOnly>
              </Form>
              <div className='w-full flex justify-center mt-3'>
                <Button
                  className='w-1/2'
                  icon={<LeftCircleOutlined />}
                  onClick={() => navigate(APP_ROUTER.USER.LIST)}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </Spin>
      </div>
    </div>
  );
};

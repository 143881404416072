import {
  Example,
  ForgotPassword,
  Home,
  Login,
  PostDetail,
  PostFukumaruDetail,
  PostFukumaruList,
  PostPage,
  ResetPassword,
  SpecialDayDetail,
  SpecialDayForm,
  SpecialDayList,
  UserDetail,
  UserPage,
  VersionList,
} from '@/pages';
import { UserUpdatePage } from '@/pages/User/UserForm';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { APP_ROUTER } from './routes';
import { NotificationList } from '@/pages/Notification';
import { PromptDetail, PromptForm, PromptList } from '@/pages/Prompt';
import { EventList } from '@/pages/Event';
import { ContentList } from '@/pages/Content';
import { SurveyList } from '@/pages/Survey';
import { NotificationRemindList } from '@/pages/NotificationRemind';
import { SurveyHealthList } from '@/pages/SurveyHealth';
import { SurveyHealthQuestionList } from '@/pages/SurveyHealthQuestion';

export interface IRouteWrapProps {
  isAuthenticated: boolean;
}

export const publicRoutes = [
  {
    path: APP_ROUTER.AUTH.LOGIN,
    element: <Login title='Login' />,
  },
  {
    path: APP_ROUTER.AUTH.FORGOT_PASSWORD,
    element: <ForgotPassword title='Forgot Password' />,
  },
  {
    path: APP_ROUTER.AUTH.RESET_PASSWORD,
    element: <ResetPassword title='Reset Password' />,
  },
];

export const privateRoutes = [
  {
    path: APP_ROUTER.HOME,
    element: <Home title='ユーザー一覧' />,
  },
  {
    path: APP_ROUTER.USER.LIST,
    element: <UserPage title='ユーザー一覧' />,
  },
  {
    path: APP_ROUTER.USER.DETAIL,
    element: <UserDetail title='ユーザー一覧' />,
  },
  {
    path: APP_ROUTER.USER.UPDATE,
    element: <UserUpdatePage title='ユーザー一覧' />,
  },
  {
    path: APP_ROUTER.POST.LIST,
    element: <PostPage title='投稿一覧' />,
  },
  {
    path: APP_ROUTER.POST.DETAIL,
    element: <PostDetail title='投稿一覧' />,
  },
  {
    path: APP_ROUTER.POST_FUKUMARU.LIST,
    element: <PostFukumaruList title='ふくまる' />,
  },
  {
    path: APP_ROUTER.POST_FUKUMARU.DETAIL,
    element: <PostFukumaruDetail title='ふくまる' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.LIST,
    element: <SpecialDayList title='今日は何の日' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.DETAIL,
    element: <SpecialDayDetail title='今日は何の日' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.CREATE,
    element: <SpecialDayForm title='今日は何の日' />,
  },
  {
    path: APP_ROUTER.SPECIAL_DAY.UPDATE,
    element: <SpecialDayForm title='今日は何の日' />,
  },
  {
    path: APP_ROUTER.NOTIFICATION.LIST,
    element: <NotificationList title='お知らせ' />,
  },
  {
    path: APP_ROUTER.PROMPT.LIST,
    element: <PromptList title='プロンプト' />,
  },
  {
    path: APP_ROUTER.PROMPT.DETAIL,
    element: <PromptDetail title='プロンプト' />,
  },
  {
    path: APP_ROUTER.PROMPT.CREATE,
    element: <PromptForm title='プロンプト' />,
  },
  {
    path: APP_ROUTER.EVENT.LIST,
    element: <EventList title='イベント' />,
  },
  {
    path: APP_ROUTER.CONTENT.LIST,
    element: <ContentList title='コンテンツ' />,
  },
  {
    path: APP_ROUTER.SURVEY.LIST,
    element: <SurveyList title='アンケート' />,
  },
  // {
  //   path: APP_ROUTER.NOTIFICATION_REMIND.LIST,
  //   element: <NotificationRemindList title='プッシュ通知' />,
  // },
  {
    path: APP_ROUTER.SURVEY_HEALTH.LIST,
    element: <SurveyHealthList title='お口アンケート' />,
  },
  {
    path: APP_ROUTER.SURVEY_HEALTH.LIST_QUESTION,
    element: <SurveyHealthQuestionList title='お口アンケート' />,
  },
  {
    path: APP_ROUTER.VERSION.LIST,
    element: <VersionList title='バージョン' />,
  },
];

export const PublicRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation();

  return isAuthenticated ? (
    <Navigate to={APP_ROUTER.HOME} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export const PrivateRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTER.AUTH.LOGIN} state={{ from: location }} replace />
  );
};

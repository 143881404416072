import { ITimestampResponse } from '@/types/common';
import { Moment } from 'moment';

export enum TypeSurveyHealth {
  EAT_10 = 'EAT_10',
  DVS = 'DVS',
  OF5 = 'OF5',
  ELDERLY = 'ELDERLY',
}

export const generateSurveyHealthTypeText = (value: TypeSurveyHealth) => {
  switch (value) {
    case TypeSurveyHealth.EAT_10:
      return '飲み込む力のチェック';
    case TypeSurveyHealth.DVS:
      return '食事のバランスのチェック';
    case TypeSurveyHealth.OF5:
      return 'お口の健康のチェック';
    case TypeSurveyHealth.ELDERLY:
      return '一週間の食事のチェック';
    default:
      return '飲み込む力のチェック';
  }
};

export interface ISurveyHealth extends ITimestampResponse {
  id: number;
  name: string;
  title: string;
  description: string;
  message: string;
  sortOrder: number;
  status: number;
  type: TypeSurveyHealth;
}

export interface IGetListSurveyHeathResponse extends ISurveyHealth {}

export interface IGetDetailSurveyHealthResponse extends ISurveyHealth {}

export interface ICreateSurveyHealthResponse extends ISurveyHealth {}

export interface IUpdateSurveyHealthResponse extends ISurveyHealth {}

import { Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { PostList } from '../PostList';
import { PostReportList } from '../PostReportList';
import { DISPLAY_MODE } from '@/utils';

interface IProps {
  title?: string;
}

export const PostPage: React.FC<IProps> = ({title}) => {
  const [searchParams] = useSearchParams();
  const [postId, setPostId] = useState<string>(
    searchParams.get('postId') || '',
  );
  const mode =
    (searchParams.get('mode') as DISPLAY_MODE) || DISPLAY_MODE.DEFAULT;

  return (
    <Tabs
      onChange={() => setPostId('')}
      destroyInactiveTabPane={true}
      defaultActiveKey={mode}
      type='card'
      items={[
        {
          label: `投稿一覧`,
          key: DISPLAY_MODE.DEFAULT,
          children: <PostList title={title}/>,
        },
        {
          label: '報告一覧',
          key: DISPLAY_MODE.REPORT,
          children: <PostReportList title={title} postId={postId} />,
        },
      ]}
    />
  );
};

import { Drawer, Input, Pagination, Table } from '@/components/commons';
import { PostService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IGetDetailPostResponse } from '@/types/response';
import {
  convertRequest,
  handleErrorCommon,
  postStatus,
  postStatusColors,
  postTypeColors,
  postTypes,
} from '@/utils';
import { IResponse, IResponseError } from '@/types/common';
import { FormProvider, useForm } from 'react-hook-form';
import { PostForm } from '../Drawers';
import { SearchOutlined } from '@ant-design/icons';
import { Image, TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import './style.scss';

interface IProps {
  title?: string;
}

interface IParams {
  title: string;
  limit: number;
  page: number;
}

export const PostList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    title: '',
    limit: 10,
    page: 1,
  });

  const navigate = useNavigate();

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isFetching, refetch } = useQuery([ENDPOINT.POST.GET_LIST, params], () =>
    PostService.getList(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '220px',
    },
    {
      title: '写真',
      dataIndex: 'imageCover',
      width: '140px',
      align: 'center',
      render: (imageCover) =>
        imageCover?.url && <Image width={112} src={imageCover?.url} preview={false} />,
    },
    {
      title: '種類',
      dataIndex: 'type',
      width: '140px',
      align: 'center',
      render: (value) => (
        <Tag color={postTypeColors[value as keyof typeof postTypeColors]}>
          {postTypes[value as keyof typeof postTypes]}
        </Tag>
      ),
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: '140px',
      align: 'center',
      render: (value) => (
        <Tag color={postStatusColors[value as keyof typeof postStatusColors]}>
          {postStatus[value as keyof typeof postStatus]}
        </Tag>
      ),
    },
    {
      title: '家族',
      dataIndex: 'familyId',
      width: '160px',
      render: (familyId) => familyId.name,
    },
    {
      title: 'いつ',
      dataIndex: 'time',
      align: 'center',
      width: '166px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    // {
    //   align: 'center',
    //   width: '166px',
    //   fixed: 'right',
    //   render: (record: IGetListUserResponse) => (
    //     <Button
    //       type='primary'
    //       icon={<EyeFilled />}
    //       onClick={() =>
    //         navigate(APP_ROUTER.POST.DETAIL.replace(':id', String(record.id)))
    //       }
    //     >
    //       Detail
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        title: search,
      }));
    }, 500);
  }, [search]);

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    reset({});
  };

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.POST.GET_DETAIL.replace(':id', String(currentId))],
    () => PostService.getDetail(Number(currentId)),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailPostResponse>) => {
        const data = response.data;
        reset({
          title: data?.title,
          imageCover: data?.imageCover?.url,
          status: postStatus[data?.status as keyof typeof postStatus],
          type: data?.type,
          activities: data?.activities,
          audience: data?.audience,
          emotions: data?.emotions,
          diary: data?.diary,
          location: data?.location,
          content: data?.content,
          family: data?.familyId.name,
          topic: data?.topicId.title,
          time: data?.time ? moment(data?.time).format('YYYY-MM-DD HH:mm:ss') : '',
          publicAt: data?.publicAt ? moment(data?.publicAt).format('YYYY-MM-DD HH:mm:ss') : '',
          isDisabled: true
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const methods = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { reset, setError, watch } = methods;

  useEffect(() => {

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isNotFoundDetail]);

  const isDisabled = watch('isDisabled');

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              // setOpenDrawer(true);
              // setCurrentId(record.id);
              // setIsNotFoundDetail(false);
              navigate(APP_ROUTER.POST.DETAIL.replace(':id', String(record.id)))
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title='投稿詳細'
        onOk={() => {}}
        onCancel={handleCancel}
        onDelete={() => {}}
        maskClosable={false}
        open={openDrawer}
        loading={isFetchingDetail}
        isDeleted={false}
        disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <PostForm isLoading={isFetchingDetail} />
        </FormProvider>
      </Drawer>
    </div>
  );
};

import * as yup from 'yup';

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    nickname: yup.string().required('ニックネームが未入力です。'),
    dayOfBirth: yup.string().required('生年月日が未入力です。'),
    zipCode: yup.string().when('preZipCode', ([preZipCode], schema) => {
      return schema
        .required('開催場所は未入力です。')
        .test(
          'zipCode',
          '「住所検索」をタップして郵便番号を確認してください',
          (zipCode) => {
            return preZipCode === zipCode;
          },
        );
    }),
  } as any);

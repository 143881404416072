import { Button, FieldReadOnly, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PromptService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError } from '@/types/common';
import { IGetDetailPromptResponse } from '@/types/response';
import { ERROR } from '@/utils/constants/messages';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const PromptDetail: React.FC<IProps> = ({ title }) => {
  const { id: promptId } = useParams();
  const navigate = useNavigate();

  const { data: promptDetail, isFetching } = useQuery(
    [ENDPOINT.PROMPT.GET_DETAIL.replace(':id', promptId!)],
    () => PromptService.getDetail(Number(promptId)),
    {
      onError: (response: IResponseError) => {
        if (response.error === ERROR.PROMPT_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data: IGetDetailPromptResponse = promptDetail?.data!;

  const getValueByKey = (key: string) => {
    const found = data.keyInputs.find((item) => item.key === key);
    return found ? found.value : null;
  };

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[560px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          {!isFetching && (
            <>
              <header className='flex justify-between w-full md:w-[400px]'>
                <h3 className='font-semibold text-[20px]'>Prompt Detail</h3>
              </header>
              <Form
                labelCol={{ span: 10 }}
                labelAlign='left'
                className='flex flex-col justify-center w-full border-block p-3 mt-3'
              >
                <FieldReadOnly label='Title'>
                  <div className='break-all'>{data?.title}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Description'>
                  <div className='break-all'>{data?.description}</div>
                </FieldReadOnly>
                <FieldReadOnly label='Group'>
                  <div className='break-all'>{data.groupId?.name}</div>
                </FieldReadOnly>
                {data.groupId?.promptKeys?.map((promptKey, index) => (
                  <FieldReadOnly key={index} label={promptKey.key}>
                    <div className='break-all'>
                      {getValueByKey(promptKey.key)}
                    </div>
                  </FieldReadOnly>
                ))}
                <FieldReadOnly label='Instructions'>
                  <div style={{ whiteSpace: 'pre-line', lineHeight: 1.4 }}>
                    {data?.instructions}
                  </div>
                </FieldReadOnly>
                <FieldReadOnly label='Result'>
                  <div style={{ whiteSpace: 'pre-line', lineHeight: 1.4 }}>
                    {data?.result}
                  </div>
                </FieldReadOnly>
              </Form>
              <div className='w-full flex justify-center mt-3'>
                <Button
                  className='w-1/2'
                  icon={<LeftCircleOutlined />}
                  onClick={() => navigate(APP_ROUTER.PROMPT.LIST)}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </Spin>
      </div>
    </div>
  );
};

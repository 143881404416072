import { Field, Input, Spin, TextArea } from '@/components/commons';
import { convertObjectToArray, postStatus, postTypes } from '@/utils';
import { Form, Image, Select } from 'antd';
import { useFormContext } from 'react-hook-form';

interface IProps {
  isLoading: boolean;
}

export const PostForm: React.FC<IProps> = ({ isLoading }) => {
  const { watch } = useFormContext();

  const isDisabled = watch('isDisabled');
  const imageCover = watch('imageCover');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field name='title' label='タイトル' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        {imageCover! && (
          <Field label='写真' name='imageCover'>
            <Image width={112} src={!isLoading && imageCover} />
          </Field>
        )}
        <Field name='status' label='ステータス' className='w-full'>
          <Select
            options={convertObjectToArray(postStatus, 'string')}
            disabled={isDisabled}
          />
        </Field>
        <Field name='type' label='種類' className='w-full'>
          <Select
            disabled={isDisabled}
            options={convertObjectToArray(postTypes, 'string')}
          />
        </Field>
        <Field name='activities' label='活動' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='audience' label='誰と' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='emotions' label='どんな気分？' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='diary' label='絵日記' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='location' label='場所' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='content' label='本文'>
          <TextArea
            placeholder='入力してください'
            rows={3}
            maxLength={500}
            disabled={isDisabled}
          />
        </Field>
        <Field name='family' label='家族' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='topic' label='トピック' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='time' label='いつ' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
        <Field name='publicAt' label='公開日' className='w-full'>
          <Input disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};

import { APP_ROUTER } from '@/routes/routes';
import { AuthService } from '@/services';
import { IResponseError } from '@/types/common';
import {
  ICheckResetPasswordRequest,
  IResetPasswordRequest,
} from '@/types/request';
import { NOTIFY_STATUS, notificationMessage } from '@/utils';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { formSchemaFn } from './formSchemaFn';
import { Button, Field, InputPassword } from '@/components/commons';
import { Form } from 'antd';

interface IProps {
  title?: string;
}

export const ResetPassword: React.FC<IProps> = ({ title }) => {
  const { code } = useParams();
  const navigate = useNavigate();

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const { handleSubmit } = methods;

  const { mutate: checkResetPassword } = useMutation(
    (body: ICheckResetPasswordRequest) => AuthService.checkResetPassword(body),
    {
      onSuccess: () => {},
      onError: (response: IResponseError) => {
        if (response.error === ERROR.RESET_PASSWORD_INVALID) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const { mutate: resetPassword, isLoading: isLoadingResetPassword } =
    useMutation(
      (body: IResetPasswordRequest) => AuthService.resetPassword(body),
      {
        onSuccess: () => {
          navigate(APP_ROUTER.AUTH.LOGIN);
          notificationMessage({
            message: 'Reset password successfully',
            type: NOTIFY_STATUS.SUCCESS,
          });
        },
        onError: (response: IResponseError) => {
          if (response.error === ERROR.RESET_PASSWORD_INVALID) {
            notificationMessage({
              message:
                ERROR_MESSAGE[response.message as keyof typeof ERROR_MESSAGE],
              type: NOTIFY_STATUS.SUCCESS,
            });
          }
        },
      },
    );

  const handleResetPassword = (values: any) => {
    resetPassword({
      codeVerify: code!,
      password: values.newPassword,
    });
  };

  useEffect(() => {
    checkResetPassword({
      codeVerify: code!,
    });
  }, []);

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-[396px]'>
        <header>
          <div className='text-[20px] font-semibold text-center'>
            Reset password
          </div>
          <span className='desc'>
            Please enter the password you registered. We will reissue a new
            password.
          </span>
        </header>
        <FormProvider {...methods}>
          <Form
            className='mt-[32px] flex flex-col justify-center items-center'
            layout='vertical'
            onFinish={handleSubmit(handleResetPassword)}
          >
            <Field name='newPassword' label='New Password' className='w-full'>
              <InputPassword placeholder='' />
            </Field>
            <Field
              name='passwordConfirmation'
              label='Password Confirmation'
              className='w-full'
            >
              <InputPassword placeholder='' />
            </Field>
            <Button
              type='primary'
              className='w-[316px] font-semibold'
              htmlType='submit'
              loading={isLoadingResetPassword}
            >
              Change password
            </Button>
            <Button
              className='back-btn font-semibold w-[316px]'
              type='link'
              onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
            >
              Back
            </Button>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};

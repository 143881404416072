import moment from 'moment';
import * as yup from 'yup';

export const onlyHalfSizeRegex =
  /[\uFF01-\uFF60\uFFE0-\uFFEF\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    name: yup
      .string()
      .required('アンケート名が入力されていません。')
      .max(100, 'アンケート名は100文字以内で入力してください。'),
    surveyFrom: yup
      .date()
      .required('回答期間の開始日時を選択してください。')
      .when(['status', 'isDisabled'], ([status, isDisabled], schema) => {
        if (!status || isDisabled) {
          return schema;
        }
        return schema.min(
          moment().add(1, 'minute').startOf('minute').toDate(),
          isActionEdit
            ? '回答期間が過去の日時なので、更新できませんでした。'
            : '回答期間が過去の日時なので、作成できませんでした。',
        );
      }),
    surveyTo: yup
      .date()
      .required('回答期間の終了日時を選択してください。')
      .min(
        yup.ref('surveyFrom'),
        '終了日時は開始日時より後の日付を選択してください。',
      ),
    instructions: yup
      .string()
      .required('プロンプトが入力されていません。')
      .max(10000, 'プロンプトは10000文字以内で入力してください。'),
    status: yup.boolean().required('バナー画像は未入力です。'),
    remindNotificationAt: yup
      .date()
      .nullable()
      .when(['surveyFrom', 'surveyTo'], ([surveyFrom, surveyTo], schema) => {
        if (!surveyFrom || !surveyTo) {
          return schema;
        }
        return schema
          .min(
            moment(surveyFrom).startOf('minute').toDate(),
            'お知らせ通知は回答期間内の日時を選択してください。',
          )
          .max(
            moment(surveyTo).startOf('minute').toDate(),
            'お知らせ通知は回答期間内の日時を選択してください。',
          );
      }),
    postCodeType: yup.number().required('開催場所は未入力です。'),
    zipCode: yup
      .string()
      .when(
        ['postCodeType', 'preZipCode', 'keepCheckZipCodeError', 'address1'],
        (
          [postCodeType, preZipCode, keepCheckZipCodeError, address1],
          schema,
        ) => {
          if (!postCodeType) {
            return schema;
          }
          return schema
            .required('郵便番号は未入力です。')
            .test(
              'noFullWidth',
              '郵便番号は半角英数字で入力してください。',
              (zipCode) => {
                return !onlyHalfSizeRegex.test(zipCode || '');
              },
            )
            .test('zipCode', '「住所検索」をタップしください', (zipCode) => {
              return (
                keepCheckZipCodeError || (preZipCode === zipCode && address1)
              );
            })
            .test('errorZipCode', 'この番号では検索できません。', (_) => {
              return !keepCheckZipCodeError;
            });
        },
      ),
  } as any);

import {
  DatePicker,
  Field,
  Input,
  RadioGroup,
  Spin,
  TextArea,
} from '@/components/commons';
import {
  EventFeeType,
  eventFeeTypeOptions,
  eventStatus,
  postCodeTypeOptions,
} from '@/utils';
import { Badge, Button, Form, Image, InputNumber, Upload } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';
import { CloseOutlined } from '@ant-design/icons';
import {
  FALLBACK_URL,
  FILE_SIZE_LIMIT,
  SUPPORTED_FORMATS,
} from '@/services/image';
import { onlyHalfSizeRegex } from './formSchemaFn';
import { useEffect } from 'react';

interface IProps {
  isLoading: boolean;
  openDrawer: boolean;
  checkZipCode: any;
  isLoadingCheckZipCode: boolean;
}

export const EventForm: React.FC<IProps> = ({
  isLoading,
  openDrawer,
  checkZipCode,
  isLoadingCheckZipCode,
}) => {
  const { trigger, watch, setValue, setError, clearErrors } = useFormContext();

  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs().startOf('day'));
  };

  const disabledTime = (current: Dayjs) => {
    if (!current) return {};

    const now = dayjs();
    const selectedDate = dayjs(current);

    if (selectedDate.isBefore(now, 'day')) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    if (selectedDate.isSame(now, 'day')) {
      const disabledHours: number[] = [];
      for (let i = 0; i < now.hour(); i++) {
        disabledHours.push(i);
      }

      const disabledMinutes = (selectedHour: number) => {
        if (selectedHour === now.hour()) {
          const minutes = [];
          for (let i = 0; i <= now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      };

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: disabledMinutes,
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const isDisabled = watch('isDisabled');
  const feeType = watch('feeType');
  const imageBanner = watch('imageBanner');
  const attachments = watch('attachments');
  const postCodeType = watch('postCodeType');
  const zipCode = watch('zipCode');
  const keepCheckZipCodeError = watch('keepCheckZipCodeError');

  const handleUpload = (info: any) => {
    const file = info.file.originFileObj || info.file;
    const reader = new FileReader();
    reader.onload = () => {
      setValue('imageBanner', reader.result as string);
      setValue('attachments', info);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setValue('imageBanner', null);
    setValue('attachments', null);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const char = String.fromCharCode(event.which);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData.getData('text');
    if (!/^[0-9]*$/.test(clipboardData)) {
      event.preventDefault();
    }
  };

  const handleKeyPressInputPostCode = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const char = String.fromCharCode(event.which);
    const inputValue = (event.target as HTMLInputElement).value;

    if (!/[0-9]/.test(char) || inputValue.length >= 7) {
      event.preventDefault();
    }
  };

  const handlePasteInputPostCode = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const clipboardData = event.clipboardData.getData('text');
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    const selectionStart = inputElement.selectionStart ?? 0;
    const selectionEnd = inputElement.selectionEnd ?? 0;

    const newLength =
      inputValue.length -
      (selectionEnd - selectionStart) +
      clipboardData.length;

    if (!/^[0-9]*$/.test(clipboardData) || newLength > 7) {
      event.preventDefault();
    }
  };

  const beforeUpload = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      setError('attachment', {
        type: 'validate',
        message: '画像のサイズは10MBを超えています。',
      });
      return Upload.LIST_IGNORE;
    }

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      setError('attachment', {
        type: 'validate',
        message: '画像は .jpg、.png、.jpeg 形式ではありません。',
      });
      return Upload.LIST_IGNORE;
    }
    clearErrors('attachment');

    return true;
  };

  useEffect(() => {
    if (keepCheckZipCodeError) {
      setValue('keepCheckZipCodeError', null);
    }
  },[zipCode])
  
  const onBlurInput = (e: any) => {
    if (!/^[0-9]*$/.test(e.target.value)) {
      setValue('zipCode', '')
      setError('zipCode', {
        type: 'validate',
        message: '郵便番号は未入力です。',
      });
    }
  }

  const handleCheckZipCode = (zipCode: string) => {
    if (zipCode) {
      if (
        !onlyHalfSizeRegex.test(
          zipCode,
        )
      ) {
        checkZipCode(zipCode);
      }
    } else {
      setError('zipCode', {
        type: 'validate',
        message: '郵便番号は未入力です。',
      });
    }
  };

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='イベント名' name='name' required>
          <Input
            placeholder='イベント名'
            maxLength={40}
            disabled={isDisabled}
          />
        </Field>
        <label className='title-calendar font-semibold'>
          開催期間
          <Badge status='error' className='ml-2' />
        </label>
        <div className='flex items-baseline	gap-1 pt-2'>
          <Field name='eventFrom' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('eventFrom', e ? (e as Moment).second(0) : undefined);
                trigger('eventFrom');
                trigger('eventTo');
              }}
              disabled={isDisabled}
            />
          </Field>
          <span>～</span>
          <Field name='eventTo' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('eventTo', e ? (e as Moment).second(0) : undefined);
                trigger('eventFrom');
                trigger('eventTo');
              }}
              disabled={isDisabled}
            />
          </Field>
        </div>
        <Field label='開催場所' name='address' required>
          <Input placeholder='開催場所' maxLength={100} disabled={isDisabled} />
        </Field>
        <label className='title-calendar font-semibold'>
          参加費
          <Badge status='error' className='ml-2' />
        </label>
        <div className='flex items-baseline pt-2 min-h-[70px]'>
          <Field name='feeType' className='pr-5' required>
            <RadioGroup options={eventFeeTypeOptions} disabled={isDisabled} />
          </Field>
          {feeType === EventFeeType.PAID && (
            <>
              <Field name='fee'>
                <InputNumber<number>
                  className='w-[110px] mr-1 bg-transparent hover:bg-transparent'
                  disabled={isDisabled}
                  placeholder='金額'
                  precision={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value?.replace(/\$\s?|(,*)/g, '') as unknown as number
                  }
                  onKeyPress={handleKeyPress}
                  onPaste={handlePaste}
                  controls={false}
                />
              </Field>
              <div className='absolute left-[270px] pt-1'>円</div>
            </>
          )}
        </div>
        <Field label='URL' name='url'>
          <Input placeholder='URL' maxLength={200} disabled={isDisabled} />
        </Field>
        <Field label='イベント概要' name='content' required>
          <TextArea
            placeholder='イベント概要'
            rows={3}
            maxLength={500}
            disabled={isDisabled}
          />
        </Field>
        {!attachments?.fileList?.length && !imageBanner && (
          <Field
            label='バナー画像'
            name='attachments'
            className='h-[80px]'
            required
          >
            <div className='border-solid rounded border-2 border-zinc-300  w-[200px] h-[100px] flex justify-center items-center'>
              <Upload
                customRequest={() => {}}
                disabled={isDisabled}
                listType='text'
                maxCount={1}
                beforeUpload={beforeUpload}
                showUploadList={false}
                onChange={handleUpload}
              >
                <Button
                  disabled={isDisabled}
                  type='default'
                  shape='default'
                  size='middle'
                  className='text-[#84ab42] border border-[#84ab42] font-semibold'
                >
                  画像を選択
                </Button>
              </Upload>
            </div>
          </Field>
        )}
        {imageBanner && openDrawer && !isLoading && (
          <Field
            label='バナー画像'
            name='imageBanner'
            className='h-[80px]'
            required
          >
            <div className='relative inline-block'>
              <Image
                height={100}
                src={imageBanner}
                className='block rounded-lg'
                fallback={FALLBACK_URL}
              />
              {!isDisabled && (
                <Button
                  type='primary'
                  shape='circle'
                  icon={<CloseOutlined />}
                  size='small'
                  onClick={handleRemoveImage}
                  className='absolute -top-2 -right-2 z-10 bg-red-500 border-none'
                />
              )}
            </div>
          </Field>
        )}
        <Field name='attachment'>
          <div></div>
        </Field>
        <label className='title-calendar font-semibold'>
          配信日時
          <Badge status='error' className='ml-2' />
        </label>
        <div className='flex items-baseline	gap-1 pt-2'>
          <Field name='publicAt' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('publicAt', e ? (e as Moment).second(0) : undefined);
                trigger('eventFrom');
                trigger('publicAt');
              }}
              disabled={isDisabled}
            />
          </Field>
        </div>
        <label className='title-calendar font-semibold '>
          配信範囲
          <Badge status='error' className='ml-2 mb-1' />
        </label>
        <Field
          name='postCodeType'
          className={`w-full ${!!postCodeType && 'mb-1'} `}
        >
          <RadioGroup options={postCodeTypeOptions} disabled={isDisabled} />
        </Field>
        {!!postCodeType && (
          <>
            <div className='flex items-baseline'>
              <Field name='zipCode' className='mb-0'>
                <Input
                  className='w-[200px] mr-1 bg-transparent hover:bg-transparent'
                  disabled={isDisabled}
                  onKeyPress={handleKeyPressInputPostCode}
                  onPaste={handlePasteInputPostCode}
                  onBlur={onBlurInput}
                />
              </Field>
              <Button
                className='absolute left-[210px] pt-1'
                disabled={isDisabled}
                type='primary'
                size='middle'
                onClick={() => handleCheckZipCode(zipCode)}
                loading={isLoadingCheckZipCode}
              >
                住所検索
              </Button>
            </div>
            <Field name='note' className='mb-1'>
              <span>※ハイフンなし半角で入力してください</span>
            </Field>
            <Field name='address1' className='mb-1'>
              <Input
                placeholder='-'
                maxLength={200}
                disabled={true}
                style={{ color: 'black' }}
              />
            </Field>
            <Field name='address2'>
              <Input
                placeholder='-'
                maxLength={200}
                disabled={true}
                style={{ color: 'black' }}
              />
            </Field>
          </>
        )}

        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={eventStatus} />
        </Field>
      </Spin>
    </Form>
  );
};

import { Button, Field, Input, Spin, TextArea } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PostFukumaruService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import { IUpdatePostFukumaruRequest } from '@/types/request';
import { IGetDetailPostFukumaruResponse } from '@/types/response';
import {
  NOTIFY_STATUS,
  handleErrorCommon,
  modulesReactQuill,
  notificationMessage,
} from '@/utils';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import {
  CheckCircleOutlined,
  CloseOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Image, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  formSchemaFn,
  isRenderHTML,
  MAX_LENGTH_EDITOR,
  MAX_LENGTH_NO_EDITOR,
} from './formSchemaFn';
import moment from 'moment';
import {
  FALLBACK_URL,
  FILE_SIZE_LIMIT,
  ImageService,
  SUPPORTED_EXTENSION,
  SUPPORTED_FORMATS,
} from '@/services/image';
import { IUploadImageRequest } from '@/types/request/upload';
import ReactQuill from 'react-quill';
import { PostType } from '../PostFukumaruList';

interface IProps {
  title?: string;
  setIsEditDisplay: (display: boolean) => void;
}

interface IPayloadUpdate {
  id: number;
  body: {
    content: string;
    title: string;
    image?: string;
  };
}

export const UpdatePostFukumaru: React.FC<IProps> = ({
  title,
  setIsEditDisplay,
}) => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const { handleSubmit, reset, watch, setValue, setError, clearErrors } =
    methods;
  const imageCover = watch('imageCover');
  const attachments = watch('attachments');
  const type = watch('type');

  const {
    data: postDetail,
    isFetching,
    isLoading,
  } = useQuery(
    [ENDPOINT.POST_FUKUMARU.GET_DETAIL.replace(':id', postId!)],
    () => PostFukumaruService.getDetail(Number(postId)),
    {
      onSuccess: (response: IResponse<IGetDetailPostFukumaruResponse>) => {
        const data = response?.data;
        reset({
          title: data.title,
          content: data.content,
          publicAt: moment(data?.publicAt).format('YYYY-MM-DD HH:mm:ss') || '',
          imageCover: data?.imageCover?.url,
          type: data?.type,
        });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.POST_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdatePostFukumaruRequest; id: number }) =>
      PostFukumaruService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
            SUCCESS.UPDATE_FUKUMARU as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        setIsEditDisplay(false);
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });
      },
    },
  );

  const { mutate: upload } = useMutation(
    (body: IUploadImageRequest) => ImageService.upload(body),
    {
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  const handleSave = async (values: any) => {
    const payload: IPayloadUpdate = {
      id: Number(postId),
      body: {
        title: values.title,
        content: isRenderHTML(type)
          ? applyCssToLinks(values.content)
          : values.content,
      },
    };

    if (values?.imageCover !== postDetail?.data?.imageCover?.url) {
      const response = await ImageService.getPresignedUrl(1);
      await upload({
        url: response?.data?.urls[0]?.signedUrl,
        file: values?.attachments?.fileList[0]?.originFileObj,
      });
      payload.body.image = response?.data?.urls[0]?.returnUrl;
    }

    update(payload);
  };

  const applyCssToLinks = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    doc.querySelectorAll('a').forEach((link) => {
      link.style.textDecoration = 'underline';
      link.style.textDecorationColor = 'rgb(34, 185, 218)';
    });

    return doc.body.innerHTML;
  };

  const handleRemoveImage = () => {
    setValue('imageCover', null);
    setValue('attachments', null);
  };

  const beforeUpload = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > FILE_SIZE_LIMIT) {
      setError('attachment', {
        type: 'validate',
        message: '画像のサイズは10MBを超えています。',
      });
      return Upload.LIST_IGNORE;
    }

    if (
      !SUPPORTED_EXTENSION.includes(fileExtension || '') ||
      !SUPPORTED_FORMATS.includes(file.type)
    ) {
      setError('attachment', {
        type: 'validate',
        message: '画像は .jpg、.png、.jpeg 形式ではありません。',
      });
      return Upload.LIST_IGNORE;
    }
    clearErrors('attachment');

    return true;
  };

  const handleUpload = (info: any) => {
    const file = info.file.originFileObj || info.file;
    const reader = new FileReader();
    reader.onload = () => {
      setValue('imageCover', reader.result as string);
      setValue('attachments', info);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className='flex flex-col items-center mx-auto w-full md:w-[700px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-full'>
        <Spin spinning={isFetching}>
          <FormProvider {...methods}>
            {!isFetching && (
              <>
                <Form
                  id='user-form'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                  className='flex flex-col justify-center w-full border-block p-3 mt-3'
                  onFinish={handleSubmit(handleSave)}
                  layout='vertical'
                >
                  <Field label='タイトル' name='title' required>
                    <Input placeholder='タイトル' />
                  </Field>
                  {!attachments?.fileList?.length && !imageCover && (
                    <Field
                      label='バナー画像'
                      name='attachments'
                      className='h-[80px]'
                      required
                    >
                      <div className='border-solid rounded border-2 border-zinc-300  w-[200px] h-[100px] flex justify-center items-center'>
                        <Upload
                          customRequest={() => { }}
                          listType='text'
                          maxCount={1}
                          beforeUpload={beforeUpload}
                          showUploadList={false}
                          onChange={handleUpload}
                        >
                          <Button
                            type='default'
                            shape='default'
                            size='middle'
                            className='text-[#84ab42] border border-[#84ab42] font-semibold'
                          >
                            画像を選択
                          </Button>
                        </Upload>
                      </div>
                    </Field>
                  )}
                  {imageCover && !isLoading && (
                    <Field
                      label='バナー画像'
                      name='imageCover'
                      className='h-[80px]'
                      required
                    >
                      <div className='relative inline-block'>
                        <Image
                          height={100}
                          src={imageCover}
                          className='block rounded-lg'
                          fallback={FALLBACK_URL}
                        />
                        <Button
                          type='primary'
                          shape='circle'
                          icon={<CloseOutlined />}
                          size='small'
                          onClick={handleRemoveImage}
                          className='absolute -top-2 -right-2 z-10 bg-red-500 border-none'
                        />
                      </div>
                    </Field>
                  )}
                  <Field name='attachment'>
                    <div></div>
                  </Field>
                  {isRenderHTML(type) ? (
                    <Field label='コンテンツ' name='content' required>
                      <ReactQuill theme='snow' modules={modulesReactQuill} />
                    </Field>
                  ) : (
                    <Field label='コンテンツ' name='content' required>
                      <TextArea
                        placeholder='コンテンツ'
                        maxLength={
                          isRenderHTML(type)
                            ? MAX_LENGTH_EDITOR
                            : MAX_LENGTH_NO_EDITOR
                        }
                        rows={6}
                      />
                    </Field>
                  )}
                  <Field label='いつ' name='publicAt'>
                    <Input disabled />
                  </Field>
                </Form>
                <div className='flex w-full gap-2 mt-3'>
                  <Button
                    className='w-1/2'
                    icon={<LeftCircleOutlined />}
                    onClick={() => setIsEditDisplay(false)}
                  >
                    Back
                  </Button>
                  <Button
                    type='primary'
                    className='w-1/2'
                    htmlType='submit'
                    form='user-form'
                    icon={<CheckCircleOutlined />}
                    loading={isLoadingUpdate}
                  >
                    Update
                  </Button>
                </div>
              </>
            )}
          </FormProvider>
        </Spin>
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-login .app-name {
  font-family: "M PLUS Rounded 1c", sans-serif;
  line-height: 0;
  color: var(--color-black);
  opacity: 0.9;
}
.p-login .forgot-password {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/pages/Authentication/Login/style.scss"],"names":[],"mappings":"AACE;EACE,4CAAA;EACA,cAAA;EACA,yBAAA;EACA,YAAA;AAAJ;AAGE;EACE,2BAAA;AADJ","sourcesContent":[".p-login {\r\n  .app-name {\r\n    font-family: 'M PLUS Rounded 1c', sans-serif;\r\n    line-height: 0;\r\n    color: var(--color-black);\r\n    opacity: 0.9;\r\n  }\r\n\r\n  .forgot-password {\r\n    color: var(--color-primary);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FILE_SIZE_LIMIT, SUPPORTED_FORMATS } from '@/services/image';
import * as yup from 'yup';
import { PostType } from '../PostFukumaruList';

export const MAX_LENGTH_NO_EDITOR = 500;
export const MAX_LENGTH_EDITOR = 10000;
export const isRenderHTML = (type: string) =>
  type === PostType.SAFETY_NOTICE || type === PostType.DENTAL_NOTICE;

export const formSchemaFn = () =>
  yup.object().shape({
    title: yup
      .string()
      .required('タイトルは未入力です。')
      .max(100, 'タイトルは100文字以内で入力してください。'),
    content: yup
      .string()
      .required('コンテンツは未入力です。')
      .when('type', ([type], schema) => {
        const maxLength = isRenderHTML(type)
          ? MAX_LENGTH_EDITOR
          : MAX_LENGTH_NO_EDITOR;
        return schema.max(
          maxLength,
          `コンテンツは未入力です。は${maxLength}文字以内で入力してください。`,
        );
      }),
    attachment: yup
      .mixed()
      .when(
        ['attachments', 'imageCover'],
        ([attachments, imageCover], schema) => {
          if (imageCover && (!attachments || !attachments?.fileList?.length)) {
            return schema;
          }
          if (!attachments || !attachments?.fileList?.length) {
            return schema.required('バナー画像をアップロードしてください。');
          }
          return schema
            .test('fileSize', '画像のサイズは10MBを超えています。', (_) => {
              return attachments?.fileList[0]?.size <= FILE_SIZE_LIMIT;
            })
            .test(
              'fileType',
              '画像は .jpg、.png、.jpeg 形式ではありません。',
              (_) => {
                return SUPPORTED_FORMATS.includes(
                  attachments?.fileList[0]?.type,
                );
              },
            );
        },
      ),
  } as any);

import moment from 'moment';
import * as yup from 'yup';

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    name: yup
      .string()
      .required('コンテンツ名が入力されていません。')
      .max(100, 'コンテンツ名は100文字以内で入力してください。'),
    title: yup
      .string()
      .required('コンテンツ見出しが入力されていません。')
      .max(100, 'コンテンツ見出しは100文字以内で入力してください。'),
    contentFrom: yup
      .date()
      .required('開始日時を選択してください。')
      .when(['status'], ([status], schema) => {
        if (!status) {
          return schema;
        }
        return schema.min(
          moment().startOf('minute').toDate(),
          isActionEdit
            ? '配信期間が過去の日時なので、更新できませんでした。'
            : '配信期間が過去の日時なので、作成できませんでした。',
        );
      }),
    contentTo: yup
      .date()
      .required('終了日時を選択してください。')
      .min(
        yup.ref('contentFrom'),
        '終了日時は開始日時より後の日付を選択してください。',
      ),
    status: yup.boolean().required('バナー画像は未入力です。'),
    attachment: yup
      .string()
      .test(
        'check-images',
        'コンテンツ画像をアップロードしてください。',
        (_, context) => {
          const images = context.parent.images || [];
          return images.some((image: any) => image !== null);
        },
      ),
  } as any);

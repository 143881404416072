import { IResponse, IResponsePagination } from '@/types/common';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';
import {
  ICreateNotificationRemindRequest,
  IGetListNotificationRemindRequest,
  IUpdateNotificationRemindRequest,
} from '@/types/request';
import {
  ICreateNotificationRemindResponse,
  IGetDetailNotificationRemindResponse,
  IGetListNotificationRemindResponse,
  IUpdateNotificationRemindResponse,
} from '@/types/response';

class NotificationRemind {
  getList = (
    params: IGetListNotificationRemindRequest,
  ): Promise<IResponsePagination<IGetListNotificationRemindResponse>> => {
    return axiosClient.get(ENDPOINT.NOTIFICATION_REMIND.GET_LIST, {
      params: params,
    });
  };

  getDetail = (
    id: number,
  ): Promise<IResponse<IGetDetailNotificationRemindResponse>> => {
    return axiosClient.get(
      ENDPOINT.NOTIFICATION_REMIND.GET_DETAIL.replace(':id', String(id)),
    );
  };

  create = (
    body: ICreateNotificationRemindRequest,
  ): Promise<IResponse<ICreateNotificationRemindResponse>> => {
    return axiosClient.post(ENDPOINT.NOTIFICATION_REMIND.CREATE, body);
  };

  update = (
    data: IUpdateNotificationRemindRequest,
    id: number,
  ): Promise<IResponse<IUpdateNotificationRemindResponse>> => {
    return axiosClient.put(
      ENDPOINT.NOTIFICATION_REMIND.UPDATE.replace(':id', String(id)),
      data,
    );
  };

  delete = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.NOTIFICATION_REMIND.DELETE.replace(':id', String(id)),
    );
  };
}

export const NotificationRemindService = new NotificationRemind();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-forgot-password .back-btn {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/pages/Authentication/ForgotPassword/style.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ","sourcesContent":[".p-forgot-password {\r\n  .back-btn {\r\n    color: var(--color-primary);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const imageFreeInput = `Based on this information, create an image to post in the family group.
    Input Details:
    {
    "diary": {diary_value}
    "time": {time_value}
    }
    Instructions:
    {instructions}`;

export const textFreeInput = `Based on this information, create a tweet to post in the family group.
    Input Details:
    *Topic: [Provide the topic of the post]
    *Diary: [User provides a short paragraph. Expand this paragraph into a longer one.]
    Input Format:
    {
        "diary": {diary_format}
        "time": {time_format}
    }

    Output Requirements (in Japanese):
    *Title: A title with a maximum of 100 characters, including optional icons.
    *Content: The main content of the tweet, limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
        "title": "string"
        "content": "string"
    }
    Instructions:
    {instructions}`;

export const titleFreeInput = `Based on this information, create a tweet to post in the family group.
    Input Details:
    *Topic: [Provide the topic of the post]
    *Diary: [User provides a short paragraph. Expand this paragraph into a longer one.]
    Input Format:
    {
        "diary": {diary_format}
        "time": {time_format}
    }

    Output Requirements (in Japanese):
    *Title: A title with a maximum of 100 characters, including optional icons.
    *Content: The main content of the tweet, limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
        "title": "string"
    }
    Instructions:
    {instructions}`;

export const contentFreeInput = `Based on this information, create a tweet to post in the family group.
    Input Details:
    *Topic: [Provide the topic of the post]
    *Diary: [User provides a short paragraph. Expand this paragraph into a longer one.]
    Input Format:
    {
        "diary": {diary_format}
        "time": {time_format}
    }

    Output Requirements (in Japanese):
    *Title: A title with a maximum of 100 characters, including optional icons.
    *Content: The main content of the tweet, limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
        "content": "string"

    }
    Instructions:
    {instructions}`;

export const imageEasyInput = `
    Create an engaging and family-friendly diary entry for the family group based on the following details:
    Characters: {audience_value} (Example: Mom)
    Location: {location_value} (Example: The park nearby)
    time: {time_value} (Example: Last week)
    Activity: {activities_value} (Example: Running with a friend)
    Emotion: {emotions_value} (Example: Feeling refreshed)
    Instructions:
     {instructions}`;

export const textEasyInput = `
Based on the information create a tweet to post in the family group.
    Input Details:
    * Audience: [Identify the family members this tweet is aimed at.]
    * Location: [Specify the location to be mentioned in the tweet.]
    * Time: [Time of event.]
    * Activities: [Describe the activities involved.]
    * Emotions: [Express the feelings associated with the activities.]
    Input Format: Please format the output as JSON in the following structure: 
    {  
        "audience": {audience_format},
        "location": {location_format},   
        "time": {time_format},
        "activities": {activities_format},
        "emotions": {emotions_format}
    }

    Output Requirements (in Japanese):
    * Title: A title with a maximum of 100 characters, including optional icons.
    * Content: The main content of the tweet, is limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
        "title": "string"
        "content": "string"
    }

    Instructions:
     {instructions}`;

export const titleEasyInput = `
Based on the information create a tweet to post in the family group.
    Input Details:
    * Audience: [Identify the family members this tweet is aimed at.]
    * Location: [Specify the location to be mentioned in the tweet.]
    * Time: [Time of event.]
    * Activities: [Describe the activities involved.]
    * Emotions: [Express the feelings associated with the activities.]
    Input Format: Please format the output as JSON in the following structure: 
    {  
        "audience": {audience_format},
        "location": {location_format},   
        "time": {time_format},
        "activities": {activities_format},
        "emotions": {emotions_format}
    }

    Output Requirements (in Japanese):
    * Title: A title with a maximum of 100 characters, including optional icons.
    * Content: The main content of the tweet, is limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
         "title": "string"
    }

    Instructions:
    {instructions}`;

export const contentEasyInput = `
Based on the information create a tweet to post in the family group.
    Input Details:
    * Audience: [Identify the family members this tweet is aimed at.]
    * Location: [Specify the location to be mentioned in the tweet.]
    * Time: [Time of event.]
    * Activities: [Describe the activities involved.]
    * Emotions: [Express the feelings associated with the activities.]
    Input Format: Please format the output as JSON in the following structure: 
    {  
        "audience": {audience_format},
        "location": {location_format},   
        "time": {time_format},
        "activities": {activities_format},
        "emotions": {emotions_format}
    }

    Output Requirements (in Japanese):
    * Title: A title with a maximum of 100 characters, including optional icons.
    * Content: The main content of the tweet, is limited to 1000 characters (minimum 300 characters).You are required to try to create the minimum number of characters.

    Output Format: Make sure format the output as JSON in the following structure: 
    {
        "content": "string"
    }

    Instructions:
    {instructions}`;

import { IPromptKey } from '@/types/response';
import * as yup from 'yup';
import { FORM } from './constants';

export const formSchemaFn = (formName: FORM, promptKeys: IPromptKey[]) => {
  const promptKeysSchema = promptKeys?.reduce((schema, promptKey) => {
    return {
      ...schema,
      [promptKey.key]: yup.string().required(`Please input ${promptKey.key}`),
    };
  }, {});

  return yup.object().shape({
    groupId: yup.number().required('Please select group'),
    ...promptKeysSchema,
    title: yup.string().max(100, 'Length must not exceed 100 characters'),
    description: yup.string().max(500, 'Length must not exceed 500 characters'),
    instructions: yup.string().required('Please input Instructions'),
  });
};

import {
  Button,
  DeleteContent,
  FieldReadOnly,
  Spin,
} from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PostFukumaruService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError } from '@/types/common';
import { ERROR, SUCCESS, SUCCESS_MESSAGE } from '@/utils/constants/messages';
import {
  DeleteOutlined,
  EditFilled,
  LeftCircleOutlined,
} from '@ant-design/icons';
import { Form, Image, Tabs } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdatePostFukumaru } from '../UpdatePostFukumaru';
import { CommentList } from '@/pages/Post/CommentList';
import { notificationMessage, notificationModal, NOTIFY_STATUS } from '@/utils';
import { convertTypeAutoGeneratePostText, PostType } from '../PostFukumaruList';
import { FALLBACK_URL } from '@/services/image';

interface IProps {
  title?: string;
}

export const PostFukumaruDetail: React.FC<IProps> = ({ title }) => {
  const { id: postId } = useParams();
  const navigate = useNavigate();
  const [isEditDisplay, setIsEditDisplay] = useState<boolean>(false);

  const { data: postDetail, isFetching } = useQuery(
    [ENDPOINT.POST_FUKUMARU.GET_DETAIL.replace(':id', postId!)],
    () => PostFukumaruService.getDetail(Number(postId)),
    {
      enabled: !isEditDisplay,
      onError: (response: IResponseError) => {
        if (response.error === ERROR.POST_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data = postDetail?.data;

  const { mutate: deletePostFukumaru, isLoading: isLoadingDelete } =
    useMutation((id: number) => PostFukumaruService.delete(id), {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_FUKUMARU as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        navigate(APP_ROUTER.POST_FUKUMARU.LIST);
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: 'Delete Fail',
          type: NOTIFY_STATUS.ERROR,
        });
      },
    });

  const handleDelete = (id: number) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: (
        <DeleteContent
          name='タイトル'
          content={postDetail?.data?.title || String(id)}
        />
      ),
      maskClosable: false,
      onOk: () => deletePostFukumaru(id),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  return (
    <Tabs
      type='card'
      destroyInactiveTabPane={true}
      items={[
        {
          label: `投稿詳細`,
          key: 'Detail',
          children: !isEditDisplay ? (
            <div className='flex flex-col items-center mx-auto w-full md:w-[700px]'>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <div className='w-full'>
                <Spin spinning={isFetching || isLoadingDelete}>
                  {!isFetching && !isLoadingDelete && (
                    <>
                      <header className='flex justify-between w-full md:w-[700px]'>
                        <h3 className='font-semibold text-[20px]'>ふくまる</h3>
                        <div>
                          <Button
                            danger
                            type='primary'
                            className='mr-2'
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(Number(postId))}
                          >
                            削除
                          </Button>
                          <Button
                            type='primary'
                            icon={<EditFilled />}
                            onClick={() => setIsEditDisplay(true)}
                          >
                            変更
                          </Button>
                        </div>
                      </header>
                      <Form
                        labelCol={{ span: 6 }}
                        labelAlign='left'
                        className='flex flex-col justify-center w-full border-block p-3 mt-3'
                      >
                        <FieldReadOnly label='タイトル'>
                          <div className='break-all'>
                            <div className='leading-6'>{data?.title}</div>
                          </div>
                        </FieldReadOnly>
                        <FieldReadOnly label='バナー画像'>
                          {data?.imageCover?.url && (
                            <Image
                              width={112}
                              src={data?.imageCover?.url}
                              fallback={FALLBACK_URL}
                            />
                          )}
                        </FieldReadOnly>
                        <FieldReadOnly label='種類'>
                          <div className='break-all'>
                            <div className='leading-6'>
                              {convertTypeAutoGeneratePostText(
                                data?.type as PostType,
                              )}
                            </div>
                          </div>
                        </FieldReadOnly>
                        <FieldReadOnly label='コンテンツ'>
                          {data?.content && (
                            <div className='leading-6 truncate'>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.content,
                                }}
                              />
                            </div>
                          )}
                        </FieldReadOnly>
                        <FieldReadOnly label='いつ'>
                          {data?.publicAt &&
                            moment(data?.publicAt).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                        </FieldReadOnly>
                      </Form>
                      <div className='w-full flex justify-center mt-3'>
                        <Button
                          className='w-1/2'
                          icon={<LeftCircleOutlined />}
                          onClick={() =>
                            navigate(APP_ROUTER.POST_FUKUMARU.LIST)
                          }
                        >
                          Back
                        </Button>
                      </div>
                    </>
                  )}
                </Spin>
              </div>
            </div>
          ) : (
            <UpdatePostFukumaru
              title='Update Post Fukumaru'
              setIsEditDisplay={setIsEditDisplay}
            />
          ),
        },
        {
          label: 'コメント一覧',
          key: 'Comment',
          children: <CommentList id={postId} />,
        },
      ]}
    />
  );
};

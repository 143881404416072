import { IResponse, IResponsePagination } from '@/types/common';
import { IGetListPostRequest } from '@/types/request';
import { IGetDetailPostResponse, IGetListPostResponse } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class Post {
  getList = (
    params: IGetListPostRequest,
  ): Promise<IResponsePagination<IGetListPostResponse>> => {
    return axiosClient.get(ENDPOINT.POST.GET_LIST, { params: params });
  };

  getDetail = (id: number): Promise<IResponse<IGetDetailPostResponse>> => {
    return axiosClient.get(ENDPOINT.POST.GET_DETAIL.replace(':id', String(id)));
  };

  getListComment = (
    params: IGetListPostRequest,
  ): Promise<IResponsePagination<any>> => {
    return axiosClient.get(ENDPOINT.COMMENT.GET_LIST, { params: params });
  };

  deleteComment = (id: number) => {
    return axiosClient.delete(
      ENDPOINT.COMMENT.DELETE.replace(':id', String(id)),
    );
  };
  
}

export const PostService = new Post();

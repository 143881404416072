import { NOTIFY_STATUS } from '@/utils/constants';
import { notificationMessage } from '@/utils/helpers';
import { deleteCookie, getCookie } from '@/utils/helpers/storage';
import axios from 'axios';

const APP_API_URL = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL: APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(async (config: any) => {
  const customHeaders = {
    Authorization: '',
  };

  const token = getCookie('accessToken') ? getCookie('accessToken') : '';

  if (token) {
    customHeaders.Authorization = `Bearer ${token}`;
  }

  return {
    ...config,
    headers: {
      ...customHeaders, // auto attach token
      ...config.headers, // but you can override for some requests
    },
  };
});

axiosClient.interceptors.response.use(
  (response) => {
    return response?.data;
  },

  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    if (config?.enableNotificationMessage) {
      notificationMessage({
        message: 'Bad Request',
        type: NOTIFY_STATUS.ERROR,
      });
    }

    if (status === 401) {
      deleteCookie('accessToken');
      window.location.href = '/login';
    } else if (error.response.status === 403) {
      window.location.href = '/403';
    } else if (error.response.status === 429) {
      notificationMessage({
        message: 'Too Many Requests',
        type: NOTIFY_STATUS.ERROR,
      });
    } else {
      throw {
        ...error.response.data,
        status: error.response.status,
      };
    }
  },
);

export default axiosClient;

import { Button, Field, Input, InputPassword } from '@/components/commons';
import { useAuth } from '@/providers/Auth';
import { APP_ROUTER } from '@/routes/routes';
import { AuthService, UserService } from '@/services';
import { IResponse, IResponseError } from '@/types/common';
import { ILoginRequest } from '@/types/request';
import { IGetMeResponse, ILoginResponse } from '@/types/response';
import { handleErrorCommon, notificationMessage, NOTIFY_STATUS } from '@/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formSchemaFn } from './formSchemaFn';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import './style.scss';

interface IProps {
  title?: string;
}

export const Login: React.FC<IProps> = ({ title }) => {
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const navigate = useNavigate();
  const { logInSuccess, setCurrentUser } = useAuth();

  const { mutate: mutateLogin, isLoading } = useMutation(
    (body: ILoginRequest) => AuthService.login(body),
    {
      onSuccess: (response: IResponse<ILoginResponse>) => {
        logInSuccess(response.data);
        getMe();
      },
      onError: (error: IResponseError) => {
        if (error?.error === ERROR.INCORRECT_LOGIN) {
          notificationMessage({
            message: ERROR_MESSAGE.INCORRECT_LOGIN,
            type: NOTIFY_STATUS.ERROR,
          });
        } else {
          handleErrorCommon(error, setError);
        }
      },
    },
  );

  const { mutate: getMe } = useMutation(() => UserService.getMe(), {
    onSuccess: (response: IResponse<IGetMeResponse>) => {
      setCurrentUser(response.data);
      navigate(APP_ROUTER.HOME);
    },
  });

  const handleLogin = (values: ILoginRequest) => {
    mutateLogin(values);
  };

  const { handleSubmit, setError } = methods;

  return (
    <div className='p-login flex flex-col items-center justify-center h-screen'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='w-[396px] flex justify-center flex-col'>
        <div className='flex gap-[8px] items-center justify-center'>
          <div className='w-[50px] h-[50px]'>
            <img src='/logo.svg' alt='' />
          </div>
          <span className='app-name text-[30px] font-bold'>ヒビノエ</span>
        </div>
        <FormProvider {...methods}>
          <Form
            className='mt-[30px] flex flex-col items-center'
            layout='vertical'
            onFinish={handleSubmit(handleLogin)}
          >
            <Field name='email' label='メールアドレス' className='w-full'>
              <Input placeholder='' />
            </Field>
            <Field name='password' label='パスワード' className='w-full'>
              <InputPassword placeholder='' />
            </Field>
            <Button
              type='primary'
              className='w-[316px] font-semibold'
              htmlType='submit'
            >
              ログイン
            </Button>
            <Button
              className='forgot-password font-semibold w-[316px]'
              type='link'
              loading={isLoading}
              onClick={() => navigate(APP_ROUTER.AUTH.FORGOT_PASSWORD)}
            >
              パスワードを忘れた方はこちら
            </Button>
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};

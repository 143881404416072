// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-empty table {
  min-height: 605px;
}
.table-empty .ant-table-container .ant-table-content {
  height: 100%;
  overflow-x: hidden !important;
}

.ant-table-wrapper {
  height: 100%;
  overflow: hidden;
}
.ant-table-wrapper .ant-spin-nested-loading {
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  min-height: 605px;
}

.table-common .ant-table-content {
  min-height: 605px;
  border-radius: 8px;
  border: 1px solid var(--color-border);
}

.table-common .ant-table-thead > tr > th {
  border-bottom: 1px solid var(--color-border);
}

.table-common .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--color-border);
}

.table-common .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/commons/Table/style.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,YAAA;EACA,6BAAA;AAAJ;;AAIA;EACE,YAAA;EACA,gBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AADR;AAGQ;EACI,iBAAA;AADZ;;AAOA;EACE,iBAAA;EACA,kBAAA;EACA,qCAAA;AAJF;;AAOA;EACE,4CAAA;AAJF;;AAOA;EACE,4CAAA;AAJF;;AAOA;EACE,mBAAA;AAJF","sourcesContent":[".table-empty {\n  table {\n    min-height: 605px;\n  }\n  .ant-table-container .ant-table-content{\n    height: 100%;\n    overflow-x: hidden !important;\n  }\n}\n\n.ant-table-wrapper {\n  height: 100%;\n  overflow: hidden;\n\n  .ant-spin-nested-loading {\n    height: 100%;\n\n    .ant-spin-container {\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        height: 100%;\n        \n        .ant-table {\n            min-height: 605px;\n        }\n    }\n  }\n}\n\n.table-common .ant-table-content {\n  min-height: 605px;\n  border-radius: 8px;\n  border: 1px solid var(--color-border);\n}\n\n.table-common .ant-table-thead > tr > th {\n  border-bottom: 1px solid var(--color-border);\n}\n\n.table-common .ant-table-tbody > tr > td {\n  border-bottom: 1px solid var(--color-border);\n}\n\n.table-common .ant-table-tbody > tr:last-child > td {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

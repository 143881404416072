import { Drawer, Pagination, Table } from '@/components/commons';
import { DeleteContent } from '@/components/commons/DeleteContent';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
  surveyStatus,
} from '@/utils';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { formSchemaFn } from '../Drawers/SurveyForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/SurveyForm/constants';
import { EventService } from '@/services/event';
import { ICreateSurveyRequest, IUpdateSurveyRequest } from '@/types/request';
import { IGetDetailSurveyResponse } from '@/types/response';
import { SurveyForm } from '../Drawers';
import { SurveyService } from '@/services/survey';

interface IProps {
  title?: string;
}

interface IParams {
  limit: number;
  page: number;
}

export const SurveyList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [detail, setDetail] = useState<IGetDetailSurveyResponse>();
  const [isNotPermissionEdit, setIsNotPermissionEdit] =
    useState<boolean>(false);
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [params, setParams] = useState<IParams>({
    limit: 10,
    page: 1,
  });

  const { data, refetch, isFetching } = useQuery(
    [ENDPOINT.SURVEY.GET_LIST, params],
    () => SurveyService.getList(convertRequest(params)),
  );

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(!!currentId, isNotPermissionEdit)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, watch, setValue, setError, clearErrors } =
    methods;

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    setDetail(undefined);
    reset({});
  };

  const handleCreate = async (values: ICreateSurveyRequest) => {
    const { ...rest } = values;
    const payload: ICreateSurveyRequest = {
      ...rest,
      status: values?.status ? 1 : 0,
      zipCode: !values?.postCodeType ? null : values.zipCode,
    };
    await createSurvey(payload);
  };

  const { mutate: createSurvey, isLoading: isLoadingCreate } = useMutation(
    (body: ICreateSurveyRequest) => SurveyService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.CREATE_SURVEY as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (response: IResponseError) => {
        if (response.message === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
        } else {
          handleErrorCommon(response, setError);
        }
      },
    },
  );

  const { mutate: checkZipCode, isLoading: isLoadingCheckZipCode } =
    useMutation((zipCode: string) => EventService.checkZipCode(zipCode), {
      onSuccess: (response: IResponse<any>) => {
        const { data } = response;
        clearErrors('zipCode');
        setValue('preZipCode', data?.zipCode);
        setValue('address1', data?.address1);
        setValue('address2', data?.address2);
        setValue('keepCheckZipCodeError', false);
      },
      onError: (error: IResponseError) => {
        if (error.error === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
          setValue('keepCheckZipCodeError', true);
          setValue('address1', '');
          setValue('address2', '');
        } else {
          handleErrorCommon(error, setError);
        }
      },
    });

  const { isFetching: isFetchingDetail, refetch: refetchDetail } = useQuery(
    [ENDPOINT.SURVEY.GET_DETAIL.replace(':id', String(currentId))],
    () => SurveyService.getDetail(currentId!),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailSurveyResponse>) => {
        const data = response?.data;
        setDetail(data);
        const payload: IUpdateSurveyRequest = {
          name: data?.name,
          surveyFrom: moment(data?.surveyFrom),
          surveyTo: moment(data?.surveyTo),
          instructions: data?.instructions,
          ...(data?.remindNotificationAt && {
            remindNotificationAt: moment(data?.remindNotificationAt),
          }),
          status: data?.status,
          postCodeType: data?.japanPost ? 1 : 0,
          zipCode: data?.japanPost?.zipCode || '',
          preZipCode: data?.japanPost?.zipCode || '',
          address1: data?.japanPost?.address1 || '',
          address2: data?.japanPost?.address2 || '',
          isDisabled: !!data?.surveyRooms?.length,
          isEnableEdit: !data?.surveyRooms?.length,
        };
        reset({
          formName: FORM_NAME.UPDATE_SURVEY,
          ...payload,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const handleUpdate = async (values: IUpdateSurveyRequest) => {
    const { ...rest } = values;
    const payload: IUpdateSurveyRequest = {
      ...rest,
      status: values?.status ? 1 : 0,
      zipCode: !values.postCodeType ? null : values.zipCode,
    };
    try {
      delete payload.formName;
      delete payload.isDisabled;
      delete payload.postCodeType;
      delete payload.address1;
      delete payload.address2;
      delete payload.preZipCode;
      delete payload.keepCheckZipCodeError;
      await updateSurvey({ body: payload, id: Number(currentId) });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDelete = (data: IGetDetailSurveyResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='アンケート名' content={data?.name} />,
      maskClosable: false,
      onOk: () => deleteSurvey(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  const { mutate: updateSurvey, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdateSurveyRequest; id: number }) =>
      SurveyService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.UPDATE_SURVEY as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        if (error.error === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
        } else {
          handleErrorCommon(error, setError);
        }

        if (error.error === ERROR.CANT_EDIT_SURVEY_HAVE_PEOPLE_JOIN) {
          refetchDetail();
        }

        if (error.error === ERROR.SURVEY_NOT_FOUND) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteSurvey, isLoading: isLoadingDelete } = useMutation(
    (id: number) => SurveyService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_SURVEY as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.SURVEY_RELOAD_DATA) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const columns: TableColumnsType = [
    {
      title: 'アンケート名',
      dataIndex: 'name',
      width: '500px',
    },
    {
      title: '回答期間',
      render: (value, record) => {
        return `${moment(record.surveyFrom).format('YYYY年MM月DD日 HH:mm')}～${moment(record.surveyTo).format('YYYY年MM月DD日 HH:mm')}`;
      },
    },
    {
      title: 'ステータス',
      render: (value, record) => {
        if (!record.status) {
          return surveyStatus[1].label;
        }
        const isPublished = record?.pushNotificationAt;
        return isPublished ? '配信中 ' : '配信予約済み';
      },
    },
  ];

  const isDisabled = watch('isDisabled');

  useEffect(() => {
    setIsNotPermissionEdit(isDisabled);

    if (isNotFoundDetail) {
      setOpenDrawer(false);
      refetch();
    }
  }, [isDisabled, isNotFoundDetail]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px] h-full'>
        <header className='text-right'>
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({}),
                setValue('status', 0),
                setIsNotFoundDetail(false);
            }}
          >
            アンケート新規作成
          </Button>
        </header>

        <div className='flex justify-between flex-col'>
          <Table
            loading={isFetching}
            scroll={{ x: 800 }}
            bordered
            rowKey='id'
            columns={columns}
            dataSource={data?.data.data}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setOpenDrawer(true);
                setCurrentId(record.id);
                setIsNotFoundDetail(false);
              },
            })}
            locale={{
              emptyText: 'アンケートはまだありません',
            }}
          />

          {data?.data?.pagination?.total! > 10 && (
            <Pagination
              className='pagination-table'
              total={data?.data.pagination.total}
              pageSize={params.limit}
              current={params.page}
              showSizeChanger={false}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>

      <Drawer
        title={!currentId ? 'アンケート新規作成' : 'アンケート詳細'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          isLoadingUpdate ||
          isLoadingDelete ||
          isFetchingDetail ||
          isLoadingCreate
        }
        isDeleted={!!currentId}
        // disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <SurveyForm
            isLoadingCheckZipCode={isLoadingCheckZipCode}
            isLoading={
              isFetchingDetail ||
              isLoadingUpdate ||
              isLoadingCreate ||
              isLoadingDelete
            }
            openDrawer={openDrawer}
            checkZipCode={checkZipCode}
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

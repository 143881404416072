import { Button, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { EventService, UserService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponse, IResponseError } from '@/types/common';
import { IUpdateUserRequest } from '@/types/request';
import { IGetDetailUserResponse } from '@/types/response';
import {
  NOTIFY_STATUS,
  handleErrorCommon,
  notificationMessage,
  userStatus,
} from '@/utils';
import { ERROR, ERROR_MESSAGE } from '@/utils/constants/messages';
import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { UserForm } from '../Drawers';
import { ImageService } from '@/services/image';
import moment from 'moment';
import { IUploadImageRequest } from '@/types/request/upload';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchemaFn } from '../Drawers/UserForm/formSchemaFn';

interface IProps {
  title?: string;
}

export const UserUpdatePage: React.FC<IProps> = ({ title }) => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn(true, true)),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, setError, clearErrors, setValue } = methods;

  const {
    data: userDetail,
    refetch: refetchUserDetail,
    isFetching: isLoadingGetDetailUser,
  } = useQuery(
    [ENDPOINT.USER.GET_DETAIL.replace(':id', userId!)],
    () => UserService.getDetail(Number(userId)),
    {
      onSuccess: (response: IResponse<IGetDetailUserResponse>) => {
        const data = response?.data;
        reset({
          id: data?.id,
          email: data?.email,
          isVerified: data?.isVerified ? 'true' : 'false',
          status: userStatus[data?.status as keyof typeof userStatus],
          nickname: data?.profile?.nickname,
          gender: data?.profile?.gender,
          dayOfBirth: moment(data?.profile?.dayOfBirth),
          avatar: data?.profile?.avatar?.url,
          zipCode: data?.profile?.japanPostId?.zipCode,
          preZipCode: data?.profile?.japanPostId?.zipCode || '',
          address1: data?.profile?.japanPostId?.address1,
          address2: data?.profile?.japanPostId?.address2,
          lastLoginAt: moment(data?.lastLoginAt).format('YYYY-MM-DD HH:mm:ss'),
          lastOnlineAt: moment(data?.lastOnlineAt).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          expiredCodeRemoveAt: moment(data?.expiredCodeRemoveAt).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          createdAt: moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          deletedAt: data?.deletedAt
            ? moment(data?.deletedAt).format('YYYY-MM-DD HH:mm:ss')
            : '',
        });
      },
      onError: (response: IResponseError) => {
        if (response.error === ERROR.USER_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const { mutate: update, isLoading: isLoadingUpdateUser } = useMutation(
    (payload: { body: IUpdateUserRequest; id: number }) =>
      UserService.update(payload.body, payload.id),
    {
      onSuccess: () => {
        setIsUpdated(true);
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
      },
    },
  );

  const handleSave = async (values: IUpdateUserRequest) => {
    const { status, gender, nickname, dayOfBirth, zipCode } =
      values;
    const payload: IUpdateUserRequest = {
      status: status? 'active' : 'deaActive',
      gender,
      nickname,
      avatar: null,
      dayOfBirth: moment(dayOfBirth).format('YYYY-MM-DD'),
      zipCode,
    };
    try {
      if (!values?.attachments?.fileList?.length) {
        await update({ body: payload, id: Number(userId) });
        return;
      }
      const response = await ImageService.getPresignedUrl(1);
      await upload({
        url: response?.data?.urls[0]?.signedUrl,
        file: values?.attachments?.fileList[0]?.originFileObj,
      });
      payload.avatar = response?.data?.urls[0]?.returnUrl;
      if (!isLoadingUpload) {
        await update({ body: payload, id: Number(userId) });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const { mutate: upload, isLoading: isLoadingUpload } = useMutation(
    (body: IUploadImageRequest) => ImageService.upload(body),
    {
      onError: (response: IResponseError) => {
        handleErrorCommon(response, setError);
      },
    },
  );

  const { mutate: checkZipCode, isLoading: isLoadingCheckZipCode } =
    useMutation((zipCode: string) => EventService.checkZipCode(zipCode), {
      onSuccess: (response: IResponse<any>) => {
        const { data } = response;
        clearErrors('zipCode');
        setValue('preZipCode', data?.zipCode);
        setValue('address1', data?.address1);
        setValue('address2', data?.address2);
      },
      onError: (error: IResponseError) => {
        if (error.error === ERROR.ZIP_CODE_NOT_FOUND) {
          setError('zipCode', {
            type: 'validate',
            message: ERROR_MESSAGE.ZIP_CODE_NOT_FOUND,
          });
        } else {
          handleErrorCommon(error, setError);
        }
      },
    });

  useEffect(() => {
    if (isUpdated && !isLoadingUpload) {
      notificationMessage({
        message: 'Success',
        type: NOTIFY_STATUS.SUCCESS,
      });
      navigate(APP_ROUTER.USER.DETAIL.replace(':id', userId!));
    }
  }, [isUpdated, isLoadingUpload]);

  return (
    <div className='mx-auto w-full md:w-[400px]'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Spin spinning={isLoadingGetDetailUser}>
        {!isLoadingGetDetailUser && (
          <FormProvider {...methods}>
            <header className='flex w-full md:w-[400px]'>
              <h3 className='font-semibold text-[20px]'>Update User</h3>
            </header>
            <div className='border-block p-3 mt-3'>
              <UserForm
                isLoading={
                  isLoadingUpdateUser ||
                  isLoadingUpload ||
                  isLoadingGetDetailUser
                }
                openDrawer={true}
                checkZipCode={checkZipCode}
                isLoadingCheckZipCode={isLoadingCheckZipCode}
              />
            </div>
            <div className='flex w-full gap-2 mt-3'>
              <Button
                className='w-1/2'
                icon={<LeftCircleOutlined />}
                onClick={() =>
                  navigate(APP_ROUTER.USER.DETAIL.replace(':id', userId!))
                }
              >
                Back
              </Button>
              <Button
                type='primary'
                className='w-1/2'
                icon={<CheckCircleOutlined />}
                loading={isLoadingUpdateUser}
                onClick={handleSubmit(handleSave)}
              >
                Update
              </Button>
            </div>
          </FormProvider>
        )}
      </Spin>
    </div>
  );
};

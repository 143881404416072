import { Button, FieldReadOnly, Spin } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PostService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IResponseError } from '@/types/common';
import {
  DISPLAY_MODE,
  postStatus,
  postStatusColors,
  postTypeColors,
  postTypes,
} from '@/utils';
import { ERROR } from '@/utils/constants/messages';
import { LeftCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Badge, Form, Image, Tabs, Tag } from 'antd';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CommentList } from '../CommentList';

interface IProps {
  title?: string;
}

export const PostDetail: React.FC<IProps> = ({ title }) => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const { data: postDetail, isFetching } = useQuery(
    [ENDPOINT.POST.GET_DETAIL.replace(':id', postId!)],
    () => PostService.getDetail(Number(postId)),
    {
      onError: (response: IResponseError) => {
        if (response.error === ERROR.POST_NOT_FOUND) {
          navigate(APP_ROUTER.NOT_FOUND);
        }
      },
    },
  );

  const data = postDetail?.data;

  return (
    <Tabs
      type='card'
      items={[
        {
          label: `投稿詳細`,
          key: 'Detail',
          children: (
            <div className='flex flex-col items-center mx-auto w-full md:w-[700px]'>
              <Helmet>
                <title>{title}</title>
              </Helmet>

              <div className='w-full'>
                <Spin spinning={isFetching}>
                  {!isFetching && (
                    <>
                      <header className='flex justify-between w-full md:w-[700px]'>
                        <h3 className='font-semibold text-[20px]'>
                          Post Detail
                        </h3>
                        <Badge
                          {...(postDetail?.data?.countPostReport && {
                            count: postDetail?.data?.countPostReport,
                          })}
                          className='mr-3'
                        >
                          <Button
                            danger={true}
                            type='primary'
                            icon={<WarningOutlined />}
                            onClick={() =>
                              navigate(
                                APP_ROUTER.POST.LIST +
                                  `?postId=${postId}&mode=${DISPLAY_MODE.REPORT}`,
                              )
                            }
                          >
                            報告
                          </Button>
                        </Badge>
                      </header>
                      <Form
                        labelCol={{ span: 6 }}
                        labelAlign='left'
                        className='flex flex-col justify-center w-full border-block p-3 mt-3'
                      >
                        <FieldReadOnly label='Title'>
                          <div className='break-all'>{data?.title}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Cover'>
                          {data?.imageCover?.url && (
                            <Image width={112} src={data?.imageCover?.url} />
                          )}
                        </FieldReadOnly>
                        <FieldReadOnly label='Status'>
                          <Tag
                            color={
                              postStatusColors[
                                data?.status as keyof typeof postStatusColors
                              ]
                            }
                          >
                            {
                              postStatus[
                                data?.status as keyof typeof postStatus
                              ]
                            }
                          </Tag>
                        </FieldReadOnly>
                        <FieldReadOnly label='Type'>
                          <Tag
                            color={
                              postTypeColors[
                                data?.type as keyof typeof postTypeColors
                              ]
                            }
                          >
                            {postTypes[data?.type as keyof typeof postTypes]}
                          </Tag>
                        </FieldReadOnly>
                        <FieldReadOnly label='Activities'>
                          <div className='leading-6'>{data?.activities}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Audience'>
                          <div className='leading-6'>{data?.audience}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Emotions'>
                          <div className='leading-6'>{data?.emotions}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Diary'>
                          <div className='leading-6'>{data?.diary}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Location'>
                          <div className='leading-6'>{data?.location}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Content'>
                          <div className='leading-6'>{data?.content}</div>
                        </FieldReadOnly>
                        <FieldReadOnly label='Created By'>
                          {data?.createdBy?.profile?.nickname}
                        </FieldReadOnly>
                        <FieldReadOnly label='Topic'>
                          {data?.topicId?.title}
                        </FieldReadOnly>
                        <FieldReadOnly label='Time'>
                          {data?.time &&
                            moment(data?.time).format('YYYY-MM-DD HH:mm:ss')}
                        </FieldReadOnly>
                        <FieldReadOnly label='Public At'>
                          {data?.publicAt &&
                            moment(data?.publicAt).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                        </FieldReadOnly>
                      </Form>
                      <div className='w-full flex justify-center mt-3'>
                        <Button
                          className='w-1/2'
                          icon={<LeftCircleOutlined />}
                          onClick={() => navigate(APP_ROUTER.POST.LIST)}
                        >
                          Back
                        </Button>
                      </div>
                    </>
                  )}
                </Spin>
              </div>
            </div>
          ),
        },
        {
          label: 'コメント一覧',
          key: 'Comment',
          children: <CommentList id={postId} />,
        },
      ]}
    />
  );
};

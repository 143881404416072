import { Input as InputBase, InputProps } from 'antd';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

interface IProps extends InputProps {
  field?: ControllerRenderProps<any, any>;
}
export const Input: React.FC<IProps> = ({ className, field, ...props }) => {
  const context = useFormContext();
  return (
    <InputBase
      className={className}
      {...field}
      {...props}
      onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) =>
        context?.setValue(props?.name as string, event?.target?.value?.trim())
      }
    />
  );
};

import {
  DatePicker,
  Field,
  Input,
  RadioGroup,
  Spin,
} from '@/components/commons';
import { contentStatus } from '@/utils';
import { Badge, Button, Carousel, Form, Image, Select, Upload } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';
import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  FALLBACK_URL,
  FILE_SIZE_LIMIT,
  SUPPORTED_EXTENSION,
  SUPPORTED_FORMATS,
} from '@/services/image';
import { ISurvey } from '@/types/response';
import { CarouselRef } from 'antd/es/carousel';
import { useRef } from 'react';
import { ContentImage } from '../../ContentList';

interface IProps {
  isLoading: boolean;
  openDrawer: boolean;
  surveyOptions: ISurvey[] | undefined;
}

const contentStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  color: '#fff',
  textAlign: 'center',
  background: '#463e4d',
  paddingTop: '40px',
  paddingBottom: 40,
  minHeight: 150,
  minWidth: 200,
};

export const ContentForm: React.FC<IProps> = ({
  isLoading,
  openDrawer,
  surveyOptions = [],
}) => {
  const { trigger, watch, setValue, setError, clearErrors } = useFormContext();
  const carouselRef = useRef<CarouselRef | null>(null);

  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs().startOf('day'));
  };

  const disabledTime = (current: Dayjs) => {
    if (!current) return {};

    const now = dayjs();
    const selectedDate = dayjs(current);

    if (selectedDate.isBefore(now, 'day')) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    if (selectedDate.isSame(now, 'day')) {
      const disabledHours: number[] = [];
      for (let i = 0; i < now.hour(); i++) {
        disabledHours.push(i);
      }

      const disabledMinutes = (selectedHour: number) => {
        if (selectedHour === now.hour()) {
          const minutes = [];
          for (let i = 0; i <= now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      };

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: disabledMinutes,
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const isDisabled = watch('isDisabled');
  const images: Array<ContentImage | null> = watch('images');

  const goToSlide = (index: number) => {
    const carousel = carouselRef.current;
    if (carousel) {
      setTimeout(() => {
        carousel.goTo(index);
      }, 0);
    }
  };

  const handleAddImage = () => {
    if (images?.length < 6) {
      setValue('images', [...images, null]);
      goToSlide(images.length);
    }
  };

  const handleRemoveImage = (index: number) => {
    const data = [...images];
    data[index] = null;
    setValue('images', data);
  };

  const handleUpload = (file: File, index: number) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new (window.Image as { new (): HTMLImageElement })();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(
            img,
            (img.width - size) / 2,
            (img.height - size) / 2,
            size,
            size,
            0,
            0,
            size,
            size,
          );

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const newFile = new File([blob], `image-${index}.jpeg`, {
                  type: 'image/jpeg',
                });

                const scaledImage = URL.createObjectURL(blob);
                const data = [...images];
                data[index] = {
                  previewUrl: scaledImage,
                  file: newFile,
                };
                setValue('images', data);
              }
            },
            'image/jpeg',
            0.8, // Định dạng và chất lượng (từ 0 đến 1)
          );
        }
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };

  const beforeUpload = (file: File, index: number) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > FILE_SIZE_LIMIT) {
      setError('attachment', {
        type: 'validate',
        message: '画像のサイズは10MBを超えています。',
      });
      return Upload.LIST_IGNORE;
    }

    if (
      !SUPPORTED_EXTENSION.includes(fileExtension || '') ||
      !SUPPORTED_FORMATS.includes(file.type)
    ) {
      setError('attachment', {
        type: 'validate',
        message: '画像は .jpg、.png、.jpeg 形式ではありません。',
      });
      return Upload.LIST_IGNORE;
    }
    clearErrors('attachment');
    handleUpload(file, index);

    return true;
  };

  const handleDeleteImage = (index: number) => {
    if (images?.length !== 1) {
      const data = [...images];
      data.splice(index, 1);
      setValue('images', data);
      goToSlide(index);
    }
  };

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='コンテンツ名' name='name' required>
          <Input
            placeholder='コンテンツ名'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <Field label='コンテンツ見出し' name='title' required>
          <Input
            placeholder='コンテンツ見出し'
            maxLength={100}
            disabled={isDisabled}
          />
        </Field>
        <label className='title-calendar font-semibold'>
          配信期間
          <Badge status='error' className='ml-2' />
        </label>
        <div className='flex items-baseline	gap-1 pt-2'>
          <Field name='contentFrom' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue(
                  'contentFrom',
                  e ? (e as Moment).second(0) : undefined,
                );
                trigger('contentFrom');
                trigger('contentTo');
              }}
              disabled={isDisabled}
            />
          </Field>
          <span>～</span>
          <Field name='contentTo' className='flex-1'>
            <DatePicker
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              showTime
              onCalendarChange={(e) => {
                setValue('contentTo', e ? (e as Moment).second(0) : undefined);
                trigger('contentFrom');
                trigger('contentTo');
              }}
              disabled={isDisabled}
            />
          </Field>
        </div>
        <label className='title-calendar font-semibold '>
          コンテンツ画像
          <Badge status='error' className='ml-2 mb-1' />
        </label>
        {/* <Button
          type='primary'
          shape='default'
          icon={<PlusCircleFilled />}
          size='small'
          className={`ml-3 border-none ${isDisabled && 'hidden'}`}
          onClick={() => setTimeout(() => handleAddImage(), 0)}
        /> */}
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            maxWidth: '800px',
            whiteSpace: 'nowrap',
            minHeight: '150px',
          }}
        >
          {images?.map((value, index) => {
            return !value ? (
              <Badge
                className='mr-4 mt-3'
                key={index}
                count={
                  <Button
                    type='primary'
                    shape='circle'
                    icon={<CloseOutlined />}
                    size='small'
                    className={`bg-red-500 border-none hidden`}
                    onClick={() => handleDeleteImage(index)}
                  />
                }
              >
                <div className='border-solid rounded border-2 border-zinc-300  w-[120px] h-[120px] flex justify-center items-center'>
                  <Upload
                    beforeUpload={(file: File) => beforeUpload(file, index)}
                    customRequest={() => {}}
                    listType='text'
                    maxCount={1}
                    showUploadList={false}
                  >
                    <Button
                      type='default'
                      shape='default'
                      size='middle'
                      className='text-[#84ab42] border border-[#84ab42] font-semibold'
                      disabled={isDisabled}
                    >
                      画像を選択
                    </Button>
                  </Upload>
                </div>
              </Badge>
            ) : (
              <div className='relative inline-block mr-4 mt-3' key={index}>
                <Image
                  width={120}
                  height={120}
                  src={value?.previewUrl}
                  className='block rounded-lg'
                  fallback={FALLBACK_URL}
                />
                <Button
                  type='primary'
                  shape='circle'
                  icon={<CloseOutlined />}
                  size='small'
                  onClick={() => handleRemoveImage(index)}
                  className={`absolute -top-2 -right-2 z-10 bg-red-500 border-none ${isDisabled && 'hidden'}`}
                />
              </div>
            );
          })}
        </div>
        <label className='title-calendar font-medium text-xs'>
          <span className='font-bold mr-1'>※</span>
          1:1の写真（正方形）をアップロードしてください
        </label>
        <Field name='attachment' className='mt-[-34px] absolute'>
          <></>
        </Field>
        <Field name='surveyIds' className='flex-1 mt-5' label='アンケートID'>
          <Select
            allowClear
            style={{ width: '100%' }}
            options={surveyOptions.map((survey) => ({
              value: survey.id,
              label: survey.name,
            }))}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase()) ??
              false
            }
            disabled={isDisabled}
          />
        </Field>
        <Field label='ステータス' name='status' className='w-full'>
          <RadioGroup options={contentStatus} disabled={isDisabled} />
        </Field>
      </Spin>
    </Form>
  );
};

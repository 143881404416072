import { DeleteContent, Input, Pagination, Table } from '@/components/commons';
import { PostService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import {
  convertRequest,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
} from '@/utils';
import { DeleteFilled, SearchOutlined } from '@ant-design/icons';
import { Button, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import {
  ERROR,
  ERROR_MESSAGE,
  SUCCESS,
  SUCCESS_MESSAGE,
} from '@/utils/constants/messages';
import { IResponseError } from '@/types/common';
import { APP_ROUTER } from '@/routes/routes';
import { Link } from 'react-router-dom';
import './style.scss';

interface IProps {
  title?: string;
  id?: string | undefined;
}

interface IParams {
  postId: string | undefined;
  content: string;
  limit: number;
  page: number;
}

export const CommentList: React.FC<IProps> = ({ title, id }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    postId: id,
    content: '',
    limit: 10,
    page: 1,
  });

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isFetching, refetch } = useQuery(
    [ENDPOINT.COMMENT.GET_LIST, params],
    () => PostService.getListComment(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const { mutate: deleteComment } = useMutation(
    (id: number) => PostService.deleteComment(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_COMMENT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        notificationMessage({
          message: ERROR_MESSAGE[error.message as keyof typeof ERROR_MESSAGE],
          type: NOTIFY_STATUS.ERROR,
        });

        if (error.error === ERROR.COMMENT_NOT_FOUND) {
          refetch();
        }
      },
    },
  );

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'ニックネーム',
      dataIndex: 'createdBy',
      width: '70px',
      render: (value) => (
        <Link
          to={APP_ROUTER.USER.DETAIL.replace(':id', String(value?.id))}
          onClick={(e) => e.stopPropagation()}
        >
          {value?.profile?.nickname || ''}
        </Link>
      ),
    },
    {
      title: 'コメント',
      dataIndex: 'content',
      width: '220px',
    },
    {
      title: '投稿日時',
      dataIndex: 'createdAt',
      align: 'center',
      width: '96px',
      render: (value) => value && moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '',
      align: 'center',
      width: '50px',
      fixed: 'right',
      render: (record: any) => (
        <Button
          type='primary'
          icon={<DeleteFilled />}
          danger
          onClick={() =>
            notificationModal({
              type: NOTIFY_STATUS.CONFIRM,
              title: '削除しますか？',
              content: <DeleteContent name='確認' content={record?.content} />,
              maskClosable: false,
              onOk: () => deleteComment(record.id),
              okText: '削除する',
              cancelText: 'キャンセル',
            })
          }
        >
          削除
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        content: search,
        page: 1,
      }));
    }, 500);
  }, [search]);

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <div className='flex justify-start gap-[6px]'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
    </div>
  );
};

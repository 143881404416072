import { onlyHalfSizeRegex } from '@/pages/Event/Drawers/EventForm/formSchemaFn';
import moment from 'moment';
import * as yup from 'yup';

const isTextEditorValid = (content: string) => {
  const strippedContent = content.replace(/<(?!img\b)[^>]*>/g, '').trim();
  return strippedContent.length > 0;
};

export const formSchemaFn = (
  isActionEdit: boolean,
  isNotPermissionEdit: boolean,
) =>
  yup.object().shape({
    title: yup
      .string()
      .required('タイトルは未入力です。')
      .max(100, 'タイトルは100文字以内で入力してください。'),
    message: yup
      .string()
      .test('is-valid-message', '本文は未入力です。', (value) =>
        isTextEditorValid(value || ''),
      )
      .required('本文は未入力です。')
      .max(10000, '本文は10000文字以内で入力してください。'),
    status: yup
      .boolean()
      .required('ステータスは未入力です。')
      .oneOf([true, false], 'ステータスは未入力です。'),
    sentFromAt: yup
      .date()
      .required('開始日時を選択してください。')
      .when('status', ([status], schema) => {
        if ((isActionEdit && isNotPermissionEdit) || !status) {
          return schema;
        }

        return schema.min(
          moment().add(1, 'minute').startOf('minute').toDate(),
          '配信日時が過去の日時になっています。',
        );
      }),
    sentToAt: yup
      .date()
      .required('終了日時を選択してください。')
      .when('sentFromAt', ([sentFromAt], schema) => {
        if (!sentFromAt) {
          return schema;
        }

        return schema.min(
          yup.ref('sentFromAt'),
          '終了日時は開始日時より後の日付を選択してください。',
        );
      }),
    type: yup.string().required('種類は未入力です。'),
    postCodeType: yup.number().required('開催場所は未入力です。'),
    zipCode: yup
      .string()
      .when(
        ['postCodeType', 'preZipCode', 'keepCheckZipCodeError', 'address1'],
        (
          [postCodeType, preZipCode, keepCheckZipCodeError, address1],
          schema,
        ) => {
          if (!postCodeType) {
            return schema;
          }
          return schema
            .required('郵便番号は未入力です。')
            .test(
              'noFullWidth',
              '郵便番号は半角英数字で入力してください。',
              (zipCode) => {
                return !onlyHalfSizeRegex.test(zipCode || '');
              },
            )
            .test('zipCode', '「住所検索」をタップしください', (zipCode) => {
              return (
                keepCheckZipCodeError || (preZipCode === zipCode && address1)
              );
            })
            .test('errorZipCode', 'この番号では検索できません。', (_) => {
              return !keepCheckZipCodeError;
            });
        },
      ),
  } as any);

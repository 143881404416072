import { Field, Input, Spin, TextArea } from '@/components/commons';
import { Form, Image } from 'antd';
import { useFormContext } from 'react-hook-form';

interface IProps {
  isLoading: boolean;
}

export const PostFukumaruForm: React.FC<IProps> = ({
  isLoading,
}) => {
  const { watch } = useFormContext();

  const isDisabled = watch('isDisabled');
  const imageCover = watch('imageCover');

  return (
    <Form layout='vertical'>
      <Spin spinning={isLoading}>
        <Field label='タイトル' name='title'>
          <Input maxLength={100} disabled={isDisabled} />
        </Field>
        {imageCover! && (
          <Field label='写真' name='imageCover'>
            <Image width={112} src={!isLoading && imageCover} />
          </Field>
        )}
        <Field label='本文' name='content'>
          <TextArea rows={3} maxLength={500} disabled={isDisabled} />
        </Field>
        <Field label='家族' name='family'>
          <Input maxLength={100} disabled />
        </Field>
        <Field label='登録日' name='createdAt'>
          <Input maxLength={100} disabled />
        </Field>
      </Spin>
    </Form>
  );
};

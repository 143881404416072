export const ERROR = {
  FORM_VALIDATION: 'FORM_VALIDATION',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  POST_NOT_FOUND: 'POST_NOT_FOUND',
  DAY_NOT_FOUND: 'DAY_NOT_FOUND',
  DAY_EXIST: 'DAY_EXIST',
  NOTIFICATION_NOT_FOUND: 'NOTIFICATION_NOT_FOUND',
  PROMPT_NOT_FOUND: 'PROMPT_NOT_FOUND',
  EMAIL_INVALID: 'EMAIL_INVALID',
  RESET_PASSWORD_INVALID: 'RESET_PASSWORD_INVALID',
  CONFLICT_DATA: 'CONFLICT_DATA',
  EVENT_NOT_FOUND: 'EVENT_NOT_FOUND',
  EVENT_RELOAD_DATA: 'EVENT_RELOAD_DATA',
  COMMENT_NOT_FOUND: 'COMMENT_NOT_FOUND',
  INCORRECT_LOGIN: 'INCORRECT_LOGIN',
  ZIP_CODE_NOT_FOUND: 'ZIP_CODE_NOT_FOUND',
};

export const ERROR_MESSAGE = {
  DAY_EXIST: 'Day already exists.',
  NOTIFICATION_NOT_FOUND: 'お知らせが見つかりませんでした。',
  EMAIL_INVALID: 'Email is not found.',
  RESET_PASSWORD_INVALID: 'Your password reset link has expired.',
  CONFLICT_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  USER_NOT_FOUND: 'ユーザーが見つかりませんでした。',
  DAY_NOT_FOUND: 'SPECIAL_DAY_NOT_FOUND',
  POST_NOT_FOUND: 'POST_NOT_FOUND',
  EVENT_NOT_FOUND: 'イベントが見つかりませんでした。',
  EVENT_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  COMMENT_NOT_FOUND: 'COMMENT_NOT_FOUND',
  INCORRECT_LOGIN: 'Incorrect email or password',
  ZIP_CODE_NOT_FOUND: 'この番号では検索できません。',
};

export const SUCCESS = {
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  CREATE_SPECIALDAY: 'CREATE_SPECIALDAY',
  UPDATE_SPECIALDAY: 'UPDATE_SPECIALDAY',
  DELETE_SPECIALDAY: 'DELETE_SPECIALDAY',
  CREATE_EVENT: 'CREATE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
};

export const SUCCESS_MESSAGE = {
  CREATE_NOTIFICATION: 'お知らせが正常に作成されました。',
  UPDATE_NOTIFICATION: 'お知らせが正常に更新されました。',
  DELETE_NOTIFICATION: 'お知らせが正常に削除されました。',
  CREATE_SPECIALDAY: 'CREATE_SPECIALDAY',
  UPDATE_SPECIALDAY: 'UPDATE_SPECIALDAY',
  DELETE_SPECIALDAY: 'DELETE_SPECIALDAY',
  CREATE_EVENT: 'イベントが正常に新規作成されました。',
  UPDATE_EVENT: 'イベントが正常に更新されました。',
  DELETE_EVENT: 'イベントが正常に削除されました。',
  DELETE_COMMENT: 'コメントを削除しました。',
};

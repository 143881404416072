import { Button, Input, Pagination, Table } from '@/components/commons';
import { APP_ROUTER } from '@/routes/routes';
import { PromptService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import { IGetListUserResponse } from '@/types/response';
import { convertRequest } from '@/utils';
import { EyeFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import './style.scss';

interface IProps {
  title?: string;
}

interface IParams {
  title: string;
  limit: number;
  page: number;
}

export const PromptList: React.FC<IProps> = ({ title }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    title: '',
    limit: 10,
    page: 1,
  });

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  const navigate = useNavigate();

  const { data, isFetching } = useQuery(
    [ENDPOINT.PROMPT.GET_LIST, params],
    () => PromptService.getList(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '220px',
    },
    {
      title: 'Group',
      dataIndex: 'groupId',
      width: '140px',
      render: (promptKeyGroup) => promptKeyGroup?.name,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   width: '140px',
    //   align: 'center',
    //   render: (value) => (
    //     <Tag color={postStatusColors[value as keyof typeof postStatusColors]}>
    //       {postStatus[value as keyof typeof postStatus]}
    //     </Tag>
    //   ),
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align: 'center',
      width: '166px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      align: 'center',
      width: '166px',
      fixed: 'right',
      render: (record: IGetListUserResponse) => (
        <Button
          type='primary'
          icon={<EyeFilled />}
          onClick={() =>
            navigate(APP_ROUTER.PROMPT.DETAIL.replace(':id', String(record.id)))
          }
        >
          Detail
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        title: search,
      }));
    }, 500);
  }, [search]);

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <header className='flex justify-between w-full'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type='primary'
            icon={<PlusCircleFilled />}
            onClick={() => navigate(APP_ROUTER.PROMPT.CREATE)}
          >
            Create
          </Button>
        </header>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
    </div>
  );
};

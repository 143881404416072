import { IResponseList } from '@/types/common';
import { IPromptInputType } from '@/types/response';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class PromptInputType {
  getList = (): Promise<IResponseList<IPromptInputType>> => {
    return axiosClient.get(ENDPOINT.PROMPT_INPUT_TYPE.GET_LIST);
  };
}

export const PromptInputTypeService = new PromptInputType();

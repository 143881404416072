import {
  Button,
  DeleteContent,
  Drawer,
  Input,
  Pagination,
  Table,
} from '@/components/commons';
import { PromptService } from '@/services';
import { ENDPOINT } from '@/services/endpoint';
import {
  IGetDetailPromptResponse,
  IPrompt,
  IPromptKey,
} from '@/types/response';
import {
  convertRequest,
  handleErrorCommon,
  notificationMessage,
  notificationModal,
  NOTIFY_STATUS,
  promptStatusColors,
  promptStatusText,
} from '@/utils';
import { SearchOutlined } from '@ant-design/icons';
import { TableColumnsType, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PromptForm } from '../Drawers';
import { IResponse, IResponseError } from '@/types/common';
import {
  ICreatePromptRequest,
  IUpdatePromptRequest,
  PromptStatus,
} from '@/types/request';
import { formSchemaFn } from '../Drawers/PromptForm/formSchemaFn';
import { FORM_NAME } from '../Drawers/PromptForm/constants';
import { ERROR, SUCCESS, SUCCESS_MESSAGE } from '@/utils/constants/messages';
import { PromptInputTypeService } from '@/services/promptInputType';
import './style.scss';

interface IProps {
  title?: string;
}

interface IParams {
  title: string;
  limit: number;
  page: number;
}

export const PromptList: React.FC<IProps> = ({ title }) => {
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [detail, setDetail] = useState<IPrompt>();
  const [isNotFoundDetail, setIsNotFoundDetail] = useState<boolean>(false);
  const [isLoadDetail, setIsLoadDetail] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    title: '',
    limit: 10,
    page: 1,
  });

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(
      formSchemaFn(
        currentId ? FORM_NAME.UPDATE_PROMPT : FORM_NAME.CREATE_PROMPT,
      ),
    ),
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset, setValue, setError, watch, clearErrors } =
    methods;

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const { data, isFetching, refetch } = useQuery(
    [ENDPOINT.PROMPT.GET_LIST, params],
    () => PromptService.getList(convertRequest(params)),
  );

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, limit: pageSize });
  };

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      align: 'center',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '300px',
    },
    {
      title: '機能',
      dataIndex: 'promptInputTypeId',
      width: '220px',
      render: (promptInputTypeId) => promptInputTypeId?.name,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: '70px',
      align: 'center',
      render: (value) => (
        <Tag
          color={promptStatusColors[value as keyof typeof promptStatusColors]}
        >
          {promptStatusText[value as keyof typeof promptStatusText]}
        </Tag>
      ),
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      align: 'center',
      width: '166px',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        title: search,
      }));
    }, 500);
  }, [search]);

  const handleCancel = () => {
    setOpenDrawer(false);
    setCurrentId(null);
    // setDetail(undefined);
    reset({});
  };

  const { isFetching: isFetchingDetail } = useQuery(
    [ENDPOINT.PROMPT.GET_DETAIL.replace(':id', String(currentId))],
    () => PromptService.getDetail(Number(currentId)),
    {
      enabled: !!currentId,
      onSuccess: (response: IResponse<IGetDetailPromptResponse>) => {
        const data = response?.data;
        setDetail(data);
        setIsLoadDetail(true);
        reset({
          title: data.title,
          instructions: data.instructions,
          promptInputTypeId: data?.promptInputTypeId?.id,
          status: data?.status,
        });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
        setIsNotFoundDetail(true);
      },
    },
  );

  const promptInputTypeId = watch('promptInputTypeId');

  useEffect(() => {
    if (!isLoadDetail) {
      promptInputTypes?.data.forEach((each) => {
        if (each.id === promptInputTypeId) {
          setValue('instructions', each.instructions);
        }
      });
      clearErrors('instructions');
    }
    setIsLoadDetail(false);
  }, [promptInputTypeId]);

  const { data: promptInputTypes, isFetching: isLoadingPromptInputTypes } =
    useQuery([ENDPOINT.PROMPT_INPUT_TYPE.GET_LIST], () =>
      PromptInputTypeService.getList(),
    );

  const { mutate: create, isLoading: isLoadingCreate } = useMutation(
    (body: ICreatePromptRequest) => PromptService.create(body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.CREATE_PROMPT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
      },
    },
  );

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    (payload: { body: IUpdatePromptRequest; id: number }) =>
      PromptService.update(String(payload.id), payload.body),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.UPDATE_PROMPT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);
      },
    },
  );

  const handleCreate = (values: any) => {
    const payload: ICreatePromptRequest = {
      title: values?.title,
      instructions: values?.instructions,
      promptInputTypeId: values.promptInputTypeId,
      status: values.status,
    };

    create(payload);
  };

  const handleUpdate = (values: any) => {
    const payload: IUpdatePromptRequest = {
      title: values?.title,
      instructions: values?.instructions,
      promptInputTypeId: values.promptInputTypeId,
      status: values.status,
    };

    update({ body: payload, id: Number(currentId) });
  };

  const { mutate: deletePrompt, isLoading: isLoadingDelete } = useMutation(
    (id: number) => PromptService.delete(id),
    {
      onSuccess: () => {
        notificationMessage({
          message:
            SUCCESS_MESSAGE[
              SUCCESS.DELETE_PROMPT as keyof typeof SUCCESS_MESSAGE
            ],
          type: NOTIFY_STATUS.SUCCESS,
        });
        refetch();
        handleCancel();
        setParams({ ...params, page: 1 });
      },
      onError: (error: IResponseError) => {
        handleErrorCommon(error, setError);

        if (error.error === ERROR.EVENT_RELOAD_DATA) {
          setOpenDrawer(false);
          refetch();
        }
      },
    },
  );

  const handleDelete = (data: IGetDetailPromptResponse) => {
    notificationModal({
      type: NOTIFY_STATUS.CONFIRM,
      title: '削除しますか？',
      content: <DeleteContent name='タイトル' content={data?.title} />,
      maskClosable: false,
      onOk: () => deletePrompt(currentId!),
      okText: '削除する',
      cancelText: 'キャンセル',
    });
  };

  return (
    <div className='p-post-list'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex flex-col gap-[16px] mx-auto w-full xl:w-[1280px]'>
        <header className='flex justify-between w-full'>
          <Input
            className='w-[300px]'
            prefix={<SearchOutlined className='text-[20px]' />}
            placeholder='ユーザー名から検索'
            onChange={(e) => {
              setParams({ ...params, page: 1, title: e.target.value });
            }}
          />
          <Button
            type='primary'
            onClick={() => {
              setOpenDrawer(true),
                reset({ status: PromptStatus.DRAFT }),
                setIsNotFoundDetail(false);
            }}
          >
            プロンプト新規作成
          </Button>
        </header>
        <Table
          loading={isFetching}
          scroll={{ x: 800 }}
          bordered
          rowKey='id'
          columns={columns}
          dataSource={data?.data.data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              setOpenDrawer(true);
              setCurrentId(record.id);
              setIsNotFoundDetail(false);
            },
          })}
        />

        {data?.data?.pagination?.total! > 10 && (
          <Pagination
            className='pagination-table'
            total={data?.data.pagination.total}
            pageSize={params.limit}
            current={params.page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        )}
      </div>
      <Drawer
        title={currentId ? 'プロンプトの修正' : 'プロンプト新規作成'}
        onOk={handleSubmit(currentId ? handleUpdate : handleCreate)}
        onCancel={handleCancel}
        onDelete={() => handleDelete(detail!)}
        maskClosable={false}
        open={openDrawer}
        okText={!currentId ? '保存 ' : '更新 '}
        loading={
          isLoadingUpdate ||
          isLoadingDelete ||
          isFetchingDetail ||
          isLoadingCreate
        }
        isDeleted={!!currentId}
        // disabled={isDisabled}
      >
        <FormProvider {...methods}>
          <PromptForm
            isLoading={
              isFetchingDetail ||
              isLoadingPromptInputTypes ||
              isLoadingCreate ||
              isLoadingUpdate ||
              isLoadingDelete
            }
            promptInputTypes={promptInputTypes?.data?.map(({ id, name }) => ({
              id,
              name,
            }))}
          />
        </FormProvider>
      </Drawer>
    </div>
  );
};

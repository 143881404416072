export const ERROR = {
  FORM_VALIDATION: 'FORM_VALIDATION',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  POST_NOT_FOUND: 'POST_NOT_FOUND',
  DAY_NOT_FOUND: 'DAY_NOT_FOUND',
  DAY_EXIST: 'DAY_EXIST',
  NOTIFICATION_NOT_FOUND: 'NOTIFICATION_NOT_FOUND',
  PROMPT_NOT_FOUND: 'PROMPT_NOT_FOUND',
  EMAIL_INVALID: 'EMAIL_INVALID',
  RESET_PASSWORD_INVALID: 'RESET_PASSWORD_INVALID',
  CONFLICT_DATA: 'CONFLICT_DATA',
  EVENT_NOT_FOUND: 'EVENT_NOT_FOUND',
  EVENT_RELOAD_DATA: 'EVENT_RELOAD_DATA',
  COMMENT_NOT_FOUND: 'COMMENT_NOT_FOUND',
  INCORRECT_LOGIN: 'INCORRECT_LOGIN',
  ZIP_CODE_NOT_FOUND: 'ZIP_CODE_NOT_FOUND',
  PROMPT_ENABLE_EXIST: 'PROMPT_ENABLE_EXIST',
  SURVEY_NOT_FOUND: 'SURVEY_NOT_FOUND',
  CONTENT_NOT_FOUND: 'CONTENT_NOT_FOUND',
  CONTENT_RELOAD_DATA: 'CONTENT_RELOAD_DATA',
  SURVEY_RELOAD_DATA: 'SURVEY_RELOAD_DATA',
  CANT_EDIT_SURVEY_HAVE_PEOPLE_JOIN: 'CANT_EDIT_SURVEY_HAVE_PEOPLE_JOIN',
  NOTIFICATION_REMIND_NOT_FOUND: 'NOTIFICATION_REMIND_NOT_FOUND',
  NOTIFICATION_REMIND_RELOAD_DATA: 'NOTIFICATION_REMIND_RELOAD_DATA',
  CANT_ACTION_NOTIFICATION_REMIND_PUBLISHED:
    'CANT_ACTION_NOTIFICATION_REMIND_PUBLISHED',
  SURVEY_HEALTH_NOT_FOUND: 'SURVEY_HEALTH_NOT_FOUND',
  SURVEY_HEALTH_RELOAD_DATA: 'SURVEY_HEALTH_RELOAD_DATA',
  SURVEY_HEALTH_QUESTION_NOT_FOUND: 'SURVEY_HEALTH_QUESTION_NOT_FOUND',
  SURVEY_HEALTH_QUESTION_RELOAD_DATA: 'SURVEY_HEALTH_QUESTION_RELOAD_DATA',
  VERSION_NOT_FOUND: 'VERSION_NOT_FOUND',
  VERSION_EXIST: 'VERSION_EXIST',
};

export const ERROR_MESSAGE = {
  DAY_EXIST: 'Day already exists.',
  NOTIFICATION_NOT_FOUND: 'お知らせが見つかりませんでした。',
  EMAIL_INVALID: 'Email is not found.',
  RESET_PASSWORD_INVALID: 'Your password reset link has expired.',
  CONFLICT_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  USER_NOT_FOUND: 'ユーザーが見つかりませんでした。',
  DAY_NOT_FOUND: 'SPECIAL_DAY_NOT_FOUND',
  POST_NOT_FOUND: 'POST_NOT_FOUND',
  EVENT_NOT_FOUND: 'イベントが見つかりませんでした。',
  EVENT_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  COMMENT_NOT_FOUND: 'COMMENT_NOT_FOUND',
  INCORRECT_LOGIN: 'Incorrect email or password',
  ZIP_CODE_NOT_FOUND: 'この番号では検索できません。',
  PROMPT_ENABLE_EXIST: '有効のプロンプトはすでに存在してます',
  SURVEY_NOT_FOUND: 'アンケートが見つかりませんでした。',
  CONTENT_NOT_FOUND: 'コンテンツが見つかりませんでした。',
  CONTENT_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  SURVEY_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  CANT_EDIT_SURVEY_HAVE_PEOPLE_JOIN:
    'このアンケートに参加したユーザーがいますので、更新できませんでした。',
  NOTIFICATION_REMIND_NOT_FOUND: 'お知らせが見つかりませんでした。',
  NOTIFICATION_REMIND_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  CANT_ACTION_NOTIFICATION_REMIND_PUBLISHED:
    'データに変更がありました。このページを再読み込みしてください。',
  SURVEY_HEALTH_NOT_FOUND: 'アンケートが見つかりませんでした。',
  SURVEY_HEALTH_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  SURVEY_HEALTH_QUESTION_NOT_FOUND: '質問が見つかりませんでした。',
  SURVEY_HEALTH_QUESTION_RELOAD_DATA:
    'データに変更がありました。このページを再読み込みしてください。',
  VERSION_NOT_FOUND: 'このアプリバージョンが見つかりませんでした。',
  VERSION_EXIST: 'このアプリバージョンが存在しています。',
};

export const SUCCESS = {
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  CREATE_SPECIALDAY: 'CREATE_SPECIALDAY',
  UPDATE_SPECIALDAY: 'UPDATE_SPECIALDAY',
  DELETE_SPECIALDAY: 'DELETE_SPECIALDAY',
  CREATE_EVENT: 'CREATE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  CREATE_PROMPT: 'CREATE_PROMPT',
  UPDATE_PROMPT: 'UPDATE_PROMPT',
  DELETE_PROMPT: 'DELETE_PROMPT',
  UPDATE_FUKUMARU: 'UPDATE_FUKUMARU',
  DELETE_FUKUMARU: 'DELETE_FUKUMARU',
  CREATE_CONTENT: 'CREATE_CONTENT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  DELETE_CONTENT: 'DELETE_CONTENT',
  CREATE_SURVEY: 'CREATE_SURVEY',
  UPDATE_SURVEY: 'UPDATE_SURVEY',
  DELETE_SURVEY: 'DELETE_SURVEY',
  CREATE_NOTIFICATION_REMIND: 'CREATE_NOTIFICATION_REMIND',
  UPDATE_NOTIFICATION_REMIND: 'UPDATE_NOTIFICATION_REMIND',
  DELETE_NOTIFICATION_REMIND: 'DELETE_NOTIFICATION_REMIND',
  CREATE_SURVEY_HEALTH: 'CREATE_SURVEY_HEALTH',
  UPDATE_SURVEY_HEALTH: 'UPDATE_SURVEY_HEALTH',
  DELETE_SURVEY_HEALTH: 'DELETE_SURVEY_HEALTH',
  CREATE_SURVEY_HEALTH_QUESTION: 'CREATE_SURVEY_HEALTH_QUESTION',
  UPDATE_SURVEY_HEALTH_QUESTION: 'UPDATE_SURVEY_HEALTH_QUESTION',
  DELETE_SURVEY_HEALTH_QUESTION: 'DELETE_SURVEY_HEALTH_QUESTION',
  CREATE_APP_VERSION: 'CREATE_APP_VERSION',
  UPDATE_APP_VERSION: 'UPDATE_APP_VERSION',
  DELETE_APP_VERSION: 'DELETE_APP_VERSION',
};

export const SUCCESS_MESSAGE = {
  CREATE_NOTIFICATION: 'お知らせが正常に作成されました。',
  UPDATE_NOTIFICATION: 'お知らせが正常に更新されました。',
  DELETE_NOTIFICATION: 'お知らせが正常に削除されました。',
  CREATE_SPECIALDAY: 'CREATE_SPECIALDAY',
  UPDATE_SPECIALDAY: 'UPDATE_SPECIALDAY',
  DELETE_SPECIALDAY: 'DELETE_SPECIALDAY',
  CREATE_EVENT: 'イベントが正常に新規作成されました。',
  UPDATE_EVENT: 'イベントが正常に更新されました。',
  DELETE_EVENT: 'イベントが正常に削除されました。',
  DELETE_COMMENT: 'コメントを削除しました。',
  CREATE_PROMPT: 'プロンプトが正常に新規作成されました。',
  UPDATE_PROMPT: 'プロンプトが正常に更新されました。',
  DELETE_PROMPT: 'プロンプトが正常に削除されました。',
  UPDATE_FUKUMARU: 'ふくまるが正常に更新されました。',
  DELETE_FUKUMARU: 'ふくまるが正常に削除されました。',
  CREATE_CONTENT: 'コンテンツが正常に作成されました。',
  UPDATE_CONTENT: 'コンテンツが正常に更新されました。',
  DELETE_CONTENT: 'コンテンツが正常に削除されました。',
  CREATE_SURVEY: 'アンケートが正常に作成されました。',
  UPDATE_SURVEY: 'アンケートが正常に更新されました。',
  DELETE_SURVEY: 'アンケートが正常に削除されました。',
  CREATE_NOTIFICATION_REMIND: 'お知らせが正常に作成されました。',
  UPDATE_NOTIFICATION_REMIND: 'お知らせが正常に更新されました。',
  DELETE_NOTIFICATION_REMIND: 'お知らせが正常に削除されました。',
  CREATE_SURVEY_HEALTH: 'アンケートが正常に作成されました。',
  UPDATE_SURVEY_HEALTH: 'アンケートが正常に更新されました。',
  DELETE_SURVEY_HEALTH: 'アンケートが正常に削除されました。',
  CREATE_SURVEY_HEALTH_QUESTION: '質問が正常に作成されました。',
  UPDATE_SURVEY_HEALTH_QUESTION: '質問が正常に更新されました。',
  DELETE_SURVEY_HEALTH_QUESTION: '質問が正常に削除されました。',
  CREATE_APP_VERSION: 'アプリバージョン正常に作成されました。',
  UPDATE_APP_VERSION: 'アプリバージョン正常に更新されました。',
  DELETE_APP_VERSION: 'アプリバージョン正常に削除されました。',
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-post-list .ant-image img {
  border-radius: 6px;
  width: 112px;
}
.p-post-list .ant-image .ant-image-mask {
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Post/PostList/style.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,YAAA;AADN;AAII;EACE,kBAAA;AAFN","sourcesContent":[".p-post-list {\r\n  .ant-image {\r\n    img {\r\n      border-radius: 6px;\r\n      width: 112px;\r\n    }\r\n\r\n    .ant-image-mask {\r\n      border-radius: 6px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
